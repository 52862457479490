<template>
  <v-row>
    <v-col align="right">
      <h4>
        <strong>{{ question.response_count }}</strong>
        {{ "Respondent" | pluralize(question.response_count) }} Represented
      </h4>
      <cp-simple-bar
        :items="
          question.details.values.map(x => ({
            value: x.count,
            label: x.body
          }))
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared
};
</script>
