<template>
  <q-r-multiple-choice :question="question" />
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared
};
</script>
