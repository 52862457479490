import { Api } from "@cp/lib";
const bApi = new Api(process.env.VUE_APP_TASSEL_API_PATH);

export default {
  fetchSurvey(
    { state, commit, dispatch, getters },
    survey_id = state.instance.id
  ) {
    state.survey_loaded = false;

    return bApi
      .authorize(getters.getSUSlug)
      .get(`/en/v1/${getters.getSUSlug}surveys/${survey_id}`, {
        include: "sections",
      })
      .then(({ data, included }) => {
        let survey_data = {
          ...data.attributes,
          sections: included
            ? included
                .filter(x => x.type === "section")
                .map(x => ({ ...x.attributes, id: x.id }))
            : [],
          id: data.id,
        };

        commit("setSurvey", survey_data);
        dispatch("fetchSurveyQuestionIds").then(() => {
          state.survey_loaded = true;
        });
      });
  },

  fetchSurveys({ state, commit }, { extra_attrs = true } = {}) {
    state.loading_all_surveys = true;

    return bApi
      .authorize()
      .get(`/en/v1/surveys`, {
        extra_attrs: extra_attrs,
        page: {
          size: "all",
        },
      })
      .then(({ data }) => {
        commit(
          "setSurveys",
          data.map(x => ({
            ...x.attributes,
            id: x.id,
          }))
        );

        // commit("setPagination", meta);
      })
      .finally(() => {
        state.loading_all_surveys = false;
      });
  },

  fetchCampaignSummary({ commit }) {
    return bApi
      .authorize()
      .get(`/en/v1/dashboard/campaign-summary`)
      .then(({ data }) => commit("setCampaignSummary", data));
  },

  fetchSurveySummaryItems({ commit }) {
    return bApi
      .authorize()
      .get(`/en/v1/dashboard/live-campaign-summary`)
      .then(({ data }) => commit("setSurveySummaries", data));
  },

  fetchRecentlyEndedSurveySummaryItems({ commit }) {
    return bApi
      .authorize()
      .get(`/en/v1/dashboard/recently-ended-campaign-summary`)
      .then(({ data }) => commit("setRecentlyEndedSurveySummaries", data));
  },

  fetchSurveyQuestionIds({ state, commit, getters }) {
    return bApi
      .authorize(getters.getSUSlug)
      .get(
        `/en/v1/${getters.getSUSlug}surveys/${state.instance.id}/question-ids`
      )
      .then(({ data }) => commit("setSurveyQuestionIds", data));
  },

  fetchSection(
    { state, commit, getters },
    section_id = state.section_instance.id
  ) {
    return bApi
      .authorize(getters.getSUSlug)
      .get(
        `/en/v1/${getters.getSUSlug}surveys/${state.instance.id}/sections/${section_id}`
      )
      .then(({ data }) => {
        commit("setSection", { ...data.attributes, id: data.id });
      });
  },

  fetchSections({ state, commit, getters }, survey_id = state.instance.id) {
    return bApi
      .authorize(getters.getSUSlug)
      .get(`/en/v1/${getters.getSUSlug}surveys/${survey_id}/sections`)
      .then(({ data }) => {
        commit("setSections", data);
      });
  },

  fetchQuestionsForSection(
    { state, commit, getters },
    section_id = state.section_instance.id
  ) {
    state.loading_all_questions = true;

    if (section_id != state.section_instance.id) {
      state.section_instance.id = section_id;
    }

    return bApi
      .authorize(getters.getSUSlug)
      .get(
        `/en/v1/${getters.getSUSlug}surveys/${state.instance.parent_version}/sections/${section_id}/questions`,
        {
          page: { size: "all" },
        }
      )
      .then(data => {
        // TODO it would be cool for this data to come back differently to avoid
        // all of this mapping
        data.data.forEach(question => (question.attributes.options = []));

        data.included
          .filter(x => x.type == "option")
          .forEach(option => {
            data.data
              .find(x => x.id == option.relationships.question.data.id)
              .attributes.options.push({
                ...option.attributes,
                id: option.id,
              });
          });

        commit(
          "setQuestionsOnSection",
          data.data.map(x => ({
            ...x.attributes,
            id: x.id,
            type: {
              text: x.attributes.type,
              value: x.attributes.question_type_id,
              slug: x.attributes.question_type_slug,
            },
          }))
        );
      })
      .finally(() => {
        state.loading_all_questions = false;
      });
  },

  processSurvey({ state, dispatch }, survey = state.instance) {
    state.processing = true;
    let action = survey.id ? "updateSurvey" : "createSurvey";

    return dispatch(action, survey)
      .then(response => {
        this._vm.$CpEvent.$emit(state.processing_action_name, {
          response: response,
          message: state.processing_action_name,
        });
        return response;
      })
      .catch(error => {
        dispatch("deactivateCreateEditDialog");
        dispatch("handleErrorResponse", error.response, { root: true });
      })
      .finally(response => {
        state.processing = false;
        state.processing_action_name = null;
      });
  },

  processSection({ state, dispatch }, section = state.section_instance) {
    state.processing = true;
    let action = state.section_instance.id ? "updateSection" : "createSection";

    return dispatch(action, section)
      .then(response => {
        return response;
      })
      .finally(() => {
        state.processing = false;
      });
  },

  createSurvey({ state, commit }, survey = state.instance) {
    state.processing_action_name =
      state.processing_action_name || "surveyCreated";

    return bApi
      .authorize()
      .post(`/en/v1/surveys`, {
        survey: { ...state.instance, status_slug: "draft" },
      })
      .then(({ data, included }) => {
        let survey_data = {
          ...data.attributes,
          sections: included
            ? included
                .filter(x => x.type === "section")
                .map(x => ({ ...x.attributes, id: x.id }))
            : [],
          id: data.id,
        };

        commit("setSurvey", survey_data);
        state.new_survey_created = true;
      })
      .finally(() => {
        this._vm.$CpEvent.$emit("surveyCreated");
      });
  },

  duplicateSurvey({ state, commit, dispatch }, survey_id) {
    return bApi
      .authorize()
      .get(`/en/v1/surveys/${survey_id}/duplicate`)
      .then(({ data }) => {
        this._vm.$CpEvent.$emit("surveyDuplicated", data.id);
        this._vm.$CpEvent.$emit("snackAlert", {
          message: "Survey duplicated!",
        });
      });
  },

  updateSurvey({ state, commit, dispatch }, survey = state.instance) {
    state.processing_action_name =
      state.processing_action_name || "surveySaved";

    return bApi
      .authorize()
      .put(`/en/v1/surveys/${survey.id}`, { survey: survey })
      .then(response => {
        return response;
      });
  },

  publishSurvey({ state, dispatch }, survey = state.instance) {
    state.processing_action_name = "surveyPublished";
    return dispatch("processSurvey", {
      id: survey.id,
      status_slug: "active",
    }).then(() => {
      state.publish_confirmation_open = false;
    });
  },

  archiveSurvey({ state, dispatch }, survey = state.instance) {
    state.processing_action_name = "surveyArchived";
    return dispatch("processSurvey", {
      id: survey.id,
      status_slug: "archived",
    }).then(() => {
      state.archive_confirmation_open = false;
    });
  },

  createSection({ state, commit, dispatch }, section = state.section_instance) {
    return bApi
      .authorize()
      .post(`/en/v1/surveys/${state.instance.id}/sections`, {
        section: section,
      })
      .then(({ data }) => {
        return dispatch("fetchSections").then(() => {
          commit("setSection", { ...data.attributes, id: data.id });
        });
      })
      .finally(() => {
        this._vm.$CpEvent.$emit("sectionSaved");
      });
  },

  updateSection({ commit, state, dispatch }, section = state.section_instance) {
    return bApi
      .authorize()
      .put(`/en/v1/surveys/${state.instance.id}/sections/${section.id}`, {
        section: section,
      })
      .then(({ data }) => {
        return dispatch("fetchSections").then(() => {
          dispatch("fetchQuestionsForSection");
        });
      })
      .finally(() => {
        this._vm.$CpEvent.$emit("sectionSaved");
      });
  },

  async updateSectionOrder({ state, dispatch }) {
    // <draggable has already updated the store ?! Magical!!
    const ids = state.instance.sections.map(x => x.id);
    return bApi
      .authorize()
      .put(`/en/v1/surveys/${state.instance.id}/reorder`, {
        section_ids: ids,
      })
      .then(() => {
        dispatch("fetchSections");
      });
  },

  deleteSection(
    { commit, state, dispatch, getters },
    section = state.section_instance
  ) {
    return bApi
      .authorize()
      .post(`/en/v1/surveys/${state.instance.id}/sections/${section.id}/delete`)
      .then(() => {
        return dispatch("fetchSections").then(() => {
          if (state.instance.sections.length > 0) {
            commit("setSection", getters.getLastSection);
            state.active_editor = "questions";
            return dispatch("fetchQuestionsForSection");
          } else {
            commit("resetSectionInstance");
            state.active_editor = "section";
          }
        });
      })
      .finally(() => {
        this._vm.$CpEvent.$emit("sectionDeleted");
      });
  },

  activatePublishSurveyDialog({ state, commit }, survey = state.instance) {
    commit("setSurvey", survey);
    state.publish_confirmation_open = true;
  },

  activateArchiveSurveyDialog({ state, commit }, survey = state.instance) {
    commit("setSurvey", survey);
    state.archive_confirmation_open = true;
  },

  activateCreateEditDialog(
    { state, commit, dispatch, getters, rootGetters },
    survey_id
  ) {
    if (rootGetters["auth/isImpersonating"]) {
      this._vm.$CpEvent.$emit("snackAlert", {
        message: "Cannot create or edit surveys while impersonating.",
        color: "error",
      });
      return;
    }

    commit("resetSurveyInstance");

    state.editor_open = true;
    state.editing = survey_id != null;

    if (state.editing) {
      dispatch("fetchSurvey", survey_id).then(() => {
        if (state.instance.sections.length > 0) {
          state.active_editor = "questions";
          Object.assign(
            state.section_instance,
            state.instance.sections[state.instance.sections.length - 1]
          );
          dispatch("fetchQuestionsForSection", state.section_instance.id);
        } else {
          state.active_editor = "section";
        }
      });
    } else {
      state.active_editor = "info";
    }
  },

  deactivateCreateEditDialog({ state, commit }) {
    state.editor_open = false;
    state.editing = false;
    state.active_editor = null;
  },

  addQuestionToSection({ state, dispatch }, question_id) {
    return bApi
      .authorize()
      .post(
        `/en/v1/surveys/${state.instance.id}/sections/${state.section_instance.id}/questions`,
        { question_id: question_id }
      )
      .then(({ data }) => {
        if (data.section_id != state.section_instance.id) {
          dispatch("fetchSurvey", data.survey_id).then(() => {
            dispatch("fetchSection", data.section_id).then(() => {
              dispatch("fetchQuestionsForSection", data.section_id);
            });
          });
        } else {
          dispatch("fetchQuestionsForSection", data.section_id);
        }
      });
  },

  reorderQuestionOnSection({ state, dispatch }, { question_id, new_position }) {
    const ids = state.section_instance.questions.map(x => x.id);
    const oldIndex = ids.findIndex(id => id === question_id);
    if (oldIndex === -1) return;
    // remove id from old position
    ids.splice(oldIndex, 1);
    // reinsert id into new position
    ids.splice(new_position, 0, question_id);
    const [movedItem] = state.section_instance.questions.splice(oldIndex, 1);
    state.section_instance.questions.splice(new_position, 0, movedItem);
    return bApi
      .authorize()
      .put(
        `/en/v1/surveys/${state.instance.id}/sections/${state.section_instance.id}/questions/reorder`,
        { question_ids: ids }
      )
      .then(() => {
        dispatch("fetchQuestionsForSection");
      });
  },

  removeQuestionFromSection({ state, dispatch }, question_id) {
    return bApi
      .authorize()
      .post(
        `/en/v1/surveys/${state.instance.id}/sections/${state.section_instance.id}/questions/delete`,
        { question_id: question_id }
      )
      .then(response => {
        if (response.data.survey_id) {
          let newSectionId = response.data.section_id;

          dispatch("fetchSurvey", response.data.survey_id).then(() => {
            dispatch("fetchSection", newSectionId).then(() => {
              dispatch("fetchQuestionsForSection", newSectionId);
            });
          });
        }

        this._vm.$CpEvent.$emit("questionRemoved");
      });
  },

  addNewQuestionToSection({ commit, state, dispatch }, question) {
    return bApi
      .authorize()
      .post(`/en/v1/questions`, {
        question: {
          ...question,
          options_attributes: question.options.map((x, i) => ({
            body: x.body,
            id: x.id,
            _destroy: x._destroy,
            position: i,
          })),
          question_type_id: question.type.value,
          status_slug: "active",
        },
      })
      .then(({ data }) => {
        dispatch("addQuestionToSection", {
          ...data.attributes,
          id: data.id,
        }).then(({ data }) => {
          dispatch("fetchQuestionsForSection", data.section_id);
        });
      });
  },

  activateTransferQuestionDialog({ state, commit }, question) {
    commit("question/setQuestion", question, { root: true });
    state.transfer_question_open = true;
  },

  deactivateTransferQuestionDialog({ state, commit }) {
    commit("question/resetQuestionInstance", null, { root: true });
    state.transfer_question_open = false;
  },

  activateRemoveQuestionDialog({ state, commit }, question) {
    commit("question/setQuestion", question, { root: true });
    state.remove_question_open = true;
  },

  deactivateRemoveQuestionDialog({ state, commit }) {
    commit("question/resetQuestionInstance", null, { root: true });
    state.remove_question_open = false;
  },

  transferQuestion({ state, dispatch }, { section, question }) {
    return bApi
      .authorize()
      .put(
        `/en/v1/surveys/${state.instance.id}/sections/${state.section_instance.id}/questions/transfer`,
        {
          transfer_section_id: section.id,
          question_id: question.id,
        }
      )
      .then(({ data }) => {
        return dispatch("fetchQuestionsForSection", state.section_instance.id);
      })
      .finally(() => {
        this._vm.$CpEvent.$emit("questionTransferred");
      });
  },

  transferAllQuestions({ state, dispatch }, to_section) {
    let questionsTransferred = 0;
    let questionsToTransfer = state.section_instance.questions.length;
    state.transferring_questions = true;

    state.section_instance.questions.forEach(question => {
      bApi
        .authorize()
        .put(
          `/en/v1/surveys/${state.instance.id}/sections/${state.section_instance.id}/questions/transfer`,
          {
            transfer_section_id: to_section,
            question_id: question.id,
          }
        )
        .then(() => {
          questionsTransferred += 1;
          if (questionsTransferred == questionsToTransfer) {
            dispatch("deleteSection").finally(() => {
              state.transferring_questions = false;
            });
          }
        });
    });
  },

  openPreviewSender({ state, commit }) {
    commit("setPreviewSender", true);
  },

  sendPreviews({ recipients, survey, preview_path, preview_token }) {
    if (!Array.isArray(recipients) || recipients.length <= 0) return;

    this.sending_previews = true;

    return bApi
      .authorize()
      .get(`/en/v1/surveys/${survey.instance.id}/send-preview`, {
        recipients: recipients,
        survey: survey,
        preview_token: preview_token,
        preview_path: preview_path,
      })
      .then(response => {})
      .finally(() => {
        this.sending_previews = false;
      });
  },

  buildMenuActions({ state, getters }, survey_id) {
    return bApi
      .authorize(getters.getSUSlug)
      .get(`/en/v1/${getters.getSUSlug}surveys/${survey_id}`)
      .then(({ data }) => {
        state.survey_menu_actions = [];

        // edit survey
        if (
          data.attributes.created_by != null &&
          data.attributes.created_by != "Swift Bunny"
        ) {
          state.survey_menu_actions.push({
            title: "Edit",
            type: "storeAction",
            action: "survey/activateCreateEditDialog",
            data: data.id,
          });
        }

        // duplicate survey
        state.survey_menu_actions.push(
          {
            title: "Preview",
            type: "link",
            href: `/surveys/${data.id}/preview`,
            target: "_blank",
          },
          {
            title: "Duplicate",
            type: "storeAction",
            action: "survey/duplicateSurvey",
            data: data.id,
          }
        );

        // publish survey
        if (data.attributes.status_slug != "active") {
          state.survey_menu_actions.push({
            title: "Publish",
            type: "storeAction",
            action: "survey/activatePublishSurveyDialog",
            data: { ...data.attributes, id: data.id },
          });
        }

        // schedule campaign
        if (
          data.attributes.status_slug == "active" &&
          data.attributes.question_count > 0
        ) {
          state.survey_menu_actions.push({
            title: "Schedule",
            type: "storeAction",
            action: "campaign/activateLauncher",
            data: { ...data.attributes, id: data.id },
          });
        }

        // archive survey
        if (
          data.attributes.created_by != null &&
          data.attributes.created_by != "Swift Bunny" &&
          !data.attributes.is_live
        ) {
          state.survey_menu_actions.push({
            title: "Archive",
            type: "storeAction",
            action: "survey/activateArchiveSurveyDialog",
            data: { ...data.attributes, id: data.id },
          });
        }
      });
  },
};
