<template>
  <div>
    <survey-content v-if="shouldShowContent && service.loaded" />
    <survey-closed v-else-if="service.loaded" />
    <v-progress-circular
      v-else
      class="inq-survey-page-loading"
      size="100"
      indeterminate
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      service: state => state.surveyRenderService,
      survey: state => state.survey
    }),

    ...mapGetters("surveyRenderService", [
      "shouldShowContent",
      "shouldShowCompleted",
      "shouldShowClosed"
    ])
  },

  mounted() {
    this.$store.commit(
      "surveyRenderService/setIsPreview",
      this.$route.meta.surveyPreviewMode
    );

    if (this.$route.name == "feedback_weblink") {
      this.$store
        .dispatch("surveyRenderService/fetchWeblinkURL", {
          campaign_id: this.$route.params.campaign_id,
          survey_id: this.$route.params.survey_id
        })
        .then(su_slug => {
          window.location.href = `${process.env.VUE_APP_DARKHAVEN_UI_PATH}/feedback/${su_slug}/${this.$route.params.survey_id}`;
        });
    } else if (this.$route.params.slug) {
      this.$store.commit(
        "surveyRenderService/setSurveysUserSlug",
        this.$route.params.slug
      );
      this.$store
        .dispatch("surveyRenderService/fetchStatus")
        .then(() => this.initializeSurvey());
    } else {
      this.initializeSurvey();
    }
  },

  methods: {
    initializeSurvey() {
      this.service.loaded = false;

      if (this.shouldShowContent) {
        this.$store
          .dispatch("survey/fetchSurvey", this.$route.params.id)
          .then(() => {
            if (this.service.instance.slug) {
              this.$store.dispatch("surveyRenderService/fetchResponses");
            }

            let currentSection = this.survey.instance.sections[
              this.$route.query.section - 1 || 0
            ];

            this.$store
              .dispatch(
                "surveyRenderService/initializeSection",
                currentSection.id
              )
              .then(() => {
                this.service.loaded = true;
              });
          });
      } else {
        this.$store
          .dispatch("survey/fetchSurvey", this.$route.params.id)
          .then(() => {
            this.service.loaded = true;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inq-survey-page-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
