<template>
  <div class="mb-10">
    <div class="qr-header" :class="{ 'mb-6': question.active_on_survey }">
      <div class="qr-body">
        {{ question.body }}
      </div>
      <div class="qr-type">
        {{ questionStore.question_type_slug_name_map[question.type] }}
      </div>
    </div>
    <div
      v-if="!question.active_on_survey"
      class="font-weight-bold font-italic red--text mb-6"
    >
      This question is not on the current version of this survey, but has
      responses recorded from an older version.
    </div>
    <div v-if="!question.details">
      No responses recorded for this question yet.
    </div>
    <div v-else-if="question.details.redacted">
      Because this is a confidential campaign, there have not been enough
      responses to show a breakdown.
    </div>
    <component
      v-else
      :is="`q-r-${questionStore.question_type_component_map[question.type]}`"
      :question="question"
    />
    <v-expansion-panels v-if="question.previous_versions.length">
      <v-expansion-panel>
        <v-expansion-panel-header ripple>
          <div>
            <v-icon color="primary">mdi-information</v-icon>
            This question has {{ previousVersionResponseCount }}
            {{ "response" | pluralize(previousVersionResponseCount) }} from
            {{ question.previous_versions.length }} previous
            {{ "version" | pluralize(question.previous_versions.length) }}
          </div>

          <template #actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <q-r-summary
            v-for="(previous_version, i) in question.previous_versions"
            :key="i"
            :question="previous_version"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    question: { type: Object, default: null }
  },

  computed: {
    ...mapState({
      questionStore: state => state.question
    }),

    previousVersionResponseCount() {
      return this.question.previous_versions
        .map(x => x.response_count)
        .reduce((a, b) => a + b, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.qr-header {
  padding: 2px 6px;
  width: 100%;
  border-radius: 4px;
  background-color: #eeeeee;

  * {
    line-height: 1.16;
    vertical-align: top;
    display: inline-block;
    padding: 4px;
  }

  .qr-body {
    font-size: 16px;
    font-weight: 600;
    width: 70%;
  }

  .qr-type {
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    width: 30%;
  }
}
</style>
