<template>
  <v-card
    min-height="500"
    padding="0"
    height="100%"
    depressed
    style="background-color: #f0f0f7"
  >
    <v-banner single-line class="section-list-banner font-weight-bold">
      {{ survey.instance.name || "Survey Title" }}
      <v-btn
        class="inq-title-edit-icon"
        icon
        @click="survey.active_editor = 'info'"
        :disabled="survey.instance.sections.length <= 0"
      >
        <v-icon color="primary" small>
          edit
        </v-icon>
      </v-btn>
    </v-banner>
    <v-list nav dense style="background-color: #f0f0f7">
      <v-subheader class="text-body-1 font-weight-bold">Sections</v-subheader>
      <v-list-item-group
        :value="survey.section_instance.id"
        color="primary"
        style="background-color: #f0f0f7"
      >
        <draggable
          v-model="survey.instance.sections"
          @update="updateSectionOnMove"
        >
          <v-list-item
            v-for="section in survey.instance.sections"
            :key="section.id"
            :class="{
              'cp-active-section': section.id == survey.section_instance.id,
            }"
            color="primary"
            :ripple="false"
            @click="
              survey.active_editor = 'questions';
              $store.commit('survey/setSection', section);
              $store.dispatch('survey/fetchQuestionsForSection');
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-drag-vertical</v-icon>
            </v-list-item-icon>
            <span class="active-text" v-html="section.name" />
          </v-list-item>
        </draggable>
      </v-list-item-group>

      <v-subheader class="primary--text justify-center font-weight-bold">
        <cp-btn
          class="text-body-1 pt-5"
          @click="
            $store.commit('survey/resetSectionInstance');
            survey.active_editor = 'section';
          "
          :disabled="
            survey.instance.sections.length < 1 ||
              survey.active_editor == 'section'
          "
          plain
          text
          tile
        >
          <v-icon color="primary">mdi-plus</v-icon> Add New Section
        </cp-btn>
      </v-subheader>
    </v-list>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";

export default {
  components: {
    draggable,
  },

  data() {
    return {
      selectedSection: null,
    };
  },

  computed: {
    ...mapState({
      survey: state => state.survey,
    }),
  },

  methods: {
    updateSectionOnMove(evt) {
      // <draggable> is updating the store instance before this runs?!
      this.$store.dispatch("survey/updateSectionOrder");
    },
  },
};
</script>

<style lang="scss" scoped>
.section-list-banner {
  &::v-deep .v-banner__wrapper {
    padding-right: 40px;
    padding-left: 16px;
  }
}

.inq-title-edit-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.v-list-item {
  background-color: #ffffff;
}

.cp-active-section {
  background-color: #0070a8;

  .active-text,
  .v-list-item__icon,
  .v-icon {
    color: #ffffff !important;
  }
}
</style>
