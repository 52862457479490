import { VCol } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-dialog',{staticClass:"mx-auto",attrs:{"show-model":_vm.survey.transfer_question_open,"headerBackground":"","width":"600"},on:{"update:showModel":function($event){return _vm.$set(_vm.survey, "transfer_question_open", $event)},"update:show-model":function($event){return _vm.$set(_vm.survey, "transfer_question_open", $event)},"modalClosed":function($event){_vm.$CpEvent.$off('questionTransferred', _vm.questionTransferredListener);
      _vm.$store.dispatch('survey/deactivateTransferQuestionDialog');}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Transfer Question to Section ")]},proxy:true},{key:"body",fn:function(ref){
      var close = ref.close;
return [_vm._v(" To which section would you like to move this question? "),_c('cp-select',{attrs:{"items":_vm.getMovableSections,"item-text":"name","item-value":"id","name":"sections","label":"Select Section","return-object":"","disabled":_vm.getMovableSections.length < 1},model:{value:(_vm.sectionToMoveTo),callback:function ($$v) {_vm.sectionToMoveTo=$$v},expression:"sectionToMoveTo"}})]}},{key:"actions",fn:function(){return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$store.dispatch('survey/deactivateTransferQuestionDialog')}}},[_vm._v(" Close ")]),_c('cp-btn',{attrs:{"color":"primary","rounded":"","disabled":_vm.getMovableSections.length < 1},on:{"click":function($event){return _vm.$store.dispatch('survey/transferQuestion', {
              section: _vm.sectionToMoveTo,
              question: _vm.question.instance
            })}}},[_vm._v(" Transfer ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }