const state = () => ({
  collection: [],
  campaign_summary_collection: [],
  summary_collection: [],
  recently_ended_summary_collection: [],
  instance: {
    id: null,
    name: "",
    description: "",
    status_slug: "",
    sections: [],
    question_count: null,
    question_ids: [],
    parent_version: null,
    version: null,
    version_lock: false,
    created_by: "",
    all_assigned_count: {
      email: 0,
      weblink: 0
    },
    all_respondent_count: {
      email: 0,
      weblink: 0
    },
    all_response_rate: 0
  },
  section_instance: {
    id: null,
    name: "",
    instructions: "",
    questions: [],
    position: null
  },
  search: "",
  filters: {
    createdBy: null,
    status: [
      { text: "Draft", value: "draft" },
      { text: "Active", value: "active" }
    ]
  },
  pagination: {
    number: 1,
    perPage: 10,
    total: 1,
    size: 10
  },
  processing: false,
  processing_action_name: null,
  editing: false,
  active_editor: null,
  editor_open: false,
  new_survey_created: false,
  updating_survey: false,
  archive_confirmation_open: false,
  preview_sender_open: false,
  publish_confirmation_open: false,
  sending_previews: false,
  remove_question_open: false,
  remove_section_open: false,
  transfer_question_open: false,
  transferring_questions: false,
  loading_all_surveys: false,
  loading_all_questions: false,
  survey_loaded: false,
  survey_menu_actions: []
});

import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
