<template>
  <div>
    <v-row>
      <v-col cols="8" sm="8" md="8">
        <cp-input
          label="Question Body"
          v-model="question.instance.body"
          :messages="question.instance.errors.body"
          :error="question.instance.errors.hasOwnProperty('body')"
        />
      </v-col>
      <v-col cols="4" sm="4" md="4">
        <cp-select
          v-model="question.instance.type"
          :items="question.question_types"
          return-object
          label="Question Type"
          :disabled="question.editing || question.duplicating"
          @input="addDefaultOption"
        />
      </v-col>
    </v-row>

    <template
      v-if="
        ['multiple-choice', 'multiple-select'].includes(
          question.instance.type.slug
        )
      "
    >
      <h5 class="mb-1">Answer Options</h5>

      <draggable
        v-model="question.instance.options"
        :options="{ animation: 150 }"
        ghost-class="disable-drag-ghost"
        tag="div"
        handle=".draggable"
        @end="setOptionPositions"
      >
        <transition-group name="list" tag="div">
          <v-row
            dense
            align="center"
            style="max-width: 840px;"
            v-for="(option, i) in question.instance.options"
            :key="option.position"
            class="list-item"
            :class="{ 'is-hidden': option._destroy }"
          >
            <v-col cols="auto">
              <v-icon color="primary" class="draggable" style="cursor: grab;">
                mdi-drag-vertical
              </v-icon>
            </v-col>
            <v-col>
              <v-text-field
                v-model="option.body"
                full-width
                dense
                single-line
                hide-details
                style="margin-top: -1px;"
                placeholder="Enter your answer"
                :ref="`optionTextField-${option.position}`"
                autofocus
              >
                <template #prepend>
                  <v-icon color="#cccccc">
                    {{
                      question.instance.type.slug == "multiple-select"
                        ? "mdi-checkbox-blank-outline"
                        : "mdi-checkbox-blank-circle-outline"
                    }}
                  </v-icon>
                </template>
                <template #append-outer>
                  <v-btn
                    small
                    color="error"
                    icon
                    @click="option._destroy = true"
                    tabindex="-1"
                  >
                    <v-icon color="error">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </transition-group>
      </draggable>

      <v-btn
        class="inq-add-new-option"
        color="primary"
        text
        small
        @click="addQuestionOption"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>Add New Option
      </v-btn>
    </template>
    <v-divider class="mt-5" />
    <v-row v-if="question.instance.type.text != null && shouldShowPreview">
      <v-col>
        <v-sheet color="white" elevation="1" class="pa-4">
          <q-wrapper :question="question.instance" />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    draggable
  },

  computed: {
    ...mapState({
      question: state => state.question
    }),

    ...mapGetters("question", ["shouldShowPreview", "passesValidation"])
  },

  methods: {
    addQuestionOption() {
      let newPosition = this.question.instance.options.length;
      let newOption = {
        id: null,
        body: "",
        _destroy: false,
        pointer: uuidv4(),
        position: newPosition
      };
      this.question.instance.options.push(newOption);
    },

    setOptionPositions() {
      this.question.instance.options.forEach((option, index) => {
        option.position = index;
      });
    },

    addDefaultOption() {
      if (
        ["multiple-choice", "multiple-select"].includes(
          this.question.instance.type.slug
        ) &&
        this.question.instance.options.length <= 0
      ) {
        this.addQuestionOption();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inq-add-new-option {
  height: 50px !important;
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: 16px 0;
  margin-left: 32px;
  background-color: #f7f7f7;
  border: 1px solid $gray-1;

  &::v-deep .v-btn__content {
    display: inline-block;
    padding: 24px;
    padding-left: 12px;
  }

  &::v-deep .v-icon {
    margin-top: -2px;
  }
}

.list-item {
  transition: height 0.25s ease-in-out, opacity 0.25s ease-in-out,
    visibility 0.25s ease-in-out;
  overflow: hidden;
  height: 48px;
  opacity: 1;
  visibility: visible;

  &.is-hidden {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.list-enter-active,
.list-leave-active {
  transition: height 0.25s ease-in-out, opacity 0.25s ease-in-out,
    visibility 0.25s ease-in-out;
}

.list-enter,
.list-leave-to {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.disable-drag-ghost {
  opacity: 0.15;
}
</style>
