import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-card',{staticClass:"inq-stat-card-wrapper fill-height",attrs:{"dense":"","flush-body":""}},[(Object.keys(_vm.summary).length)?_c('div',{staticClass:"inq-divider"}):_vm._e(),_c(VContainer,[_c('p',{staticClass:"text-subtitle-1 font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Stats represent the past 90 days of activity.")])])],1),(Object.keys(_vm.summary).length)?[_c(VRow,[_c(VCol,{staticClass:"inq-stat-card",attrs:{"cols":"6"}},[_c('span',{staticClass:"inq-big-number mr-2"},[_vm._v(_vm._s(_vm.summary.sent))]),_c('span',{staticClass:"text-subtitle-1 font-weight-bold grey--text"},[_vm._v(" "+_vm._s(_vm.leftLabel)+" ")]),_c(VIcon,{staticClass:"inq-stat-icon"},[_vm._v(_vm._s(_vm.leftIcon))])],1),_c(VCol,{staticClass:"inq-stat-card",attrs:{"cols":"6","align-self":"end","align":"left"}},[_c('span',{staticClass:"inq-big-number mr-2"},[_vm._v(_vm._s(_vm.summary.started))]),_c('span',{staticClass:"text-subtitle-1 font-weight-bold grey--text"},[_vm._v(" Responded ")]),_c('cp-doughnut',{attrs:{"value":_vm.summary.response_rate || 0,"size":"100px","cutout":"78%"},scopedSlots:_vm._u([{key:"inside",fn:function(){return [_c('h4',[_vm._v(_vm._s(_vm.summary.response_rate || 0)+"%")])]},proxy:true}],null,false,2318843813)})],1)],1)]:[_vm._v(" There have been no "+_vm._s(_vm.title.toLowerCase())+" created in the past 90 days. ")]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }