<template>
  <cp-dialog
    :show-model.sync="survey.archive_confirmation_open"
    class="mx-auto"
    headerBackground
    width="600"
  >
    <template #title>
      Archive Survey
    </template>

    <template #body>
      Are you sure you would like to Archive this survey?
    </template>

    <template #actions="{ close }">
      <v-col cols="auto" class="ml-auto">
        <cp-btn text @click="close()">
          Cancel
        </cp-btn>
        <cp-btn
          color="primary"
          rounded
          @click="$store.dispatch('survey/archiveSurvey')"
          :loading="survey.processing"
        >
          Yes, Archive
        </cp-btn>
      </v-col>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      survey: state => state.survey
    })
  }
};
</script>
