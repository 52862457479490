import { Api } from "@cp/lib";
const bApi = new Api(process.env.VUE_APP_TASSEL_API_PATH);

const defaultState = () => {
  return {
    instance: {
      slug: null,
      status: null
    },
    responses: [],
    is_preview: false,
    responses_initialized: false,
    confirming_submit: false,
    submitting_survey: false,
    loaded: false
  };
};

export default {
  namespaced: true,

  state: defaultState,

  getters: {
    getSectionCount(state, getters, rootState) {
      return rootState.survey.instance.sections.length;
    },

    getCompletionPercentage(state, getters, rootState) {
      return (
        Math.floor(
          (state.responses.length /
            rootState.survey.instance.question_ids.length) *
            100
        ) || 0
      );
    },

    canGoBack(state, getters, rootState) {
      return rootState.survey.section_instance.position > 0;
    },

    canGoForward(state, getters, rootState) {
      return (
        rootState.survey.section_instance.position + 1 < getters.getSectionCount
      );
    },

    canSubmit(state, getters, rootState) {
      return (
        rootState.survey.section_instance.position + 1 ==
        getters.getSectionCount
      );
    },

    shouldShowContent(state) {
      return (
        ["sent", "started"].includes(state.instance.status) || state.is_preview
      );
    },

    shouldShowCompleted(state) {
      return ["completed"].includes(state.instance.status);
    },

    shouldShowClosed(state) {
      return ["timed-out"].includes(state.instance.status);
    },

    waitingForResponses(state) {
      return !state.is_preview && !state.responses_initialized;
    }
  },

  mutations: {
    setStatus(state, status) {
      state.instance.status = status;
    },

    setIsPreview(state, is_preview = false) {
      state.is_preview = is_preview;
    },

    setSurveysUserSlug(state, slug) {
      state.instance.slug = slug;
    },

    setResponses(state, responses) {
      state.responses = responses;
    },

    setResponse(state, response) {
      let existingResponse = state.responses.find(
        x => x.question_id == response.question_id
      );

      if (existingResponse != null) {
        Object.assign(existingResponse.value, response.value);
      } else {
        state.responses.push(response);
      }
    }
  },

  actions: {
    fetchStatus({ state, commit }) {
      return bApi
        .get(`/en/v1/surveys-users/${state.instance.slug}`)
        .then(({ data }) => {
          commit("setStatus", data.attributes.status_slug);
        });
    },

    initializeSection({ state, dispatch }, section_id) {
      state.responses_initialized = false;
      return dispatch("survey/fetchSection", section_id, { root: true })
        .then(() => {
          return dispatch("survey/fetchQuestionsForSection", section_id, {
            root: true
          });
        })
        .finally(() => {
          state.responses_initialized = true;
        });
    },

    fetchResponses({ state, commit }) {
      return bApi
        .get(`/en/v1/surveys-users/${state.instance.slug}/question-responses`)
        .then(({ data }) => {
          commit(
            "setResponses",
            data.map(x => ({
              question_id: x.id,
              value: x.value
            }))
          );
        });
    },

    postResponse({ state, commit, rootState }, response) {
      if (state.is_preview) {
        commit("setResponse", response);
      } else {
        return bApi
          .put(`/en/v1/surveys-users/${state.instance.slug}`, {
            surveys_user: {
              responses_attributes: [response]
            },
            survey_id: rootState.survey.instance.id
          })
          .then(data => {
            commit("setResponse", response);
          });
      }
    },

    submitSurvey({ state, commit }) {
      if (state.is_preview) {
        this._vm.$CpEvent.$emit("snackAlert", {
          message: `Surveys cannot be submitted in preview mode. Close this tab to return to the survey builder dashboard.`,
          color: "error"
        });
        return;
      }

      state.submitting_survey = true;
      return bApi
        .put(`/en/v1/surveys-users/${state.instance.slug}`, {
          surveys_user: {
            status_slug: "completed"
          }
        })
        .then(data => {
          commit("setStatus", "completed");
        })
        .finally(() => {
          state.submitting_survey = false;
        });
    },

    fetchWeblinkURL({ state, dispatch }, { campaign_id, survey_id }) {
      state.loaded = false;

      return bApi
        .get(
          `/en/v1/surveys/${survey_id}/campaigns/${campaign_id}/generate-anon-survey`
        )
        .then(({ data }) => {
          return data.surveys_user_slug;
        })
        .catch(error => {
          dispatch("handleErrorResponse", error.response, { root: true });
          state.loaded = true;
        });
    }
  }
};
