<template>
  <main>
    <cp-app :navItems="links" />
  </main>
</template>

<script>
export default {
  computed: {
    links() {
      return [
        {
          text: "Dashboard",
          to: "/",
          icon: "view-dashboard",
          exact: true
        },
        {
          text: "Surveys",
          to: "/surveys",
          icon: "clipboard-check"
        },
        {
          text: "Questions",
          to: "/questions",
          icon: "file-question"
        }
      ];
    }
  }
};
</script>

<style>
body main .container.app-container {
  /* <v-row> is messing with app-container sizing, this fixes it */
  padding-top: 1px;
}
</style>
