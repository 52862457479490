<template>
  <div class="dashboard">
      <v-card
          elevation="2"
          class="mb-5"
      >
        <v-card-title>
          Survey Preview Widgets
        </v-card-title>
        <hr class="mb-5"/>
        <cp-agreement-importance
            :question="{body: 'This is an agreement importance sample. Do you Agree?'}"
            :response="{}"
        ></cp-agreement-importance>
        <hr class="mb-5"/>
        <cp-agreement
            :question="{body: 'This is an agreement sample. Do you Agree?'}"
            :response="{}"
        ></cp-agreement>
        <hr class="mb-5"/>
        <cp-multi-selection
            :question="{body: 'Which of the multiple options will you choose?',
              options: [{id: 1, title: 'First Option'}, {id: 2, title: 'Second Option'}]}"
            :response="{}"
        ></cp-multi-selection>
        <hr class="mb-5"/>
        <cp-single-selection
            :question="{body: 'Which of the single options will you choose?',
              options: [{id: 1, title: 'First Option'}, {id: 2, title: 'Second Option'}]}"
            :response="{}"
        ></cp-single-selection>
        <hr class="mb-5"/>
        <cp-yes-no
            :question="{body: 'Is this a yes/no question?'}"
            :response="{}"
        ></cp-yes-no>
        <hr class="mb-5"/>
        <cp-text-entry
            :question="{body: 'This is a text field question.'}"
            :response="{}"
        ></cp-text-entry>
        <hr class="mb-5"/>
        <cp-numeric-entry
            :question="{body: 'How many numeric entries do you require?'}"
            :response="{}"
        ></cp-numeric-entry>
      </v-card>


      <cp-banner>
        <template v-slot:icon>
          <cp-icon icon="edit" />
        </template>
        <template v-slot:body>
          Operating as Client Name
        </template>
        <template v-slot:actions>
          <cp-btn text>
            Stop
          </cp-btn>
        </template>
      </cp-banner>

      <div class="boxed">
        <cp-alert type="warning" icon="warning" dismissable
          >This is an alert message</cp-alert
        >
        <cp-alert type="info" icon="info" dismissable
          >This is an informational message</cp-alert
        >
        <cp-alert type="error" icon="warning"
          >This is an error message</cp-alert
        >
        <cp-alert type="success" icon="success" dismissable
          >This is a confirmation message</cp-alert
        >
      </div>
      <br /><br />

      <cp-accordion>
        <v-expansion-panel v-for="(item, i) in 5" :key="i">
          <v-expansion-panel-header>Item</v-expansion-panel-header>
          <v-expansion-panel-content>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </cp-accordion>

      <br /><br />

      <cp-breadcrumb
        :items="[
          { text: 'Link 1', href: '#' },
          { text: 'Link 2', href: '#' },
          { text: 'Link 3', href: '#' },
        ]"
      />

      <br /><br />

      <cp-card>Content Here</cp-card>

      <br /><br />

      <div class="boxed">
        <cp-chip>Default</cp-chip>
        <cp-chip disabled>Default</cp-chip>
        <cp-chip class="slightly-rounded dense" color="error">
          <cp-icon class="icon--margin-right" fill="white" icon="info-filled" />
          <strong>With Icon</strong>
        </cp-chip>
      </div>

      <br /><br />

      <cp-date-picker v-model="picker"></cp-date-picker>

      <br /><br />

      <section class="collection">
        <div class="boxed">
          <cp-dialog width="500" header-background>
            <template #activator>
              <cp-btn color="primary">
                Default
              </cp-btn>
            </template>
            <template #title>
              Privacy Policy
            </template>
            <template #body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </template>
            <template #actions="{close}">
              <v-col cols="auto">
                <cp-btn color="primary" text @click="close">
                  More Info
                </cp-btn>
              </v-col>
              <v-col cols="auto" class="ml-auto">
                <cp-btn color="primary" text @click="close">
                  Cancel
                </cp-btn>
              </v-col>
              <v-col cols="auto">
                <cp-btn color="primary" @click="close">
                  Save
                </cp-btn>
              </v-col>
            </template>
          </cp-dialog>
        </div>

        <div class="boxed">
          <cp-dialog width="400">
            <template #activator>
              <cp-btn color="primary">
                Simple
              </cp-btn>
            </template>
            <template #body>
              Do you want to remove the file?
            </template>
            <template #actions="{close}">
              <v-col cols="auto" class="ml-auto">
                <cp-btn color="primary" text @click="close">
                  No
                </cp-btn>
              </v-col>
              <v-col cols="auto">
                <cp-btn color="primary" @click="close">
                  Yes
                </cp-btn>
              </v-col>
            </template>
          </cp-dialog>
        </div>

        <div class="boxed">
          <cp-fullscreen-modal #default="{close}">
            <cp-modal title="Title" @back="close" @close="close">
              <cp-breadcrumb
                :items="[
                  { text: 'Link 1', href: '#' },
                  { text: 'Link 2', href: '#' },
                  { text: 'Link 3', href: '#' },
                ]"
              />
              <cp-btn @click="close">Testing</cp-btn>
              <cp-card>Content Here</cp-card>
            </cp-modal>
          </cp-fullscreen-modal>
        </div>

        <br /><br />

        <h1>Data Tables</h1>

        <section class="collection">
          <h2 id="dataTable" class="collection-header">
            <a class="hash-link" href="#dataTable">Data Table <span>#</span></a>
          </h2>

          <h3 id="standardTable" class="collection-subheader">
            <a class="hash-link" href="#standardTable"
              >Standard Table <span>#</span></a
            >
          </h3>
          <p class="collection-p">
            Standard table structure
          </p>
          <div class="boxed">
            <cp-table
              :headers="[
                { text: 'First Name', value: 'first_name' },
                { text: 'Last Name', value: 'last_name' },
              ]"
              :items="users"
              sort-by="last_name"
            >
              <template #item-columns="{item}">
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
              </template>
            </cp-table>
          </div>

          <h3 id="expandableTable" class="collection-subheader">
            <a class="hash-link" href="#standardTable"
              >Expandable Table <span>#</span></a
            >
          </h3>
          <p class="collection-p">
            Expandable table structure
          </p>
          <div class="boxed">
            <cp-table
              :headers="[
                { text: 'First Name', value: 'first_name' },
                { text: 'Last Name', value: 'last_name' },
                { text: 'Expand', value: null },
              ]"
              :items="users"
              sort="last_name"
              add-header-tooltips
            >
              <template #item-columns="{item, isExpanded, expand}">
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
                <td
                  class="no-redirect"
                  :class="{ 'expand-icon': isExpanded }"
                  @click="expand(!isExpanded)"
                >
                  <cp-icon icon="arrow-drop" />
                </td>
              </template>
              <template #expanded-item="{item}">
                <cp-card class="background--gray-2">
                  <table>
                    <tbody>
                      <tr>
                        <td>Details for {{ item.first_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </cp-card>
              </template>
            </cp-table>
          </div>

          <h3 id="selectableTable" class="collection-subheader">
            <a class="hash-link" href="#selectableTable"
              >Selectable Table <span>#</span></a
            >
          </h3>
          <p class="collection-p">
            Selectable table structure
          </p>
          <div class="boxed">
            <cp-table
              :headers="[
                { text: 'First Name', value: 'first_name' },
                { text: 'Last Name', value: 'last_name' },
              ]"
              :items="users"
              sort="last_name"
              selectable
              item-url="/testing"
              disabled-text="User already assigned"
            >
              <template #item-columns="{item}">
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
              </template>
            </cp-table>
          </div>

          <h3 id="singleSelectTable" class="collection-subheader">
            <a class="hash-link" href="#singleSelectTable"
              >Single Select Table <span>#</span></a
            >
          </h3>
          <p class="collection-p">
            Single select table structure
          </p>
          <div class="boxed">
            <cp-table
              :headers="[
                { text: 'First Name', value: 'first_name' },
                { text: 'Last Name', value: 'last_name' },
              ]"
              :items="users"
              sort="last_name"
              selectable
              single-select
            >
              <template #item-columns="{item}">
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
              </template>
            </cp-table>
          </div>
        </section>
      </section>

      <br /><br />

      <section class="collection">
        <h2 id="windows" class="collection-header">
          <a class="hash-link" href="#windows">Windows <span>#</span></a>
        </h2>
        <div class="boxed">
          <cp-window />
        </div>
      </section>

      <br /><br />

      <h1>Form Elements</h1>

      <section class="collection">
        <h2 id="form" class="collection-header">
          <a class="hash-link" href="#form">Form Elements <span>#</span></a>
        </h2>
        <h3 id="formTextFields" class="collection-subheader">
          <a class="hash-link" href="#formTextFields"
            >Text Fields <span>#</span></a
          >
        </h3>
        <p class="collection-p" />
        <div class="boxed">
          <v-row>
            <v-col cols="6">
              <cp-input label="Label" messages="Message" />
              <br />
              <cp-input label="Label" messages="Message" solo />
              <br />
              <cp-input label="Label" messages="Message" filled />
              <br />
              <cp-input label="Label" messages="Message" outlined />
            </v-col>
            <v-col cols="6">
              <cp-input label="Label" placeholder="Placeholder" />
              <br />
              <cp-input label="Label" placeholder="Placeholder" solo />
              <br />
              <cp-input label="Label" placeholder="Placeholder" filled />
              <br />
              <cp-input label="Label" placeholder="Placeholder" outlined />
            </v-col>
          </v-row>
        </div>

        <h3 id="formSelects" class="collection-subheader">
          <a class="hash-link" href="#formSelects">Selects <span>#</span></a>
        </h3>
        <p class="collection-p">
          A standard single select has a multitude of configuration options.
        </p>
        <div class="boxed">
          <v-row>
            <v-col cols="6">
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
              />
              <br />
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                filled
              />
              <br />
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                outlined
              />
              <br />
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                solo
              />
            </v-col>
            <v-col cols="6">
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                disabled
              />
              <br />
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                filled
                disabled
              />
              <br />
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                outlined
                disabled
              />
              <br />
              <cp-select
                value="All"
                :items="['All', 'Item 1', 'Item 2']"
                name="example"
                label="Label"
                solo
                disabled
              />
            </v-col>
          </v-row>
        </div>

        <h3 id="formTextarea" class="collection-subheader">
          <a class="hash-link" href="#formTextarea">Textarea <span>#</span></a>
        </h3>
        <p class="collection-p">
          Textarea options.
        </p>
        <div class="boxed">
          <v-row>
            <v-col cols="6">
              <cp-textarea label="Default" hint="Hint" />
            </v-col>
            <v-col cols="6">
              <cp-textarea solo label="Solo" hint="Hint" />
            </v-col>
            <v-col cols="6">
              <cp-textarea filled label="Filled" hint="Hint" />
            </v-col>
            <v-col cols="6">
              <cp-textarea outlined label="Outlined" hint="Hint" />
            </v-col>
          </v-row>
        </div>

        <h3 id="formSearch" class="collection-subheader">
          <a class="hash-link" href="#formSearch">Search <span>#</span></a>
        </h3>
        <p class="collection-p" />
        <div class="boxed">
          <cp-search />
        </div>
      </section>

      <br /><br />

      <section class="collection">
        <h2 id="steppers" class="collection-header">
          <a class="hash-link" href="#steppers">Steppers <span>#</span></a>
        </h2>
        <div class="boxed">
          <cp-stepper />
        </div>
      </section>

      <br /><br />

      <section class="collection">
        <h2 id="ratings" class="collection-header">
          <a class="hash-link" href="#ratings">Ratings <span>#</span></a>
        </h2>
        <p class="collection-p">
          Rating options
        </p>
        <div class="boxed">
          <cp-rating />
        </div>
      </section>

      <br /><br />

      <section class="collection">
        <h2 id="progressLinear" class="collection-header">
          <a class="hash-link" href="#progressLinear"
            >Progress Linear <span>#</span></a
          >
        </h2>
        <div class="boxed">
          <cp-progress-linear
            top-text="Satisfaction"
            :top-value="33"
            :bottom-value="80"
            color="primary"
            height="12"
            background-color="#D0D0CE"
            rounded
            reactive
          />

          <cp-progress-linear
            top-text="Satisfaction"
            :top-value="88"
            :bottom-value="100"
            color="red"
            height="12"
            background-color="#D0D0CE"
            rounded
          />

          <cp-progress-linear
            top-text="Satisfaction"
            :top-value="55"
            :bottom-value="55"
            color="green"
            height="12"
            background-color="#D0D0CE"
            rounded
          />
        </div>
      </section>

      <br /><br />

      <section class="collection">
        <h2 id="footer" class="collection-header">
          <a class="hash-link" href="#footer">Footer <span>#</span></a>
        </h2>
        <div class="boxed">
          <cp-footer />
        </div>
      </section>

      <br /><br />

      <v-card height="400" width="256" class="mx-auto">
        <v-navigation-drawer permanent>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                Application
              </v-list-item-title>
              <v-list-item-subtitle>
                subtext
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-item v-for="item in items" :key="item.title" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>

      <section class="collection">
        <h2 id="cards" class="collection-header">
          <a class="hash-link" href="#cards">Cards <span>#</span></a>
        </h2>

        <h3 id="inheritCard" class="collection-subheader">
          <a class="hash-link" href="#inheritCard"
            >Inherit Card <span>#</span></a
          >
        </h3>
        <p class="collection-p">
          Inherit Card with padding and header styles.
        </p>
        <cp-card>
          <h5>H5 Title</h5>
          <p>Paragraph</p>
          <ul class="link-list">
            <li class="link-list-item">
              <a title="List Item Name" href="#">
                List Item Name
                <cp-icon icon="chevron-right" />
              </a>
            </li>
            <li class="link-list-item">
              <a title="List Item Name" href="#">
                List Item Name
                <cp-icon icon="chevron-right" />
              </a>
            </li>
            <li class="link-list-item">
              <a title="List Item Name" href="#">
                List Item Name
                <cp-icon icon="chevron-right" />
              </a>
            </li>
          </ul>
          <h5 class="margin-top">Type</h5>
          <p class="ma-0">Square / No Shadow</p>
        </cp-card>
        <br />
        <cp-card squared depressed>Sqaured / Depressed</cp-card>
        <br />
        <cp-card border-radius="30px">Custom Border Radius</cp-card>
        <br />
        <cp-card padding="0">No Padding</cp-card>
        <br />
        <h3 id="navCard" class="collection-subheader">
          <a class="hash-link" href="#navCard">Card with Nav <span>#</span></a>
        </h3>
        <p class="collection-p">
          Card with horizontal navigation bar.
        </p>
        <cp-card padding="0">
          <header class="underlined">
            <v-row align="center" justify="space-between">
              <h3>Title</h3>
              <div>
                <cp-btn text color="primary">
                  View All
                  <cp-icon
                    class="icon--small icon--margin-left"
                    icon="chevron-double-right"
                  />
                </cp-btn>
                <cp-btn icon color="primary">
                  <cp-icon icon="help-support" />
                </cp-btn>
              </div>
            </v-row>
          </header>
          <p><br />Content</p>
          <br />
        </cp-card>
        <br />
        <cp-card padding="0">
          <header class="underlined padding--vertical-0">
            <v-row align="center" justify="start">
              <h3 class="underlined__border-right">Title</h3>
              <nav class="horizontal">
                <ul>
                  <li><a href="#" class="active">Dashboard</a></li>
                  <li><a href="#">Employees</a></li>
                  <li><a href="#">Locations</a></li>
                  <li><a href="#">Positions</a></li>
                  <li><a href="#">Company</a></li>
                  <li class="menu"><a href="#">Menu</a></li>
                </ul>
              </nav>
              <div class="ml-auto">
                <cp-btn icon color="primary">
                  <cp-icon icon="help-support" />
                </cp-btn>
              </div>
            </v-row>
          </header>
          <p><br />Content</p>
          <br />
        </cp-card>
        <br />
        <h3 id="largeHeaderCard" class="collection-subheader">
          <a class="hash-link" href="#largeHeaderCard"
            >Card with large header <span>#</span></a
          >
        </h3>
        <cp-card class="cp-card--large-header">
          <header>
            <v-row align="center" no-gutters>
              <v-col class="left" cols="auto">
                <cp-icon icon="assignment" />
              </v-col>
              <v-col class="center">
                <h2>Let's Create an Assignment</h2>
                <p>
                  Do we tell the users anything at this point before they start
                  clicking items below.
                </p>
              </v-col>
              <v-col class="right" cols="auto">
                <cp-icon icon="info-outlined" />
              </v-col>
            </v-row>
          </header>
          <section class="content">
            Content
          </section>
        </cp-card>
        <br />
        <h3 id="messages" class="collection-subheader">
          <a href="#messages" class="hash-link">Messages Card <span>#</span></a>
        </h3>
        <p>Conversational chatting cards</p>
        <div class="messages">
          <div class="messages__message messages__message--left">
            <v-row dense>
              <v-col>
                <div class="message__meta">
                  <strong>Michelle Westbury</strong
                  ><span>03/11/19 00:00PM</span>
                </div>
                <cp-card depressed>
                  <cp-icon class="message__arrow" icon="arrow-up" />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse non metus in purus facilisis ultricies a a magna.
                  Maecenas iaculis, risus accumsan
                </cp-card>
              </v-col>
              <v-col cols="auto">
                <div class="message__user">
                  <a class="user__initials">MW</a>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="messages__message messages__message--right">
            <v-row dense>
              <v-col order="2">
                <div class="message__meta">
                  <strong>Robert McCloud</strong><span>03/11/19 00:00PM</span>
                </div>
                <cp-card depressed>
                  <cp-icon class="message__arrow" icon="arrow-up" />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse non metus
                </cp-card>
              </v-col>
              <v-col order="1" cols="auto">
                <div class="message__user">
                  <a class="user__initials">RM</a>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="messages__message messages__message--right">
            <v-row dense>
              <v-col order="2">
                <div class="message__meta">
                  <strong>Robert McCloud</strong><span>03/11/19 00:00PM</span>
                </div>
                <cp-card depressed>
                  <cp-icon class="message__arrow" icon="arrow-up" />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse non metus Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Suspendisse non metusLorem ipsum dolor sit
                  amet, consectetur adipiscing elit. Suspendisse non metusLorem
                  ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  non metusLorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Suspendisse non metus
                </cp-card>
              </v-col>
              <v-col order="1" cols="auto">
                <div class="message__user">
                  <a class="user__initials">RM</a>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <br />
        <h3 id="cardSingleClickThru" class="collection-subheader">
          <a class="hash-link" href="#cardSingleClickThru"
            >Card Single Click Thru <span>#</span></a
          >
        </h3>
        <p class="collection-p">
          Single lined card with click thu
        </p>

        <div class="pattern__single-line-click">
          <cp-card depressed>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col class="single-line-click__first" cols="auto">
                <cp-icon class="first__icon" icon="swift-bunny-icon" />
              </v-col>
              <v-col>
                <div class="single-line-click__text">
                  <strong>Text Goes Here</strong>
                </div>
              </v-col>
            </v-row>
          </cp-card>
        </div>
        <br />
        <div class="pattern__single-line-click">
          <a>
            <cp-card depressed>
              <v-row align="center" justify="space-between" no-gutters>
                <v-col class="single-line-click__first" cols="auto">
                  <cp-icon class="first__icon" icon="swift-bunny-icon" />
                </v-col>
                <v-col>
                  <div class="single-line-click__text">Text Goes Here</div>
                </v-col>
                <v-col class="single-line-click__last" cols="auto">
                  <cp-icon class="last__icon" icon="chevron-right" />
                </v-col>
              </v-row>
            </cp-card>
          </a>
        </div>
        <br />
        <div class="pattern__single-line-click">
          <a>
            <cp-card depressed>
              <v-row align="center" justify="space-between" no-gutters>
                <v-col class="single-line-click__first" cols="auto">
                  <div class="swift-letter-boxed background--swift-w">
                    W
                  </div>
                </v-col>
                <v-col>
                  <div class="single-line-click__text">Text Goes Here</div>
                </v-col>
                <v-col class="single-line-click__last" cols="auto">
                  <cp-icon class="last__icon" icon="chevron-right" />
                </v-col>
              </v-row>
            </cp-card>
          </a>
        </div>
        <br />
        <div class="pattern__single-line-click">
          <a>
            <cp-card depressed>
              <v-row align="center" justify="space-between" no-gutters>
                <v-col>
                  <div class="single-line-click__text">Text Goes Here</div>
                </v-col>
                <v-col class="single-line-click__last" cols="auto">
                  <cp-icon class="last__icon" icon="chevron-right" />
                </v-col>
              </v-row>
            </cp-card>
          </a>
        </div>
        <br />
        <cp-card>
          <div class="pattern__single-line-click">
            <a>
              <cp-card depressed class="background--gray">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col class="single-line-click__first" cols="auto">
                    <div class="swift-letter-boxed background--swift-w">
                      W
                    </div>
                  </v-col>
                  <v-col>
                    <div class="single-line-click__text">Text Goes Here</div>
                  </v-col>
                  <v-col class="single-line-click__last" cols="auto">
                    <cp-icon class="last__icon" icon="chevron-right" />
                  </v-col>
                </v-row>
              </cp-card>
            </a>
          </div>
          <div class="pattern__single-line-click">
            <a>
              <cp-card depressed class="background--gray">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col class="single-line-click__first" cols="auto">
                    <div class="swift-letter-boxed">
                      <cp-icon icon="swift-bunny-icon" />
                    </div>
                  </v-col>
                  <v-col>
                    <div class="single-line-click__text">Text Goes Here</div>
                  </v-col>
                  <v-col class="single-line-click__last" cols="auto">
                    <cp-icon class="last__icon" icon="chevron-right" />
                  </v-col>
                </v-row>
              </cp-card>
            </a>
          </div>
        </cp-card>
      </section>
  </div>
</template>

<script>
export default {
  name: "Demo",

  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
      right: null,

      picker: new Date().toISOString().substr(0, 10),
      date: null,
      users: [
        { id: 1, first_name: "Joe", last_name: "Bailey" },
        { id: 2, first_name: "Kevin", last_name: "McGrey" },
        { id: 3, first_name: "Brett", last_name: "Harris", selectable: false },
        { id: 4, first_name: "Rebecca", last_name: "Bachmann" },
        { id: 5, first_name: "Jonathan", last_name: "Bowman" },
      ],
    };
  },
};
</script>
