<template>
  <div>
    <cp-dialog
      :show-model.sync="campaign.launcher_open"
      header-background
      max-width="640"
      @modalClosed="resetCampaignInstance"
      scrollable
    >
      <template #title>
        Create New Campaign
      </template>
      <template #body>
        <v-card flat outlined class="pa-3 my-3">
          <v-tabs
            centered
            icons-and-text
            :value="campaign.instance.campaign_type_id - 1"
          >
            <v-tab
              v-for="(type, i) in campaign.campaign_types"
              :key="type.id"
              @click="campaign.instance.campaign_type_id = type.id"
            >
              {{ type.name }}
              <v-icon>{{ campaign.type_icon_map[type.id] }}</v-icon>
            </v-tab>
          </v-tabs>

          <v-divider class="mb-2" />

          <v-tabs-items :value="campaign.instance.campaign_type_id">
            <v-tab-item
              v-for="(type, i) in campaign.campaign_types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.description }}
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <div class="text-bold text-right">
          This cannot be changed later.
        </div>
      </template>
      <template #actions>
        <v-col cols="auto" class="ml-auto">
          <cp-btn color="primary" text @click="resetCampaignInstance()">
            Cancel
          </cp-btn>
        </v-col>
        <v-col cols="auto">
          <cp-btn
            color="primary"
            @click="$store.dispatch('campaign/activateCreateEditWindow', {})"
            :disabled="!campaign.instance.campaign_type_id"
          >
            Proceed
          </cp-btn>
        </v-col>
      </template>
    </cp-dialog>

    <cp-fullscreen-modal
      :show.sync="campaign.editor_open"
      :title="campaign.editor_window_title"
      :hide-default-activator="true"
      :close-confirmation="true"
      @fullscreenModalClosed="resetCampaignInstance()"
    >
      <campaign-create-edit :key="campaign.editor_reset_key" />
    </cp-fullscreen-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      campaign: state => state.campaign
    })
  },

  methods: {
    resetCampaignInstance() {
      if (!this.campaign.editor_open) {
        this.$store.commit("campaign/resetCampaignInstance");
      }
    }
  }
};
</script>
