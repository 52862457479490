<template>
  <v-row>
    <v-col class="text-entry-items">
      <h1
        class="text-entry-items__item py-2"
        v-for="(item, i) in question.details"
        :key="i"
      >
        {{ item.comment }}
      </h1>
    </v-col>
  </v-row>
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared
};
</script>

<style lang="scss" scoped>
.text-entry-items {
  max-height: 200px;
  overflow-y: auto;

  .text-entry-items__item {
    border-bottom: 1px solid #e6e6e6;

    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
