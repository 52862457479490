import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"background-color":"#f0f0f7"},attrs:{"min-height":"500","padding":"0","height":"100%","depressed":""}},[_c(VBanner,{staticClass:"section-list-banner font-weight-bold",attrs:{"single-line":""}},[_vm._v(" "+_vm._s(_vm.survey.instance.name || "Survey Title")+" "),_c(VBtn,{staticClass:"inq-title-edit-icon",attrs:{"icon":"","disabled":_vm.survey.instance.sections.length <= 0},on:{"click":function($event){_vm.survey.active_editor = 'info'}}},[_c(VIcon,{attrs:{"color":"primary","small":""}},[_vm._v(" edit ")])],1)],1),_c(VList,{staticStyle:{"background-color":"#f0f0f7"},attrs:{"nav":"","dense":""}},[_c(VSubheader,{staticClass:"text-body-1 font-weight-bold"},[_vm._v("Sections")]),_c(VListItemGroup,{staticStyle:{"background-color":"#f0f0f7"},attrs:{"value":_vm.survey.section_instance.id,"color":"primary"}},[_c('draggable',{on:{"update":_vm.updateSectionOnMove},model:{value:(_vm.survey.instance.sections),callback:function ($$v) {_vm.$set(_vm.survey.instance, "sections", $$v)},expression:"survey.instance.sections"}},_vm._l((_vm.survey.instance.sections),function(section){return _c(VListItem,{key:section.id,class:{
            'cp-active-section': section.id == _vm.survey.section_instance.id,
          },attrs:{"color":"primary","ripple":false},on:{"click":function($event){_vm.survey.active_editor = 'questions';
            _vm.$store.commit('survey/setSection', section);
            _vm.$store.dispatch('survey/fetchQuestionsForSection');}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-drag-vertical")])],1),_c('span',{staticClass:"active-text",domProps:{"innerHTML":_vm._s(section.name)}})],1)}),1)],1),_c(VSubheader,{staticClass:"primary--text justify-center font-weight-bold"},[_c('cp-btn',{staticClass:"text-body-1 pt-5",attrs:{"disabled":_vm.survey.instance.sections.length < 1 ||
            _vm.survey.active_editor == 'section',"plain":"","text":"","tile":""},on:{"click":function($event){_vm.$store.commit('survey/resetSectionInstance');
          _vm.survey.active_editor = 'section';}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" Add New Section ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }