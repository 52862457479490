import survey from "./modules/survey";
import question from "./modules/question";
import campaign from "./modules/campaign";
import surveyRenderService from "./modules/surveyRenderService";

export default {
  survey,
  question,
  campaign,
  surveyRenderService,
  root: {
    actions: {
      handleErrorResponse({ state }, error) {
        switch (error.data.error_id) {
          case "impersonation_prohibited":
            this._vm.$CpEvent.$emit("snackAlert", {
              message: error.data.message,
              color: "error"
            });
            throw error.data.error_id;
            break;

          case "campaign_closed":
            this._vm.$CpEvent.$emit("snackAlert", {
              message: error.data.message,
              color: "error"
            });
            throw error.data.error_id;
            break;

          default:
            this._vm.$CpEvent.$emit("snackAlert", {
              message:
                error.data.message || "There was an error with this request",
              color: "error"
            });
            throw "default_error";
        }
      }
    }
  }
};
