<template>
  <v-row>
    <q-r-likert :question="{ ...question, type: 'agreement' }" />
    <q-r-likert :question="{ ...question, type: 'importance' }" />
  </v-row>
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared
};
</script>
