<template>
  <v-textarea
    class="text-field"
    filled
    label="Comment Here"
    rows="3"
    v-model="value.text_entry"
  />
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  extends: QuestionsShared
};
</script>

<style lang="scss" scoped>
.text-field {
  display: inline-block;
  width: 100%;
  max-width: 660px;
}
</style>
