import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-btn',{attrs:{"text":"","tile":"","color":"primary"},on:{"click":function($event){_vm.survey.remove_section_open = true}}},[_c(VIcon,{staticClass:"ma-1",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-trash-can")]),_vm._v(" Remove Section ")],1),_c('cp-dialog',{staticClass:"mx-auto",attrs:{"show-model":_vm.survey.remove_section_open,"headerBackground":"","width":"600"},on:{"update:showModel":function($event){return _vm.$set(_vm.survey, "remove_section_open", $event)},"update:show-model":function($event){return _vm.$set(_vm.survey, "remove_section_open", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Remove Section ")]},proxy:true},(
        _vm.getMovableSections.length > 0 &&
          _vm.survey.section_instance.questions.length > 0
      )?{key:"body",fn:function(ref){
      var close = ref.close;
return [_c(VRow,{staticClass:"pb-8"},[_c(VCol,[_vm._v(" What would you like to do with the questions in this section? ")])],1),_c(VRow,{staticStyle:{"min-height":"189px"}},[_c(VTabs,{attrs:{"height":"48","centered":"","hide-slider":"","color":"#6B6B70","background-color":"grey lighten-2","fixed-tabs":""}},[_c(VTab,{staticClass:"pa-4 mx-1 my-auto text-no-wrap",attrs:{"height":"32","active-class":"active"}},[_vm._v(" Move to another section ")]),_c(VTabItem,[_c('cp-select',{ref:"newSection",attrs:{"label":"Select Section","items":_vm.getMovableSections,"item-text":"name","item-value":"id"},model:{value:(_vm.sectionToMoveTo),callback:function ($$v) {_vm.sectionToMoveTo=$$v},expression:"sectionToMoveTo"}}),_c(VRow,{staticClass:"ma-4",attrs:{"justify":"end"}},[_c('cp-btn',{attrs:{"text":"","color":"error"},on:{"click":close}},[_vm._v(" Cancel ")]),_c('cp-btn',{attrs:{"color":"primary","disabled":!_vm.sectionToMoveTo,"loading":_vm.survey.transferring_questions},on:{"click":function($event){return _vm.$store.dispatch(
                    'survey/transferAllQuestions',
                    _vm.sectionToMoveTo
                  )}}},[_vm._v(" Confirm ")])],1)],1),_c(VTab,{staticClass:"pa-4 mx-1 my-auto",attrs:{"height":"32","active-class":"active"}},[_vm._v(" Discard ")]),_c(VTabItem,{staticClass:"ma-4"},[_c(VRow,{attrs:{"justify":"center"}},[_c('cp-btn',{staticClass:"ma-4",attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")]),_c('cp-btn',{staticClass:"ma-5 ",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('survey/deleteSection')}}},[_vm._v(" Yes, Delete This Section ")])],1)],1)],1)],1)]}}:{key:"body",fn:function(ref){
                  var close = ref.close;
return [_c(VRow,{staticClass:"pb-4"},[_c(VCol,[_vm._v(" Are you sure? This will permanently delete this section. ")])],1),_c(VRow,{staticClass:"pb-4",attrs:{"align":"center","justify":"center"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")]),_c('cp-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('survey/deleteSection')}}},[_vm._v(" Yes, Delete This Section ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }