import { render, staticRenderFns } from "./SurveyClosed.vue?vue&type=template&id=607e44db&scoped=true&"
import script from "./SurveyClosed.vue?vue&type=script&lang=js&"
export * from "./SurveyClosed.vue?vue&type=script&lang=js&"
import style0 from "./SurveyClosed.vue?vue&type=style&index=0&id=607e44db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "607e44db",
  null
  
)

export default component.exports