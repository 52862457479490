<template>
  <div>
    <section-transfer-dialog />
    <question-remove-from-section-dialog />

    <v-data-table
      hide-default-footer
      dense
      disable-pagination
      fixed-header
      loading-text=""
      :loading="survey.loading_all_questions"
      :items="survey.section_instance.questions"
      :headers="[
        { width: '10px', sortable: false },
        {
          text: 'Question',
          value: 'body',
          sortable: false,
        },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ]"
    >
      <template #body="props">
        <draggable
          :list="props.items"
          tag="tbody"
          handle=".draggable"
          @update="updateQuestionOnMove"
        >
          <tr v-for="item in props.items">
            <td width="1%">
              <v-icon class="draggable">mdi-drag-vertical</v-icon>
            </td>

            <td class="text-body-1 py-2 pl-9" style="position: relative;">
              <v-icon
                v-if="item.created_by == 'Swift Bunny'"
                class="sb-owned-indicator"
              >
                $vuetify.icons.SwiftBunnyLogo
              </v-icon>
              <span class="table-link-text">{{ item.body }}</span>
            </td>

            <td width="20%" class="text-body-1">{{ item.type.text }}</td>

            <td width="5%">
              <question-menu-actions :question-object="item" />
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";

export default {
  components: {
    draggable,
  },

  methods: {
    buildOptions(question) {
      return [
        {
          title: "Edit Question",
          type: "storeAction",
          action: "question/activateCreateEditDialog",
          data: question.id,
        },
        {
          title: "Duplicate Question",
          type: "storeAction",
          action: "question/duplicateQuestion",
          data: question,
        },
        {
          title: "Transfer Section",
          type: "storeAction",
          action: "survey/activateTransferQuestionDialog",
          data: question,
        },
        {
          title: "Remove Question",
          type: "storeAction",
          action: "survey/activateRemoveQuestionDialog",
          data: question,
        },
      ];
    },

    removeSection(questionId) {
      this.$refs.SectionTransferDialog.open();
    },

    updateQuestionOnMove(evt) {
      console.log(this.survey.section_instance.questions.map(x => x.body));
      this.$store.dispatch("survey/reorderQuestionOnSection", {
        question_id: this.survey.section_instance.questions[evt.oldIndex].id,
        new_position: evt.newIndex,
      });
    },
  },

  computed: {
    ...mapState({
      survey: state => state.survey,
      question: state => state.question,
    }),
  },
};
</script>

<style scoped>
.v-application .text-center {
  background-color: white !important;
}
.v-data-table > .v-data-table__wrapper > table > thead {
  border-bottom: 2px solid black !important;
}
</style>
