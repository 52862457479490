import moment from "moment";
import { Api } from "@cp/lib";
const bApi = new Api(process.env.VUE_APP_TASSEL_API_PATH);
const mApi = new Api(process.env.VUE_APP_MARIGOLD_API_PATH);
import helpers from "../../mixins/helpers.js";

const defaultInstance = () => {
  return {
    id: null,
    title: "",
    creator_name: "",
    created_at: "",
    status: {
      slug: "draft",
    },
    start_on: moment()
      .set({
        hour: 9,
        minute: 0,
      })
      .add(1, "days")
      .toDate(),
    start_on_time: 9,
    close_on: moment()
      .set({
        hour: 17,
        minute: 0,
      })
      .add(8, "days")
      .toDate(),
    close_on_time: 17,
    reminder: false,
    reminder_on: null,
    reminder_on_time: null,
    campaign_type_id: 1,
    selected_recipients: [],
    locked_selected_recipients: [],
    response_rate: null,
    response_count: null,
    assigned_count: null,
    questions: [],
    survey_name: "",
    survey_id: null,
  };
};

const defaultState = () => {
  return {
    creating_campaign: false,
    editing_campaign: false,
    collection: [],
    instance: defaultInstance(),
    available_recipients: [],
    campaign_menu_actions: [],
    recipient_details_open: false,
    available_filters: {
      regions: [],
      position_categories: [],
    },
    selected_filters: {
      region: {
        value: 0,
        text: "All Regions",
      },
      position_category: {
        value: 0,
        text: "All Position Categories",
      },
    },
    campaign_types: [],
    viewer_open: false,
    editor_window_title: "",
    launcher_open: false,
    editor_open: false,
    confirming_save: false,
    confirming_close: false,
    confirming_cancel: false,
    saving: false,
    report_loaded: false,
    loaded_campaigns: false,
    start_on_open: false,
    close_on_open: false,
    reminder_on_open: false,
    reminder_on_option: 1,
    editor_reset_key: 0,
    current_step: 1,
    status_poll_interval_function: null,
    processing: false,
    type_icon_map: {
      weblink: "mdi-link-variant",
      confidential: "mdi-email-lock",
      "non-confidential": "mdi-email",
      1: "mdi-link-variant",
      2: "mdi-email-lock",
      3: "mdi-email",
    },
    type_name_map: {
      weblink: "Weblink",
      confidential: "Confidential Email",
      "non-confidential": "Non-Confidential Email",
      1: "Weblink",
      2: "Confidential Email",
      3: "Non-Confidential Email",
    },
    pagination: {
      number: 1,
      total: 1,
      size: 10,
    },
  };
};

const formatScheduleDatetime = date => {
  let dateString = helpers.methods.formatAmericanDate(date);
  let timeString = moment(date)
    .format("h:mm a")
    .toUpperCase();

  return `${dateString} at ${timeString}`;
};

export default {
  namespaced: true,

  state: defaultState,

  getters: {
    getRecipientCount(state, getters) {
      return [...new Set(state.instance.selected_recipients)].length;
    },

    getCalculatedEndDate(state) {
      if (state.instance.start_on && state.instance.days_open) {
        return moment(state.instance.start_on, "YYYY-MM-DD")
          .add(state.instance.days_open, "days")
          .format("MM/DD/YYYY");
      }

      return "";
    },

    getFormattedCreatedAt(state) {
      return helpers.methods.formatAmericanDate(state.instance.created_at);
    },

    getFormattedStartOn(state) {
      return formatScheduleDatetime(
        state.instance.start_on,
        state.instance.start_on_time
      );
    },

    getFormattedCloseOn(state) {
      if (state.instance.close_on) {
        return formatScheduleDatetime(
          state.instance.close_on,
          state.instance.close_on_time
        );
      }

      return null;
    },

    getFormattedReminderOn(state) {
      if (state.instance.reminder_on) {
        return formatScheduleDatetime(
          state.instance.reminder_on,
          state.instance.reminder_on_time
        );
      }

      if (state.instance.close_on) {
        const twoDaysBeforeClose = moment(state.instance.close_on).subtract(
          1,
          "days"
        );
        return formatScheduleDatetime(
          twoDaysBeforeClose,
          state.instance.close_on_time
        );
      }

      return null;
    },

    passesValidation(state) {
      let c = state.instance;
      let passed = true;

      // they all need a title
      if (c.title == null || !c.title.trim()) {
        passed = false;
      }

      // non weblinks need to have a start, a close, some recipients, and close date cant be before start date
      if (c.campaign_type_id != 1) {
        if (!c.start_on || !c.close_on || c.selected_recipients.length <= 0) {
          passed = false;
        }

        if (new Date(c.close_on) <= new Date(c.start_on)) {
          passed = false;
        }
      }

      // if there is a close date for any of them, it has to be before today
      if (c.close_on && new Date(c.close_on) <= new Date()) {
        passed = false;
      }

      // confidential campaigns need to have at least 7 recipients
      if (c.campaign_type_id == 2) {
        if (c.selected_recipients.length < 7) {
          passed = false;
        }
      }

      return passed;
    },

    canEdit(state) {
      if (["draft", "scheduled", "live"].includes(state.instance.status.slug)) {
        return true;
      }

      return false;
    },

    canClose(state) {
      if (["live"].includes(state.instance.status.slug)) {
        return true;
      }

      return false;
    },

    canCancel(state) {
      if (["scheduled"].includes(state.instance.status.slug)) {
        return true;
      }

      return false;
    },
  },

  mutations: {
    setCampaign(state, campaign) {
      campaign.start_on = new Date(campaign.start_on);
      campaign.close_on = campaign.close_on
        ? new Date(campaign.close_on)
        : null;
      campaign.start_on_time = campaign.start_on.getHours();
      campaign.close_on_time = campaign.close_on
        ? campaign.close_on.getHours()
        : null;

      Object.assign(state.instance, campaign);
    },

    setCampaigns(state, campaigns) {
      campaigns.forEach(c => {
        c.formatted_start_on = c.start_on
          ? formatScheduleDatetime(c.start_on)
          : null;
        c.formatted_close_on = c.close_on
          ? formatScheduleDatetime(c.close_on)
          : null;

        if (
          new Date(c.start_on) < new Date() &&
          c.status.slug == "scheduled" &&
          c.campaign_type_slug != "weblink"
        ) {
          c.status.slug = "sending";
        } else if (
          c.status.slug == "live" &&
          c.campaign_type_slug == "weblink" &&
          c.close_on == null
        ) {
          c.status.slug = "live";
        } else if (
          c.status.slug == "live" &&
          new Date(c.close_on) < new Date()
        ) {
          c.status.slug = "closing";
        }
      });

      state.collection = campaigns;
    },

    setPagination(state, meta) {
      state.pagination.number = meta.pagination.current;
      state.pagination.total = meta.pagination.last || meta.pagination.current;
    },

    setQuestionResponses(state, questions) {
      state.instance.questions = questions;
    },

    setCampaignTypes(state, campaignTypes) {
      state.campaign_types = campaignTypes;
    },

    setCreatingCampaign(state, creating = true) {
      state.creating_campaign = creating;
    },

    setEditingCampaign(state, editing = true) {
      state.editing_campaign = editing;
    },

    setViewer(state, isOpen = false) {
      state.viewer_open = isOpen;
    },

    setLauncher(state, isOpen = false) {
      state.launcher_open = isOpen;
    },

    setSaving(state, isSaving = false) {
      state.saving = isSaving;
    },

    setLoadedCampaigns(state, isLoaded = false) {
      state.loaded_campaigns = isLoaded;
    },

    setEditorWindow(state, isOpen = false) {
      state.editor_open = isOpen;
    },

    setEditorWindowTitle(state) {
      state.editor_window_title = state.creating_campaign
        ? "Create Campaign"
        : "Edit Campaign";
    },

    setAvailableRecipients(state, recipients) {
      state.available_recipients = recipients.map((x, i) => ({
        id: x.id,
        name: x.attributes.name,
        model:
          x.type == "geographical_group" ? "regions" : "position_categories",
        slug: x.attributes.slug,
        users: [],
        total_users: x.attributes.total_active_users,
        children_loaded: false,
      }));

      state.available_recipients.push(
        ...[
          {
            id: "no_region",
            name: "No Region",
            model: "regions",
            slug: "no_region",
            users: [],
            total_users: 1,
            children_loaded: false,
          },
          {
            id: "no_position",
            name: "No Position",
            model: "position_categories",
            slug: "no_position",
            users: [],
            total_users: 1,
            children_loaded: false,
          },
        ]
      );
    },

    setChildren(state, { item, data }) {
      let users = data.map(x => ({
        id: x.id,
        name: x.attributes.full_name,
        location_name: x.attributes.location_name,
        position_name: x.attributes.position_name,
        region_id: x.attributes.region_id,
        position_category_id: x.attributes.position_category_id,
        model: "users",
        isSelectable: !state.instance.locked_selected_recipients.includes(x.id),
        isSelected: !state.instance.locked_selected_recipients.includes(x.id),
      }));

      state.available_recipients.push(...users);
      item.users.push(...users);
    },

    setAvailableFilters(state, filters) {
      state.available_filters = filters;
    },

    resetCampaignInstance(state) {
      Object.assign(state.instance, defaultInstance());
      state.current_step = 1;
      state.launcher_open = false;
      state.editor_open = false;
      state.confirming_save = false;
      state.report_loaded = false;
      state.editor_reset_key++;
      state.campaign_menu_actions = [];
    },

    resetCampaignCollection(state) {
      state.loaded_campaigns = false;
      Object.assign(state.collection, []);
    },

    setCampaignMenuActions(state, actions) {
      state.campaign_menu_actions = actions;
    },
  },

  actions: {
    activateLauncher(
      { commit, dispatch, rootState },
      survey = rootState.survey.instance
    ) {
      commit("resetCampaignInstance");
      dispatch("fetchCampaignTypes");
      commit("survey/setSurvey", survey, { root: true });
      commit("setCreatingCampaign", true);
      commit("setEditingCampaign", false);
      commit("setLauncher", true);
    },

    // TODO this method is a little hectic right now
    activateCreateEditWindow(
      { commit, state, dispatch, rootState, rootGetters },
      {
        campaign_id = state.instance.id,
        survey_id = rootState.survey.instance.id,
      }
    ) {
      if (rootGetters["auth/isImpersonating"]) {
        this._vm.$CpEvent.$emit("snackAlert", {
          message: "Cannot create or edit campaigns while impersonating.",
          color: "error",
        });
        return;
      }

      if (campaign_id && survey_id) {
        dispatch("fetchCampaign", { campaign_id, survey_id }).then(data => {
          commit("setEditingCampaign", true);
          dispatch("fetchAvailableRecipients").then(() => {
            state.instance.assigned_users.forEach(item => {
              dispatch("expandParents", item);
            });

            state.instance.selected_recipients = state.instance.assigned_users;

            if (state.instance.status.slug == "live") {
              state.instance.locked_selected_recipients.push(
                ...state.instance.selected_recipients.map(x => x.id)
              );
            }
          });
        });
      } else {
        dispatch("fetchAvailableRecipients");
        // if this is a weblink, and a create (not edit), set time to current
        if (state.instance.campaign_type_id === 1) {
          state.instance.start_on = moment().format();
          state.instance.start_on_time = null;
        }
      }

      commit("setEditorWindow", true);
      commit("setCreatingCampaign", true);
      commit("setEditorWindowTitle");
      commit("setLauncher", false);
    },

    activateViewer(
      { commit, state, dispatch, rootState },
      {
        campaign_id = state.instance.id,
        survey_id = rootState.survey.instance.id,
      }
    ) {
      if (rootState.survey.instance.id === null) {
        dispatch("survey/fetchSurvey", survey_id, { root: true });
      }
      commit("setViewer", true);
      dispatch("fetchCampaign", { campaign_id, survey_id }).then(() => {
        dispatch("fetchQuestionResponses", { campaign_id, survey_id });
      });
    },

    fetchCampaign(
      { commit, state, dispatch, rootState },
      {
        campaign_id = state.instance.id,
        survey_id = rootState.survey.instance.id,
      }
    ) {
      return bApi
        .authorize()
        .get(`/en/v1/surveys/${survey_id}/campaigns/${campaign_id}`)
        .then(({ data }) => {
          let campaign = {
            ...data.attributes,
            survey_id: data.relationships.survey.data.id,
            id: data.id,
          };

          let uniqueUsers = [];
          campaign.assigned_users.forEach(u => {
            if (!uniqueUsers.find(x => x.id == u.id)) {
              uniqueUsers.push(u);
            }
          });
          campaign.assigned_users = uniqueUsers;
          campaign.assigned_count = uniqueUsers.length;

          commit("setCampaign", campaign);
        });
    },

    fetchActiveAndUpcoming({ state, commit }) {
      state.loaded_campaigns = false;

      return bApi
        .authorize()
        .get(`/en/v1/campaigns/active-and-upcoming`)
        .then(({ data }) => {
          commit(
            "setCampaigns",
            data.map(x => ({
              ...x.attributes,
              id: x.id,
              survey_id: x.relationships.survey.data.id,
            }))
          );
        })
        .finally(() => {
          state.loaded_campaigns = true;
        });
    },

    fetchCampaigns(
      { state, commit, rootState },
      survey_id = rootState.survey.instance.id
    ) {
      commit("setLoadedCampaigns", false);

      return bApi
        .authorize()
        .get(`/en/v1/surveys/${survey_id}/campaigns`, {
          page: {
            size: state.pagination.size,
            number: state.pagination.number,
          },
        })
        .then(({ data, meta }) => {
          let campaigns = data.map(x => ({
            ...x.attributes,
            id: x.id,
            survey_id: x.relationships.survey.data.id,
          }));

          campaigns.forEach(c => {
            let uniqueUsers = [];
            c.assigned_users.forEach(u => {
              if (!uniqueUsers.find(x => x.id == u.id)) {
                uniqueUsers.push(u);
              }
            });
            c.assigned_users = uniqueUsers;
            c.assigned_count = uniqueUsers.length;
          });

          commit("setCampaigns", campaigns);
          commit("setPagination", meta);

          this._vm.$CpEvent.$emit("campaignsFetched");
        })
        .finally(() => {
          commit("setLoadedCampaigns", true);
        });
    },

    fetchQuestionResponses(
      { state, commit, rootState },
      {
        campaign_id = state.instance.id,
        survey_id = rootState.survey.instance.id,
      }
    ) {
      state.report_loaded = false;

      return bApi
        .authorize()
        .get(`/en/v1/surveys/${survey_id}/campaigns/${campaign_id}/responses`)
        .then(({ data }) => {
          let questions = data.map((x, i) => ({
            ...x,
            body: x.question_body,
            position: i,
            previous_versions: [],
            active_on_survey: rootState.survey.instance.question_ids.includes(
              x.id
            ),
          }));

          // nest previous versions of questions underneath the current version
          questions.forEach(q => {
            let all_related = questions.filter(
              x => x.parent_version == q.parent_version
            );

            if (all_related.length > 1) {
              let highest_version = Math.max(
                ...all_related.map(x => x.version)
              );

              let current_question = all_related.find(
                x => x.version == highest_version
              );

              let previous_questions = all_related.filter(
                x => x.version != highest_version
              );

              if (current_question) {
                previous_questions.forEach(pq => {
                  current_question.previous_versions.push(pq);
                  questions.splice(questions.indexOf(pq), 1);
                });
              }
            }
          });

          commit("setQuestionResponses", questions);
        })
        .finally(() => {
          state.report_loaded = true;
        });
    },

    fetchCampaignTypes({ commit }) {
      return bApi
        .authorize()
        .get(`/en/v1/campaign-types`)
        .then(({ data }) =>
          commit(
            "setCampaignTypes",
            data.map(x => ({
              ...x.attributes,
              id: parseInt(x.id),
            }))
          )
        );
    },

    fetchAvailableRecipients({ commit, state }) {
      let available_recipients = [];

      return mApi
        .authorize()
        .get(`/en/v1/geographical_groups`)
        .then(({ data }) => {
          let recipients = data.filter(x =>
            ["region", "corporate"].includes(x.attributes.depth)
          );
          available_recipients.push(...recipients);

          return mApi
            .authorize()
            .get(`/en/v1/position_categories`)
            .then(({ data }) => {
              available_recipients.push(...data);
              commit("setAvailableRecipients", available_recipients);
            });
        });
    },

    getChildren({ commit, dispatch, state }, item) {
      if (!"users" in item || item.users.length > 0) return;

      return bApi
        .authorize()
        .get("/en/v1/users", {
          page: { size: "all" },
          [item.model]: [item.id],
        })
        .then(({ data }) => {
          commit("setChildren", { item, data });
        });
    },

    expandParents({ commit, state, dispatch }, item) {
      let parents = state.available_recipients.filter(
        x => x.id == item.region_id || x.id == item.position_category_id
      );

      parents.forEach(parent => {
        if (parent.users.length <= 0 && !parent.children_loaded) {
          parent.children_loaded = true;
          dispatch("getChildren", parent);
        }
      });
    },

    createCampaign({ state, commit, rootState }, campaign = state.instance) {
      commit("setSaving", true);
      campaign.user_ids = campaign.selected_recipients.map(x => x.id);

      return bApi
        .authorize()
        .post(`/en/v1/surveys/${rootState.survey.instance.id}/campaigns`, {
          campaign: campaign,
        })
        .then(() => {
          commit("resetCampaignInstance");
        })
        .finally(() => {
          commit("setSaving", false);
          commit("setCreatingCampaign", false);
        });
    },

    updateCampaign({ state, commit, rootState }, campaign = state.instance) {
      commit("setSaving", true);

      if (campaign.selected_recipients) {
        campaign.user_ids = campaign.selected_recipients.map(x => x.id);
      }

      state.processing = true;

      return bApi
        .authorize()
        .put(
          `/en/v1/surveys/${rootState.survey.instance.id}/campaigns/${campaign.id}`,
          {
            campaign: campaign,
          }
        )
        .then(() => {
          commit("resetCampaignInstance");
        })
        .finally(() => {
          commit("setSaving", false);
          commit("setEditingCampaign", false);
          state.processing = false;
        });
    },

    cancelCampaign({ state, commit, rootState }, campaign = state.instance) {
      commit("setSaving", true);
      state.processing = true;

      return bApi
        .authorize()
        .delete(
          `/en/v1/surveys/${rootState.survey.instance.id}/campaigns/${campaign.id}`
        )
        .then(() => {
          commit("resetCampaignInstance");
          this._vm.$CpEvent.$emit("snackAlert", {
            message: "Campaign cancelled",
            color: "success",
          });
        })
        .finally(() => {
          commit("setSaving", false);
          commit("setEditingCampaign", false);
          state.processing = false;
        });
    },

    pollForCampaignChanges(
      { state, commit, rootState },
      survey_id = rootState.survey.instance.id
    ) {
      return bApi
        .authorize()
        .get(`/en/v1/surveys/${survey_id}/campaigns`)
        .then(({ data }) => {
          let campaigns = data.map(x => ({
            ...x.attributes,
            id: x.id,
            survey_id: x.relationships.survey.data.id,
          }));

          let campaign_poll_pending =
            campaigns.filter(
              c =>
                (new Date(c.start_on) < new Date() &&
                  c.status.slug == "scheduled") ||
                (new Date(c.close_on) < new Date() && c.status.slug == "live")
            ).length > 0;

          if (!campaign_poll_pending) {
            clearInterval(state.status_poll_interval_function);
            commit("setCampaigns", campaigns);
          }
        });
    },

    activateCloseCampaignDialog({ state, dispatch }, campaign) {
      state.confirming_close = true;
    },

    activateCancelCampaignDialog({ state, dispatch }, campaign) {
      state.confirming_cancel = true;
    },

    buildCampaignMenuActions(
      { state, commit, getters, rootState },
      campaign = state.instance
    ) {
      commit("resetCampaignInstance");
      commit("setCampaign", campaign);
      let actions = [];

      actions.push({
        title: "View Details",
        type: "storeAction",
        action: "campaign/activateViewer",
        data: {
          campaign_id: campaign.id,
          survey_id: rootState.survey.instance.id,
        },
      });

      if (getters.canEdit) {
        actions.push({
          title: "Edit",
          type: "storeAction",
          action: "campaign/activateCreateEditWindow",
          data: {
            campaign_id: campaign.id,
            survey_id: rootState.survey.instance.id,
          },
        });
      }

      if (getters.canCancel) {
        actions.push({
          title: "Cancel",
          type: "storeAction",
          action: "campaign/activateCancelCampaignDialog",
          data: {
            id: campaign.id,
          },
        });
      }

      if (getters.canClose) {
        actions.push({
          title: "Close",
          type: "storeAction",
          action: "campaign/activateCloseCampaignDialog",
          data: {
            id: campaign.id,
            close_on: moment().toDate(),
          },
        });
      }

      commit("setCampaignMenuActions", actions);
    },

    downloadResults({ state, rootState }, survey_id, campaign) {
      campaign = campaign || state.instance;
      survey_id =
        survey_id || campaign.survey_id || rootState.survey.instance.id;

      return bApi
        .authorize()
        .download(
          `/en/v1/surveys/${survey_id}/campaigns/${campaign.id}/responses.xlsx`,
          `${campaign.title}.xlsx`
        );
    },
  },
};
