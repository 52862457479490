import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-container"},[_c('div',{staticClass:"survey-header"},[(_vm.service.is_preview)?_c('div',{staticClass:"survey-preview-banner"},[_vm._v(" Survey Preview ")]):_vm._e(),_c('h1',{staticClass:"survey-title"},[_vm._v(_vm._s(_vm.survey.instance.name))]),_c(VMenu,{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mb-5",attrs:{"text":""}},on),[_vm._v(" Page "+_vm._s(_vm.section.position + 1 || "-")+" / "+_vm._s(_vm.survey.instance.sections.length)+" "),_c(VIcon,[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,{staticClass:"pa-0 text-center",attrs:{"dense":""}},_vm._l((_vm.survey.instance.sections.length),function(n,i){return _c(VListItem,{key:i,staticClass:"pa-0 d-block"},[_c(VBtn,{staticClass:"d-inline-block text-left",attrs:{"text":"","tile":"","disabled":_vm.section.position == i,"width":"100%","min-height":"40"},on:{"click":function($event){return _vm.$store.dispatch(
                'surveyRenderService/initializeSection',
                _vm.survey.instance.sections[i].id
              )}}},[_vm._v(" Page "+_vm._s(n)+" ")])],1)}),1)],1),_c('div',{staticClass:"progress-bar",style:(("width: " + _vm.getCompletionPercentage + "%;"))},[_c('span',{class:{ 'shift-left': _vm.getCompletionPercentage >= 4 }},[_vm._v(" "+_vm._s(_vm.getCompletionPercentage)+"% ")])])],1),_c('div',{staticClass:"survey-body",class:{ 'is-preview': _vm.service.is_preview }},[_c('div',{staticClass:"survey-width-limit"},[_c('div',{staticClass:"text-center py-6"},[_c('h1',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_vm.section.name)+" ")]),(_vm.section.instructions)?_c('h1',{staticClass:"section-instructions"},[_vm._v(" "+_vm._s(_vm.section.instructions)+" ")]):_vm._e()]),_c(VSlideXReverseTransition,[(_vm.section.questions.length > 0 && !_vm.waitingForResponses)?_c('div',_vm._l((_vm.section.questions),function(question,i){return _c('q-wrapper',{key:question.id,attrs:{"question":question,"response":_vm.service.responses.find(function (x) { return x.question_id == question.id; })}})}),1):(!_vm.waitingForResponses)?_c('p',{staticClass:"text-center font-italic text--secondary"},[_vm._v(" This section has no questions. ")]):_vm._e()]),(_vm.survey.loading_all_questions)?_c('div',{staticClass:"text-center section-loader"},[_c(VProgressCircular,{attrs:{"size":100,"width":8,"color":"primary","indeterminate":""}})],1):_vm._e()],1)]),_c('survey-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }