import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-fullscreen-modal',{attrs:{"title":_vm.title,"back-text":"Close","back-icon":"close","show":_vm.survey.editor_open},on:{"fullscreenModalClosed":_vm.closeModal},scopedSlots:_vm._u([(!_vm.hideActivator)?{key:"activator",fn:function(){return [_c(VBtn,{attrs:{"rounded":"","dense":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('survey/activateCreateEditDialog')}}},[_c(VIcon,[_vm._v("mdi-"+_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)]},proxy:true}:null,{key:"default",fn:function(ref){
var close = ref.close;
return [_c('cp-card',{staticClass:"mx-auto ma-0 pa-0"},[_c('cp-banner',{attrs:{"single-line":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true},{key:"actions",fn:function(){return [_c('cp-btn',{staticClass:"text-body-1",attrs:{"text":"","color":"primary","href":("/surveys/" + (_vm.survey.instance.id) + "/preview"),"target":"_blank","disabled":_vm.survey.instance.sections.length <= 0}},[_c(VIcon,{staticClass:"ma-2"},[_vm._v("mdi-eye")]),_vm._v(" Preview ")],1)]},proxy:true}],null,true)}),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('section-list-card')],1),_c(VCol,{attrs:{"cols":"8"}},[(_vm.survey.active_editor == 'info')?_c('survey-info-create-edit-card'):_vm._e(),(_vm.survey.active_editor == 'section')?_c('section-create-edit-card'):_vm._e(),(_vm.survey.active_editor == 'questions')?_c('survey-questions-card'):_vm._e()],1)],1)],1)],1)]}}],null,true)},[_c(VRow,{staticClass:"footer-row"},[_c(VCol,{attrs:{"cols":"12"}},[_c('cp-footer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }