<template>
  <div class="cp-multi-select">
    <v-checkbox
      v-for="option in question.options.filter(x => !x._destroy)"
      v-model="value.multiple_select"
      :key="option.id"
      :label="option.body"
      :value="option.id"
      class="text-bold my-0"
    />
  </div>
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  extends: QuestionsShared,

  data() {
    return {
      value: {
        multiple_select: []
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cp-multi-select {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;

  &::v-deep .v-messages {
    display: none !important;
  }

  &::v-deep {
    .v-input--selection-controls__input {
      align-self: flex-start;
    }

    .v-label {
      margin-top: 2px;
    }
  }

  .v-input {
    text-align: left;
    width: 100%;
    padding: 4px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 50%;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 30%;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      width: 50%;
    }
  }
}
</style>
