<template>
  <div class="campaign-card">
    <v-card class="pa-2 my-2">
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="campaign-card-type">
          <div class="campaign-info-top">
            <v-btn
              large
              v-if="campaignListItem.campaign_type_slug == 'weblink'"
              :disabled="campaignListItem.status.slug != 'live'"
              @click="copyLink"
              icon
            >
              <v-icon>
                {{ campaign.type_icon_map[campaignListItem.campaign_type_id] }}
              </v-icon>
            </v-btn>

            <v-icon v-else>
              {{ campaign.type_icon_map[campaignListItem.campaign_type_id] }}
            </v-icon>
          </div>
          {{
            campaignListItem.campaign_type_slug == "weblink" ? "Link" : "Email"
          }}
        </v-col>
        <v-divider vertical class="mr-5" />
        <v-col cols="10" sm="2" lg="6">
          <div class="text-truncate">
            <a
              class="text-h6 mt-1 mr-3"
              @click="
                $store.dispatch('campaign/activateViewer', {
                  campaign_id: campaignListItem.id,
                  survey_id: campaignListItem.survey_id
                })
              "
            >
              {{ campaignListItem.title }}
            </a>
          </div>
          <v-icon>mdi-account-circle</v-icon>
          <span class="text-body-2">
            {{ campaignListItem.creator_name }}
          </span>
          <br />
          <div class="mt-2">
            <v-chip label small class="text-body-1 mr-1 mb-1">
              Start:
              {{ campaignListItem.formatted_start_on }}
            </v-chip>
            <v-chip label small class="text-body-1 mb-1">
              Close:
              {{ campaignListItem.formatted_close_on || "Indefinite" }}
            </v-chip>
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="3" sm="2" lg="1" align="center">
          <div class="px-5">
            <div class="campaign-info-top">
              <div
                v-if="campaignListItem.status.slug == 'live'"
                class="campaign-live-indicator"
              />
              <v-icon
                v-else-if="campaignListItem.status.slug == 'completed'"
                color="success"
              >
                mdi-check-decagram
              </v-icon>
              <v-icon
                v-else-if="
                  campaignListItem.status.slug == 'scheduled' &&
                    campaignListItem.campaign_type_slug != 'weblink'
                "
                color="warning"
              >
                mdi-rocket
              </v-icon>
              <v-progress-circular
                v-else-if="campaignListItem.status.slug == 'closing'"
                width="3"
                indeterminate
                color="warning"
              >
                <v-icon color="warning" style="top: 48%;">
                  mdi-close-circle
                </v-icon>
              </v-progress-circular>
              <v-icon
                v-else-if="
                  campaignListItem.status.slug == 'completed' &&
                    campaignListItem.campaign_type_slug != 'weblink'
                "
                color="success"
              >
                mdi-check-decagram
              </v-icon>
              <v-progress-circular
                v-else-if="
                  campaignListItem.status.slug == 'sending' &&
                    campaignListItem.campaign_type_slug != 'weblink'
                "
                width="3"
                indeterminate
                color="primary"
              >
                <v-icon color="primary" style="top: 48%;" size="18px">
                  mdi-rocket-launch
                </v-icon>
              </v-progress-circular>
            </div>
            <span style="white-space: nowrap;">{{
              campaignListItem.status.slug | capitalize
            }}</span>
          </div>
        </v-col>
        <v-col cols="3" sm="2" lg="1" align="center">
          <template
            v-if="
              campaignListItem.campaign_type_slug == 'weblink' &&
                campaignListItem.status.slug == 'live'
            "
          >
            <div class="campaign-info-top">
              <v-btn icon color="primary" @click="copyLink">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </div>
            Copy Link
          </template>
          <template
            v-else-if="campaignListItem.campaign_type_slug != 'weblink'"
          >
            <div class="campaign-info-top">
              <cp-doughnut
                :value="campaignListItem.response_rate || 0"
                hide-value
                size="40px"
                cutout="60%"
              />
            </div>
            Response %
          </template>
        </v-col>
        <v-divider vertical />
        <v-col cols="3" sm="2" lg="1" align="center">
          <div class="campaign-info-top">
            <div>
              {{
                campaignListItem.campaign_type_slug == "weblink"
                  ? campaignListItem.clicks
                  : campaignListItem.assigned_count
              }}
            </div>
          </div>
          {{
            campaignListItem.campaign_type_slug == "weblink"
              ? "Clicked"
              : campaignListItem.status.slug == "live"
              ? "Sent"
              : "Assigned"
          }}
        </v-col>
        <v-divider vertical />
        <v-col cols="3" sm="2" lg="1" align="center">
          <div class="campaign-info-top">
            <div v-if="campaignListItem.campaign_type_slug == 'weblink'">
              {{ campaignListItem.response_count }}
            </div>
            <div v-else-if="campaignListItem.assigned_count">
              {{ campaignListItem.response_count || 0 }}
            </div>
            <div v-else>-</div>
          </div>
          Responded
        </v-col>
        <v-divider vertical />
        <v-col cols="auto" class="pl-1" align="center">
          <campaign-menu-actions :campaign-object="campaignListItem" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import helpers from "@/mixins/helpers";

export default {
  props: { campaignListItem: { type: Object, default: null } },

  mixins: [helpers],

  computed: {
    ...mapState({
      campaign: state => state.campaign,
      survey: state => state.survey
    })
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(
        `${process.env.VUE_APP_DARKHAVEN_UI_PATH}/feedback/weblink/${this.campaignListItem.id}/${this.survey.instance.id}`
      );
      this.$CpEvent.$emit("snackAlert", {
        message: `Campaign Link Copied!`,
        color: "success"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.campaign-card-type {
  width: 56px;
  text-align: center;
}

.circ {
  position: absolute;
  bottom: 12px;
}

.campaign-type-badge::v-deep .v-badge__badge {
  color: rgba(0, 0, 0, 0.54);
  min-width: 14px;
  height: 14px;
  padding: 0;
}

.campaign-live-indicator {
  display: inline-block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #dc001b;
  box-shadow: 0 0 6px 4px rgba(#dc001b, 0.15);
  animation-duration: 1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.campaign-info-top {
  position: relative;
  height: 44px;

  &::v-deep .v-icon,
  div,
  .v-progress-circular {
    display: inline-block;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
