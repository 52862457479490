import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaign-details"},[_c('div',{staticClass:"campaign-details__header"},[_c(VRow,{staticClass:"mb-3 mt-2",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('h5',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.campaign.instance.title)+" "),_c(VIcon,{staticClass:"ml-3 mr-1 pb-0"},[_vm._v("mdi-account-circle")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.campaign.instance.creator_name))])],1),_c(VSheet,{staticClass:"d-inline-block px-2 text-body-1",attrs:{"color":"#e6e6e6","rounded":""}},[_vm._v(" Created: "+_vm._s(_vm.getFormattedCreatedAt)+" ")]),(_vm.campaign.instance.campaign_type_slug != 'weblink')?_c(VSheet,{staticClass:"d-inline-block px-2 ml-2 text-body-1",attrs:{"color":"#e6e6e6","rounded":""}},[_vm._v(" Start: "+_vm._s(_vm.getFormattedStartOn)+" ")]):_vm._e(),(_vm.campaign.instance.close_on)?_c(VSheet,{staticClass:"d-inline-block px-2 ml-2 text-body-1",attrs:{"color":"#e6e6e6","rounded":""}},[_vm._v(" Close: "+_vm._s(_vm.getFormattedCloseOn)+" ")]):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"pa-0 mt-1",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('campaign/downloadResults')}}},[_vm._v(" Export "),_c(VIcon,{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-export")])],1),(
                _vm.campaign.instance.campaign_type_slug == 'weblink' &&
                  _vm.campaign.instance.status.slug == 'live'
              )?_c(VBtn,{staticClass:"pa-0 mt-1 ml-4",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.copyLink(_vm.campaign.instance.survey_id)}}},[_vm._v(" Copy Link "),_c(VIcon,{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1):_vm._e()],1)],1)],1),_c(VSpacer),(_vm.campaign.instance.campaign_type_slug == 'weblink')?[_c(VCol,{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('cp-chip-combo',{attrs:{"disabled":"","stacked":"","items":[
              {
                leftValue: 'Respondents',
                rightValue: _vm.campaign.instance.response_count
              }
            ]}})],1)]:[_c(VCol,{staticClass:"text-center",attrs:{"cols":"auto"}},[(_vm.campaign.instance.response_rate != null)?_c('response-rate-doughnut',{attrs:{"value":_vm.campaign.instance.response_rate,"size":"140px"}}):_c(VSkeletonLoader,{staticClass:"d-inline-block",attrs:{"type":"card","width":"120px","height":"120px"}})],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VMenu,{attrs:{"max-width":"1000","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('cp-chip-combo',{attrs:{"stacked":"","items":[
                  {
                    leftValue: 'Recipients',
                    rightValue: _vm.campaign.instance.assigned_count
                  },
                  {
                    leftValue: 'Respondents',
                    rightValue: _vm.campaign.instance.response_count
                  }
                ]},on:{"chip-combo-clicked":_vm.showRecipients}})]}}]),model:{value:(_vm.campaign.recipient_details_open),callback:function ($$v) {_vm.$set(_vm.campaign, "recipient_details_open", $$v)},expression:"campaign.recipient_details_open"}},[_c(VCard,{attrs:{"color":"#ffffff","max-height":"300"}},[_c(VCardText,{staticClass:"pa-0"},[_c(VSimpleTable,{attrs:{"fixed-header":"","dense":"","height":"300"}},[_c('thead',{staticClass:"recipient-table-header"},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Position ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Location ")])])]),_c('tbody',{staticClass:"recipient-table-data"},_vm._l((_vm.campaign.instance.assigned_users),function(user){return _c('tr',{key:user.id},[_c('td',[_vm._v(_vm._s(user.full_name))]),_c('td',[_vm._v(_vm._s(user.position_name))]),_c('td',[_vm._v(_vm._s(user.location_name))])])}),0)])],1)],1)],1)],1)]],2)],1),_c('div',{staticClass:"campaign-details__body"},[(!_vm.campaign.report_loaded)?_c(VSkeletonLoader,{attrs:{"type":"text, article, text, article, text, article, text, article, text, article"}}):_vm._l((_vm.campaign.instance.questions),function(question,i){return _c('q-r-summary',{key:i,attrs:{"question":question}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }