<template>
  <div>
    <template v-if="campaign.instance.campaign_type_id != 1">
      <v-stepper-step
        step="2"
        :complete="campaign.current_step > 2"
        :editable="campaign.current_step > 2"
        edit-icon="$complete"
      >
        Pick Your Recipients
        <small>{{ getRecipientCount }} total employees</small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <div class="limited-max-width">
          <h4>
            You can select from any of the employees found within the Swift
            Bunny Data Manager, organized by region and position category.
          </h4>
          <h4 v-if="campaign.instance.campaign_type_id == 2">
            <v-banner
              icon="mdi-alert-circle-outline"
              icon-color="warning"
              outlined
              rounded
              class="mt-2"
            >
              Note, since this is a confidential campaign, we recommend
              selecting at least 15 recipients to have the best chance of
              responses being visible.
            </v-banner>
          </h4>
          <cp-card dense flush-body flat color="#f1f1f1" class="my-4">
            <cp-banner color="#f1f1f1">
              <template #body>
                Who's Getting this Survey?
              </template>
              <template #actions>
                {{ getRecipientCount }} Unique Recipients
              </template>
            </cp-banner>

            <div class="pa-4">
              <div v-for="category in categories" class="pb-4">
                <h1 class="text-bold mb-2">
                  Employees by {{ category.label }}
                </h1>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(area, i) in campaign.available_recipients.filter(
                      x => x.model == category.model
                    )"
                    :key="i"
                    @change="getChildren(area)"
                    :disabled="area.total_users <= 0"
                  >
                    <v-expansion-panel-header>
                      {{ area.name }}
                      <template v-if="area.total_users <= 0">
                        - No Active Employees
                      </template>

                      <!-- <template v-else>
                        - {{
                      
                          campaign.instance.selected_recipients.filter(
                            x => x[category.selectedKey] === area.id
                          ).length
                        
                        }} Selected
                      </template> -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        v-model="recipientSearch[area.name]"
                        class="mb-2"
                        prepend-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                      />
                      <v-data-table
                        class="recipient-data-table"
                        v-model="campaign.instance.selected_recipients"
                        :items="area.users"
                        :loading="gettingChildren"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                        :search="recipientSearch[area.name]"
                        show-select
                        dense
                        :headers="[
                          {
                            text: 'Name',
                            align: 'start',
                            sortable: true,
                            value: 'name',
                          },
                          {
                            text: 'Location',
                            align: 'start',
                            sortable: true,
                            value: 'location_name',
                          },
                          {
                            text: 'Position',
                            align: 'start',
                            sortable: true,
                            value: 'position_name',
                          },
                        ]"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </cp-card>

          <v-row class="mb-1">
            <v-col align="right">
              <v-btn
                text
                color="primary"
                rounded
                @click="campaign.current_step--"
                class="mr-4"
              >
                Back
              </v-btn>
              <v-btn
                color="primary"
                rounded
                @click="campaign.current_step++"
                :disabled="
                  getRecipientCount <= 0 ||
                    (campaign.instance.campaign_type_id == 2 &&
                      getRecipientCount < 15)
                "
              >
                Continue
              </v-btn>
              <br />
              <div
                v-if="
                  campaign.instance.campaign_type_id == 2 &&
                    getRecipientCount < 15
                "
                class="font-weight-light font-italic mt-2"
              >
                Please select {{ 15 - getRecipientCount }} more
                {{ "recipient" | pluralize(15 - getRecipientCount) }}.
              </div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>
    </template>

    <v-stepper-step
      :step="campaign.instance.campaign_type_id == 1 ? 2 : 3"
      :complete="
        campaign.current_step >
          (campaign.instance.campaign_type_id == 1 ? 2 : 3)
      "
      :editable="
        campaign.current_step >
          (campaign.instance.campaign_type_id == 1 ? 2 : 3)
      "
      edit-icon="$complete"
    >
      Schedule Your Campaign
    </v-stepper-step>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

const categories = [
  {
    label: "Region",
    model: "regions",
    selectedKey: "region_id",
  },
  {
    label: "Position Category",
    model: "position_categories",
    selectedKey: "position_category_id",
  },
];

export default {
  data() {
    return {
      categories,
      recipientSearch: {},
      gettingChildren: false,
    };
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign,
    }),

    ...mapGetters("campaign", ["getRecipientCount"]),
  },

  methods: {
    getChildren(item) {
      if (item.users.length <= 0) {
        this.gettingChildren = true;
        this.$store.dispatch("campaign/getChildren", item).finally(() => {
          this.gettingChildren = false;
        });
      }
    },

    selectAll({ value }, area) {
      area.users
        .filter(
          x => !this.campaign.instance.locked_selected_recipients.includes(x.id)
        )
        .forEach(item => {
          if (value) {
            this.campaign.instance.selected_recipients.push(item.id);
          } else {
            this.campaign.instance.selected_recipients = this.campaign.instance.selected_recipients.filter(
              x => x != item.id
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.limited-max-width {
  max-width: 760px;
}

.recipient-data-table::v-deep .v-data-table__wrapper {
  max-height: 300px;
  overflow-y: auto;
}
</style>
