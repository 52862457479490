<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" class="pt-0">
        <cp-card dense flush-body collapse-actions>
          <template #title>Surveys</template>

          <template #actions>
            <v-text-field
              class="inq-list-action"
              v-model="survey.search"
              placeholder="Search"
              hide-details
              dense
              append-icon="mdi-magnify"
            />
            <v-select
              class="inq-list-action"
              v-model="survey.filters.createdBy"
              placeholder="Creator"
              :items="survey.collection.map(x => x.created_by)"
              hide-details
              clearable
              dense
            />
            <v-combobox
              class="inq-list-action inq-list-action--wide"
              :items="[
                { text: 'Draft', value: 'draft' },
                { text: 'Active', value: 'active' },
                { text: 'Archived', value: 'archived' }
              ]"
              v-model="survey.filters.status"
              placeholder="Status"
              hide-details
              multiple
              dense
              clearable
            />
            <v-btn
              class="px-4"
              rounded
              color="primary"
              @click="$store.dispatch('survey/activateCreateEditDialog')"
            >
              <v-icon>mdi-plus</v-icon>
              Create Survey
            </v-btn>
          </template>

          <surveys-table :survey-collection="filteredSurveys" />
        </cp-card>
      </v-col>
    </v-row>

    <survey-create-edit-dialog hide-activator />
    <survey-archive-dialog />
    <survey-publish-dialog />
    <campaign-create-launcher />
  </div>
</template>

<script>
import { mapState } from "vuex";
import helpers from "../mixins/helpers.js";

export default {
  mixins: [helpers],

  computed: {
    ...mapState({
      survey: state => state.survey,
      campaign: state => state.campaign
    }),

    filteredSurveys() {
      let filteredResults = this.survey.collection;

      if (this.survey.filters.createdBy) {
        filteredResults = filteredResults.filter(
          x => x.created_by == this.survey.filters.createdBy
        );
      }

      if (this.survey.filters.status.length > 0) {
        filteredResults = filteredResults.filter(x =>
          this.survey.filters.status.map(y => y.value).includes(x.status_slug)
        );
      }

      return filteredResults || [];
    }
  },

  methods: {
    surveyChangedCallback() {
      this.$store.dispatch("survey/fetchSurveys", { extra_attrs: false });
      this.$store.commit("survey/resetSurveyInstance");
      this.$store.commit("survey/resetSectionInstance");
      this.$CpEvent.$emit("snackAlert", { message: "Survey updated" });
    },

    surveyDuplicatedCallback() {
      this.$store.dispatch("survey/fetchSurveys", { extra_attrs: false });
    }
  },

  mounted() {
    this.survey.collection = [];
    this.$store.dispatch("survey/fetchSurveys", { extra_attrs: false });
    this.$CpEvent.$on("surveyPublished", this.surveyChangedCallback);
    this.$CpEvent.$on("surveyArchived", this.surveyChangedCallback);
    this.$CpEvent.$on("surveyDuplicated", this.surveyDuplicatedCallback);
  },

  beforeDestroy() {
    this.$store.commit("survey/resetSurveyCollection");
    this.$store.commit("campaign/resetCampaignInstance");
    this.$store.commit("campaign/resetCampaignCollection");
    this.$CpEvent.$off("surveyPublished", this.surveyChangedCallback);
    this.$CpEvent.$off("surveyArchived", this.surveyChangedCallback);
    this.$CpEvent.$off("surveyDuplicated");
  },

  watch: {
    "survey.editor_open"(opening, closing) {
      if (closing) {
        this.$store.commit("survey/resetSurveyInstance");
        this.$store.commit("survey/resetSectionInstance");
        this.$store.dispatch("survey/fetchSurveys", { extra_attrs: false });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inq-list-action {
  width: 180px;
  margin-right: 12px;

  &.inq-list-action--wide {
    width: 290px;
  }
}
</style>
