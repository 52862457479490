<template>
  <div>
    <cp-dialog
      :show-model.sync="survey.transfer_question_open"
      class="mx-auto"
      headerBackground
      width="600"
      @modalClosed="
        $CpEvent.$off('questionTransferred', questionTransferredListener);
        $store.dispatch('survey/deactivateTransferQuestionDialog');
      "
    >
      <template #title>
        Transfer Question to Section
      </template>

      <template #body="{close}">
        To which section would you like to move this question?
        <cp-select
          v-model="sectionToMoveTo"
          :items="getMovableSections"
          item-text="name"
          item-value="id"
          name="sections"
          label="Select Section"
          return-object
          :disabled="getMovableSections.length < 1"
        />
      </template>

      <template #actions>
        <v-col cols="auto" class="ml-auto">
          <cp-btn
            text
            @click="$store.dispatch('survey/deactivateTransferQuestionDialog')"
          >
            Close
          </cp-btn>
          <cp-btn
            color="primary"
            rounded
            :disabled="getMovableSections.length < 1"
            @click="
              $store.dispatch('survey/transferQuestion', {
                section: sectionToMoveTo,
                question: question.instance
              })
            "
          >
            Transfer
          </cp-btn>
        </v-col>
      </template>
    </cp-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      sectionToMoveTo: null
    };
  },

  mounted() {
    this.$CpEvent.$on("questionTransferred", this.questionTransferredListener);
  },

  beforeDestroy() {
    this.$CpEvent.$off("questionTransferred", this.questionTransferredListener);
  },

  computed: {
    ...mapState({
      question: state => state.question,
      survey: state => state.survey
    }),

    ...mapGetters("survey", ["getMovableSections"])
  },

  methods: {
    questionTransferredListener() {
      this.$store.dispatch("survey/deactivateTransferQuestionDialog");
    }
  }
};
</script>
