<template>
  <div>
    <cp-dialog
      :show-model.sync="survey.remove_question_open"
      class="mx-auto"
      headerBackground
      width="500"
      @modalClosed="$store.dispatch('survey/deactivateRemoveQuestionDialog')"
    >
      <template #title>
        Remove Question
      </template>

      <template #body>
        Are you sure you want to remove this question from the survey?
        <v-row class="pt-5">
          <v-col align="center">
            <cp-btn
              color="error"
              text
              class="ma-2"
              @click="$store.dispatch('survey/deactivateRemoveQuestionDialog')"
            >
              Cancel
            </cp-btn>
            <cp-btn
              color="primary"
              rounded
              class="ma-2"
              @click="
                $store.dispatch(
                  'survey/removeQuestionFromSection',
                  question.instance.id
                )
              "
            >
              Yes, Remove
            </cp-btn>
          </v-col>
        </v-row>
      </template>
    </cp-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      question: state => state.question,
      survey: state => state.survey
    })
  }
};
</script>
