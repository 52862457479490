<template>
  <div class="survey-container">
    <div class="survey-header">
      <div v-if="service.is_preview" class="survey-preview-banner">
        Survey Preview
      </div>
      <h1 class="survey-title">{{ survey.instance.name }}</h1>
      <v-menu transition="slide-y-transition" bottom>
        <template #activator="{ on }">
          <v-btn class="mb-5" text v-on="on">
            Page {{ section.position + 1 || "-" }} /
            {{ survey.instance.sections.length }}
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense class="pa-0 text-center">
          <v-list-item
            class="pa-0 d-block"
            v-for="(n, i) in survey.instance.sections.length"
            :key="i"
          >
            <v-btn
              text
              tile
              :disabled="section.position == i"
              width="100%"
              min-height="40"
              class="d-inline-block text-left"
              @click="
                $store.dispatch(
                  'surveyRenderService/initializeSection',
                  survey.instance.sections[i].id
                )
              "
            >
              Page {{ n }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="progress-bar" :style="`width: ${getCompletionPercentage}%;`">
        <span :class="{ 'shift-left': getCompletionPercentage >= 4 }">
          {{ getCompletionPercentage }}%
        </span>
      </div>
    </div>

    <div class="survey-body" :class="{ 'is-preview': service.is_preview }">
      <div class="survey-width-limit">
        <div class="text-center py-6">
          <h1 class="section-title">
            {{ section.name }}
          </h1>
          <h1 v-if="section.instructions" class="section-instructions">
            {{ section.instructions }}
          </h1>
        </div>
        <v-slide-x-reverse-transition>
          <div v-if="section.questions.length > 0 && !waitingForResponses">
            <q-wrapper
              v-for="(question, i) in section.questions"
              :question="question"
              :response="
                service.responses.find(x => x.question_id == question.id)
              "
              :key="question.id"
            />
          </div>
          <p
            v-else-if="!waitingForResponses"
            class="text-center font-italic text--secondary"
          >
            This section has no questions.
          </p>
        </v-slide-x-reverse-transition>
        <div
          v-if="survey.loading_all_questions"
          class="text-center section-loader"
        >
          <v-progress-circular
            :size="100"
            :width="8"
            color="primary"
            indeterminate
          />
        </div>
      </div>
    </div>

    <survey-footer />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      service: state => state.surveyRenderService,
      survey: state => state.survey,
      section: state => state.survey.section_instance,
    }),

    ...mapGetters("surveyRenderService", [
      "getCompletionPercentage",
      "getSectionCount",
      "waitingForResponses",
    ]),
  },

  watch: {
    "section.position"(newVal, oldVal) {
      if (newVal != null && this.$route.query.section - 1 != newVal) {
        this.$router.push({
          query: {
            ...this.$route.query,
            section: newVal + 1,
          },
        });
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.survey-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #f1f1f1;
  flex-direction: column;

  .survey-preview-banner {
    text-align: center;
    font-weight: 600;
    background-color: #f0b323;
    padding: 0.5rem 0;
  }

  .survey-width-limit {
    max-width: 1200px;
    margin: 0 auto;
  }

  .survey-header {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #aaaaaa;
  }

  .survey-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    padding-top: 1rem;
    color: #007dba;
  }

  .progress-bar {
    transition: width 0.25s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    background-color: #007dba;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.85rem;
    border-right: 2px solid #007dba;

    span {
      transition: all 0.25s ease-in-out;
      text-align: right;
      position: absolute;
      right: 6px;
      margin-right: -34px;
      color: #007dba;

      &.shift-left {
        margin-right: 0;
        color: #ffffff;
      }
    }
  }

  .section-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    color: #666666;
  }

  .section-instructions {
    text-align: center;
  }

  .section-loader {
    padding-top: 24vh;
  }

  .survey-body {
    background-color: #f1f1f1;
    padding: 1rem;
    padding-top: 110px;
    min-height: calc(100vh - 124px);

    &.is-preview {
      padding-top: 156px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      text-align: center;
    }
  }

  .question {
    margin: 1rem 0;
    padding: 1rem 0;
    padding-top: 2rem;
    border-top: 1px solid #aaaaaa;
  }
}
</style>
