import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaign-card"},[_c(VCard,{staticClass:"pa-2 my-2"},[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VCol,{staticClass:"campaign-card-type",attrs:{"cols":"auto"}},[_c('div',{staticClass:"campaign-info-top"},[(_vm.campaignListItem.campaign_type_slug == 'weblink')?_c(VBtn,{attrs:{"large":"","disabled":_vm.campaignListItem.status.slug != 'live',"icon":""},on:{"click":_vm.copyLink}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.campaign.type_icon_map[_vm.campaignListItem.campaign_type_id])+" ")])],1):_c(VIcon,[_vm._v(" "+_vm._s(_vm.campaign.type_icon_map[_vm.campaignListItem.campaign_type_id])+" ")])],1),_vm._v(" "+_vm._s(_vm.campaignListItem.campaign_type_slug == "weblink" ? "Link" : "Email")+" ")]),_c(VDivider,{staticClass:"mr-5",attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"10","sm":"2","lg":"6"}},[_c('div',{staticClass:"text-truncate"},[_c('a',{staticClass:"text-h6 mt-1 mr-3",on:{"click":function($event){return _vm.$store.dispatch('campaign/activateViewer', {
                campaign_id: _vm.campaignListItem.id,
                survey_id: _vm.campaignListItem.survey_id
              })}}},[_vm._v(" "+_vm._s(_vm.campaignListItem.title)+" ")])]),_c(VIcon,[_vm._v("mdi-account-circle")]),_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.campaignListItem.creator_name)+" ")]),_c('br'),_c('div',{staticClass:"mt-2"},[_c(VChip,{staticClass:"text-body-1 mr-1 mb-1",attrs:{"label":"","small":""}},[_vm._v(" Start: "+_vm._s(_vm.campaignListItem.formatted_start_on)+" ")]),_c(VChip,{staticClass:"text-body-1 mb-1",attrs:{"label":"","small":""}},[_vm._v(" Close: "+_vm._s(_vm.campaignListItem.formatted_close_on || "Indefinite")+" ")])],1)],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"3","sm":"2","lg":"1","align":"center"}},[_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"campaign-info-top"},[(_vm.campaignListItem.status.slug == 'live')?_c('div',{staticClass:"campaign-live-indicator"}):(_vm.campaignListItem.status.slug == 'completed')?_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" mdi-check-decagram ")]):(
                _vm.campaignListItem.status.slug == 'scheduled' &&
                  _vm.campaignListItem.campaign_type_slug != 'weblink'
              )?_c(VIcon,{attrs:{"color":"warning"}},[_vm._v(" mdi-rocket ")]):(_vm.campaignListItem.status.slug == 'closing')?_c(VProgressCircular,{attrs:{"width":"3","indeterminate":"","color":"warning"}},[_c(VIcon,{staticStyle:{"top":"48%"},attrs:{"color":"warning"}},[_vm._v(" mdi-close-circle ")])],1):(
                _vm.campaignListItem.status.slug == 'completed' &&
                  _vm.campaignListItem.campaign_type_slug != 'weblink'
              )?_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" mdi-check-decagram ")]):(
                _vm.campaignListItem.status.slug == 'sending' &&
                  _vm.campaignListItem.campaign_type_slug != 'weblink'
              )?_c(VProgressCircular,{attrs:{"width":"3","indeterminate":"","color":"primary"}},[_c(VIcon,{staticStyle:{"top":"48%"},attrs:{"color":"primary","size":"18px"}},[_vm._v(" mdi-rocket-launch ")])],1):_vm._e()],1),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.campaignListItem.status.slug)))])])]),_c(VCol,{attrs:{"cols":"3","sm":"2","lg":"1","align":"center"}},[(
            _vm.campaignListItem.campaign_type_slug == 'weblink' &&
              _vm.campaignListItem.status.slug == 'live'
          )?[_c('div',{staticClass:"campaign-info-top"},[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":_vm.copyLink}},[_c(VIcon,[_vm._v("mdi-content-copy")])],1)],1),_vm._v(" Copy Link ")]:(_vm.campaignListItem.campaign_type_slug != 'weblink')?[_c('div',{staticClass:"campaign-info-top"},[_c('cp-doughnut',{attrs:{"value":_vm.campaignListItem.response_rate || 0,"hide-value":"","size":"40px","cutout":"60%"}})],1),_vm._v(" Response % ")]:_vm._e()],2),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"3","sm":"2","lg":"1","align":"center"}},[_c('div',{staticClass:"campaign-info-top"},[_c('div',[_vm._v(" "+_vm._s(_vm.campaignListItem.campaign_type_slug == "weblink" ? _vm.campaignListItem.clicks : _vm.campaignListItem.assigned_count)+" ")])]),_vm._v(" "+_vm._s(_vm.campaignListItem.campaign_type_slug == "weblink" ? "Clicked" : _vm.campaignListItem.status.slug == "live" ? "Sent" : "Assigned")+" ")]),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"3","sm":"2","lg":"1","align":"center"}},[_c('div',{staticClass:"campaign-info-top"},[(_vm.campaignListItem.campaign_type_slug == 'weblink')?_c('div',[_vm._v(" "+_vm._s(_vm.campaignListItem.response_count)+" ")]):(_vm.campaignListItem.assigned_count)?_c('div',[_vm._v(" "+_vm._s(_vm.campaignListItem.response_count || 0)+" ")]):_c('div',[_vm._v("-")])]),_vm._v(" Responded ")]),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{staticClass:"pl-1",attrs:{"cols":"auto","align":"center"}},[_c('campaign-menu-actions',{attrs:{"campaign-object":_vm.campaignListItem}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }