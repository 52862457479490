<template>
  <v-row>
    <v-col cols="8">
      <cp-input
        v-model="survey.instance.name"
        label="Survey Title"
        messages="Required"
        class="py-6"
        required
        ref="surveyTitle"
      />
      The Description is only visible to survey administrators and is designed
      to help you keep your surveys organized. It will not be visible to your
      survey respondents.
      <cp-textarea
        v-model="survey.instance.description"
        outlined
        label="Description"
        class="py-4"
      />
      <div class="text-right">
        <cp-btn
          color="primary"
          width="200"
          :loading="survey.processing"
          :disabled="!survey.instance.name"
          @click="processSurvey"
        >
          Save
        </cp-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      survey: state => state.survey
    })
  },

  methods: {
    processSurvey() {
      this.$store.dispatch("survey/processSurvey").then(response => {
        if (this.survey.instance.sections.length > 0) {
          this.survey.active_editor = "questions";
        } else {
          this.survey.active_editor = "section";
        }
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(this.$refs.surveyTitle.$refs.cpTextField.focus, 200);
    });
  }
};
</script>
