<template>
  <v-text-field
    class="text-field"
    filled
    type="number"
    v-model="value.numeric_entry"
  />
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  extends: QuestionsShared
};
</script>

<style lang="scss" scoped>
.text-field {
  display: inline-block;
  width: 100%;
  max-width: 100px;
}
</style>
