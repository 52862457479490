<template>
  <v-row>
    <v-col align="center">
      <h4>
        <strong>{{ question.response_count }}</strong>
        {{ "Respondent" | pluralize(question.response_count) }} Represented
      </h4>
      <v-row align="center">
        <v-col align="right">
          <h2>{{ question.details.yes }} said Yes</h2>
        </v-col>
        <v-col cols="auto">
          <cp-doughnut
            :value="percentage"
            color="#0070A8"
            secondaryColor="#DC001B"
            :circumference="1.5"
            :rotation="0.75"
            size="150px"
          />
        </v-col>
        <v-col align="left">
          <h2>{{ question.details.no }} said No</h2>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared,

  computed: {
    percentage() {
      return Math.floor(
        (this.question.details.yes / this.question.response_count) * 100
      );
    },
  },
};
</script>
