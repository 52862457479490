<template>
  <cp-menu
    @menuOpened="$store.dispatch('survey/buildMenuActions', surveyObject.id)"
    bottom
    left
    transition="slide-y-transition"
    :options="survey.survey_menu_actions"
    :min-width="130"
    @menuClosed="survey.survey_menu_actions = []"
  >
  </cp-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    surveyObject: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      survey: state => state.survey
    })
  }
};
</script>
