<template>
  <v-col cols="6" class="text-center">
    <h4 v-if="totalResponseCount > 0">
      {{ highestResponse.value }}% of {{ totalResponseCount }}
      {{ "Responders" | pluralize(totalResponseCount) }} Said
      <strong>{{ highestResponse.text }}</strong>
    </h4>
    <h4 v-else>
      No responses
    </h4>
    <cp-bar
      focus-highest-value
      height="180px"
      bar-color="0,138,9"
      :values="chartValues"
      :labels="Object.values(questionStore.response_value_maps[question.type])"
    />
  </v-col>
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared
};
</script>
