<template>
  <cp-dialog
    :show-model.sync="survey.preview_sender_open"
    header-background
    max-width="640"
    fixed-header-footer
  >
    <template #title>
      Send Survey Preview
    </template>
    <template #body>
      <p>
        Enter up to 5 comma separated email addresses. We'll send them a link to
        preview the survey, where they can view and interact with it, but we
        won't record any responses.
      </p>
      <v-text-field
        v-model="recipients"
        label="Email Addresses"
        placeholder="person1@place.com, person2@place.com"
        prepend-icon="mdi-email"
        :error-messages="errorMessage"
        @keyup.enter="sendPreviews"
      ></v-text-field>
    </template>
    <template #actions="{close}" fixed>
      <v-col cols="auto" class="ml-auto">
        <cp-btn color="primary" text @click="close">
          Cancel
        </cp-btn>
      </v-col>
      <v-col cols="auto">
        <cp-btn
          color="primary"
          @click="sendPreviews"
          :disabled="splitRecipients.length <= 0"
          :loading="survey.sending_previews"
        >
          Send
        </cp-btn>
      </v-col>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SurveySendPreview",

  data() {
    return {
      recipients: "",
      emailsAllValid: true,
      errorMessage: null
    };
  },

  computed: {
    ...mapState({
      survey: state => state.survey
    }),

    ...mapGetters("surveyRenderService", ["getSurveyPreviewToken"]),

    splitRecipients() {
      return [...new Set(this.recipients.replace(/\s/g, "").split(","))];
    },

    payload() {
      return {
        recipients: this.splitRecipients,
        survey: this.survey,
        preview_token: this.getSurveyPreviewToken,
        preview_path:
          process.env.VUE_APP_TASSEL_UI_PATH +
          this.$route.path +
          "/preview?preview_id=" +
          this.getSurveyPreviewToken
      };
    }
  },

  methods: {
    sendPreviews() {
      if (!this.recipients) {
        this.errorMessage = "Required";
        return;
      }

      this.emailsAllValid = true;

      if (this.splitRecipients.length > 5) {
        this.errorMessage = "Only 5 email addresses allowed at a time";
        return;
      }

      this.splitRecipients.forEach(email => {
        if (!this.isValidEmail(email)) {
          this.emailsAllValid = false;
        }
      });

      if (this.emailsAllValid) {
        this.errorMessage = null;
        this.$store.dispatch("survey/sendPreviews", this.payload).then(() => {
          this.recipients = "";
          this.survey.preview_sender_open = false;
          this.$CpEvent.$emit("snackAlert", {
            message: `Preview survey emails sent!`,
            color: "success"
          });
        });
      } else {
        this.errorMessage = "Not all entered emails appear to be valid";
      }
    },

    isValidEmail(email) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(email);
    }
  }
};
</script>
