<template>
  <v-row class="cp-double-likert" justify="center">
    <v-col cols="auto">
      <q-likert
        for-double-likert
        disable-watcher
        :question="{
          ...question,
          type: { text: 'Agreement Rating Scale', slug: 'agreement' }
        }"
        :response="response"
        @update-double-likert="value.agreement = $event"
      />
    </v-col>
    <v-col cols="auto">
      <q-likert
        for-double-likert
        disable-watcher
        :question="{
          ...question,
          type: { text: 'Importance Rating Scale', slug: 'importance' }
        }"
        :response="response"
        @update-double-likert="value.importance = $event"
      />
    </v-col>
  </v-row>
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  extends: QuestionsShared,

  data() {
    return {
      value: {
        agreement: null,
        importance: null
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cp-double-likert {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    align-items: end;
    justify-content: flex-end !important;
  }
}
</style>
