<template>
  <v-row>
    <v-col cols="12" class="mt-5">
      <v-subheader flat class="font-weight-bold black--text pl-0 text-body-1"
        >{{ survey.section_instance.name }}
        <v-icon
          dense
          class="ma-1"
          color="primary"
          @click="survey.active_editor = 'section'"
          >edit</v-icon
        >
        <v-spacer />
        <template>
          <div class="text-center">
            <section-move-or-discard-dialog />
          </div>
        </template>
      </v-subheader>
      <p>
        {{ survey.section_instance.instructions }}
      </p>
      <questions-table />
      <question-add-to-section-dialog />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      survey: state => state.survey
    })
  }
};
</script>

<style scoped>
.v-application .text-center {
  background-color: white !important;
}
.draggable {
  cursor: pointer;
}
</style>
