<template>
  <v-row>
    <v-col align="right">
      <h4>
        <strong>{{ question.response_count }}</strong> {{ "Respondent" | pluralize(question.response_count) }} Represented
      </h4>
      <cp-line
        :height="200"
        :labels="Object.keys(this.question.details)"
        :values="Object.values(this.question.details)"
      />
      <!-- <cp-line :labels="builtData.labels" :values="builtData.values" /> -->
    </v-col>
  </v-row>
</template>

<script>
import QRShared from "./QRShared";

export default {
  extends: QRShared,

  computed: {
    builtData() {
      let keyValues = Object.keys(this.question.details);
      let min = Math.min(...keyValues);
      let max = Math.max(...keyValues);
      let labels = [];
      let values = [];

      for (let i = min; i <= max; i++) {
        labels.push(i);
        values.push(this.question.details[i] || 0);
      }

      return { labels, values };
    }
  }
};
</script>
