<template>
  <v-row>
    <v-col cols="8" class="mt-5">
      Every survey must have at least one section. Use multiple sections to
      divide blocks of questions.
      <cp-input
        label="Section Title"
        messages="Required"
        class="py-6"
        v-model="survey.section_instance.name"
        required
        ref="sectionTitle"
      />
      Instructions will be visible to survey respondents at the beginning of
      each survey section.
      <cp-textarea
        outlined
        label="Section Instructions"
        class="py-4"
        v-model="survey.section_instance.instructions"
      />
      <div class="text-right">
        <cp-btn
          v-if="survey.instance.sections.length > 0"
          color="error"
          text
          @click="cancelSectionCreateEdit"
        >
          Cancel
        </cp-btn>
        <cp-btn
          color="primary"
          width="200"
          :disabled="!survey.section_instance.name"
          @click="processSection"
        >
          Save
        </cp-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      survey: state => state.survey
    }),

    ...mapGetters("survey", ["getLastSection"])
  },

  methods: {
    cancelSectionCreateEdit() {
      this.$store.commit("survey/setSection", this.getLastSection);
      this.survey.active_editor = "questions";
      this.$store.dispatch("survey/fetchQuestionsForSection");
    },

    processSection() {
      this.$store.dispatch("survey/processSection").then(() => {
        if (this.survey.instance.sections.length > 0) {
          this.survey.active_editor = "questions";
        } else {
          this.survey.active_editor = "section";
        }
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(this.$refs.sectionTitle.$refs.cpTextField.focus, 200);
    });
  }
};
</script>
