<template>
  <v-container class="pa-0">
    <div v-if="!campaign.loaded_campaigns" class="text-center pa-6">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      />
    </div>
    <h4 v-else-if="campaign.collection.length <= 0" class="text-center pa-6">
      No campaigns yet!
    </h4>
    <v-row v-else no-gutters>
      <v-col cols="7">
        <v-list class="active-campaign-list pa-0">
          <v-list-item-group
            mandatory
            active-class="selected-campaign"
            v-model="selectedCampaignIndex"
          >
            <v-list-item
              class="active-campaign-list__item"
              v-for="(item, i) in campaign.collection"
              :key="i"
            >
              <v-list-item-icon class="ml-0 mr-3">
                <v-icon>
                  {{ campaign.type_icon_map[item.campaign_type_id] }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{ item.title }}</span>
                <span class="text-caption">{{ item.survey_name }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="5" align="center" class="pa-2">
        <div v-if="selectedCampaignInstance">
          <h4 class="selected-campaign__title text-bold mt-4">
            {{ selectedCampaignInstance.title }}
          </h4>
          <cp-doughnut
            :value="selectedCampaignInstance.response_rate || 0"
            label="Response Rate"
            size="140px"
          />
          <v-row class="my-1">
            <v-col cols="6" align="center">
              <h4 class="text-bold">Start</h4>
              <h4>
                {{ formatAmericanDate(selectedCampaignInstance.start_on) }}
              </h4>
            </v-col>
            <v-col cols="6" align="center">
              <h4 class="text-bold">Close</h4>
              <h4>
                {{
                  formatAmericanDate(selectedCampaignInstance.close_on) || "-"
                }}
              </h4>
            </v-col>
          </v-row>
          <v-divider />
          <h4 class="mt-4 mb-2">
            <v-icon class="pb-0">mdi-account-circle</v-icon>
            {{ selectedCampaignInstance.creator_name }}
          </h4>
          <v-btn
            text
            color="primary"
            small
            @click="
              $store.dispatch('campaign/activateViewer', {
                campaign_id: selectedCampaignInstance.id,
                survey_id: selectedCampaignInstance.survey_id,
              })
            "
          >
            More Details
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <cp-dialog
      :show-model.sync="campaign.viewer_open"
      max-width="1160px"
      scrollable
      header-background
      @modalClosed="$store.commit('campaign/resetCampaignInstance')"
    >
      <template #title>
        Campaign Details
      </template>
      <template #body>
        <campaign-detail />
      </template>
    </cp-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import helpers from "../../mixins/helpers";

export default {
  data() {
    return {
      selectedCampaignIndex: 0,
    };
  },

  mixins: [helpers],

  computed: {
    ...mapState({
      campaign: state => state.campaign,
    }),

    selectedCampaignInstance() {
      if (this.campaign.collection.length <= 0) {
        return {};
      }

      return this.campaign.collection[this.selectedCampaignIndex];
    },
  },

  mounted() {
    this.$store.dispatch("campaign/fetchActiveAndUpcoming");
  },

  beforeDestroy() {
    this.selectedCampaignIndex = 0;
  },
};
</script>

<style lang="scss" scoped>
.active-campaign-list {
  overflow-y: auto;
  height: 400px;
  max-height: 90vh;
  border-right: 1px solid #e6e6e6;
}

.active-campaign-list__item {
  border-bottom: 1px solid #e6e6e6;

  &:last-child {
    border-bottom: 0;
  }
}

.selected-campaign {
  background-color: #0070a8;
  color: #ffffff;
}

.selected-campaign__title {
  min-height: 50px;
}
</style>
