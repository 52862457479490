<template>
  <cp-dialog
    :show-model.sync="campaign.confirming_close"
    class="mx-auto"
    headerBackground
    width="600"
  >
    <template #title>
      Close Campaign Early
    </template>

    <template #body>
      Are you sure you want to close this campaign early? Users will no longer
      be able to access surveys, but any responses they have provided up to this
      point are still recorded.
    </template>

    <template #actions="{ close }">
      <v-col cols="auto" class="ml-auto">
        <cp-btn text @click="close()">
          Cancel
        </cp-btn>
        <cp-btn
          color="primary"
          rounded
          @click="closeCampaign"
          :loading="campaign.closing"
        >
          Yes, Close Campaign
        </cp-btn>
      </v-col>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      campaign: state => state.campaign
    })
  },

  methods: {
    closeCampaign() {
      let campaign_to_close = this.campaign.instance.id;

      this.$store
        .dispatch("campaign/updateCampaign", {
          id: this.campaign.instance.id,
          close_on: moment().toDate()
        })
        .then(() => {
          this.campaign.collection.find(
            x => x.id == campaign_to_close
          ).status.slug = "closing";
          this.campaign.confirming_close = false;
          this.$store.dispatch("campaign/fetchCampaigns");
        });
    }
  }
};
</script>
