<template>
  <div class="cp-likert">
    <h4 class="tiny-label" v-html="labels.minText" />
    <h4 class="tiny-label tiny-label--right" v-html="labels.maxText" />
    <v-btn-toggle
      v-model="value[modelNameToUse]"
      @change="$emit('update-double-likert', $event)"
    >
      <v-btn v-for="(n, i) in 5" :key="i" :value="n" active-class="active">
        <v-icon>
          mdi-radiobox-{{ value[modelNameToUse] == n ? "marked" : "blank" }}
        </v-icon>
      </v-btn>
    </v-btn-toggle>
    <h4 class="mt-2">{{ question.type.text | uppercase }}</h4>
  </div>
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  props: {
    disableWatcher: { type: Boolean, default: false },
    forDoubleLikert: { type: Boolean, default: false }
  },

  extends: QuestionsShared,

  computed: {
    labels() {
      switch (this.question.type.slug) {
        case "agreement":
          return {
            minText: "Totally<br />Disagree",
            maxText: "Totally<br />Agree"
          };
        case "importance":
          return {
            minText: "Very<br />Unimportant",
            maxText: "Very<br />Important"
          };
        case "likelihood":
          return {
            minText: "Very<br />Unlikely",
            maxText: "Very<br />Likely"
          };
        default:
          return {
            minText: "A Little",
            maxText: "A Lot"
          };
      }
    },

    modelNameToUse() {
      return this.forDoubleLikert ? this.question.type.slug : "likert";
    }
  },

  methods: {
    init() {
      if (this.response != null) {
        this.value = this.response.value;
      }
      this.responsesSet = true;

      if (!this.disableWatcher) {
        this.$watch(
          "value",
          function(newValue, oldValue) {
            this.responseWatcher();
          },
          { deep: true }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cp-likert {
  text-align: left;
  display: inline-block;
  position: relative;

  .tiny-label {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 4px;

    &.tiny-label--right {
      text-align: right;
      position: absolute;
      right: 4px;
      top: 0;
    }
  }

  .v-btn {
    width: 64px;

    &.active {
      background-color: #009cde;

      .v-icon {
        color: #ffffff !important;
      }
    }
  }
}
</style>
