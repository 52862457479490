<template>
  <div>
    <v-subheader class="primary--text justify-center font-weight-bold">
      <cp-btn
        class="text-body-1 pt-5"
        plain
        text
        tile
        @click="
          $store.dispatch('question/activateCreateEditDialog');
          $store.dispatch('question/fetchQuestions');
          $store.dispatch('survey/fetchSurveyQuestionIds');
        "
      >
        <v-icon color="primary">mdi-plus</v-icon> Add Question
      </cp-btn>
    </v-subheader>
    <cp-dialog
      max-width="1160"
      scrollable
      :show-model.sync="question.editor_open"
      header-background
      @modalClosed="
        $store.dispatch('question/deactivateCreateEditDialog');
        tab = 0;
        question.search = '';
      "
    >
      <template #title>
        {{ question.editing ? "Edit" : "Add" }} Question
      </template>

      <template #body>
        <v-row v-if="!question.editing && !question.duplicating">
          <v-spacer />
          <v-col cols="4" xs="12" sm="12" md="8">
            <v-tabs
              v-model="tab"
              height="48"
              centered
              hide-slider
              color="#6B6B70"
              background-color="grey lighten-2"
              grow
            >
              <v-tab
                height="32"
                active-class="active"
                class="pa-4 mx-1 my-auto text-no-wrap"
              >
                Select from Existing
              </v-tab>

              <v-tab
                height="32"
                active-class="active"
                class="pa-4 mx-1 my-auto"
              >
                Create new
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row class="mt-2">
              <v-spacer />
              <v-col cols="6" align="center">
                <cp-search
                  label="Search for Questions"
                  v-model="question.search"
                  ref="searchFilter"
                ></cp-search>
              </v-col>
              <v-col cols="4" align="center">
                <cp-select
                  v-model="filters.type"
                  :items="question.question_types"
                  label="Filter By Type"
                  placeholder=""
                  return-object
                  clearable
                />
              </v-col>
              <v-spacer />
            </v-row>
            <cp-table
              v-if="question.collection.length > 0"
              class="questions-list"
              dense
              loadingText="No Questions Found"
              :search="question.search"
              :items="question.collection"
              fixed-header
              :items-per-page="20"
              :headers="[
                {
                  text: 'Question',
                  value: 'body',
                  sortable: false
                },
                {
                  text: 'Type',
                  value: 'type',
                  sortable: false,
                  filter: (value, search, item) => {
                    return filters.type == null
                      ? true
                      : item.type.text == filters.type.text;
                  }
                },
                {}
              ]"
            >
              <template #item-columns="{ item }">
                <td class="pl-9" style="position: relative;">
                  <v-icon
                    v-if="item.created_by == 'Swift Bunny'"
                    class="sb-owned-indicator"
                  >
                    $vuetify.icons.SwiftBunnyLogo
                  </v-icon>
                  <span class="table-link-text">{{ item.body }}</span>
                </td>
                <td>{{ item.type.text }}</td>
                <td class="text-right">
                  <v-btn
                    color="primary"
                    :disabled="survey.instance.question_ids.includes(item.id)"
                    @click="addExistingQuestion(item.id)"
                    icon
                  >
                    <v-icon>
                      mdi-{{
                        survey.instance.question_ids.includes(item.id)
                          ? "check"
                          : "plus"
                      }}
                    </v-icon>
                  </v-btn>
                </td>
              </template>
            </cp-table>
            <div v-else class="text-center py-6">
              <v-progress-circular
                :size="100"
                :width="8"
                color="primary"
                indeterminate
              />
            </div>
          </v-tab-item>

          <v-tab-item class="ma-4">
            <question-create-edit-form ref="questionCreateEditForm" />
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template
        #actions="{ close }"
        v-if="question.editing || question.duplicating"
      >
        <v-col cols="auto" class="ml-auto">
          <cp-btn color="primary" text @click="close">
            Cancel
          </cp-btn>
          <cp-btn
            color="primary"
            rounded
            :disabled="!passesValidation"
            @click="$store.dispatch('question/processQuestion')"
          >
            Save
          </cp-btn>
        </v-col>
      </template>
      <template #actions v-else-if="tab == 1">
        <v-col cols="auto" class="ml-auto">
          <cp-btn
            color="primary"
            text
            :disabled="!passesValidation"
            @click="
              addAnother = true;
              $store.dispatch('question/processQuestion');
            "
          >
            Add Another
          </cp-btn>
          <cp-btn
            color="primary"
            rounded
            :disabled="!passesValidation"
            @click="$store.dispatch('question/processQuestion')"
          >
            Add and Close
          </cp-btn>
        </v-col>
      </template>
      <template #actions="{ close }" v-else>
        <v-col cols="auto" class="ml-auto">
          <cp-btn color="primary" text @click="close">
            Cancel
          </cp-btn>
        </v-col>
      </template>
    </cp-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      addAnother: false,
      filters: {
        type: null
      },
      tab: null
    };
  },

  mounted() {
    this.$CpEvent.$on("questionCreated", this.questionCreatedListener);
    this.$CpEvent.$on("questionSaved", this.questionSavedListener);
    this.$CpEvent.$on("questionRemoved", this.questionSavedListener);
  },

  beforeDestroy() {
    this.$store.commit("question/resetQuestionInstance");
    this.$store.commit("question/resetQuestionCollection");
    this.$CpEvent.$off("questionCreated", this.questionCreatedListener);
    this.$CpEvent.$off("questionSaved", this.questionSavedListener);
    this.$CpEvent.$off("questionRemoved", this.questionSavedListener);
  },

  computed: {
    ...mapState({
      question: state => state.question,
      survey: state => state.survey
    }),

    ...mapGetters("question", ["passesValidation"])
  },

  methods: {
    addExistingQuestion(question_id) {
      this.$store
        .dispatch("survey/addQuestionToSection", question_id)
        .then(() => {
          this.$store.dispatch("question/deactivateCreateEditDialog");
        });
    },

    questionCreatedListener(question) {
      this.$store
        .dispatch("survey/addQuestionToSection", question.data.id)
        .then(() => {
          this.$store.commit("question/resetQuestionInstance");

          if (!this.addAnother) {
            this.$store.dispatch("question/deactivateCreateEditDialog");
          }

          this.addAnother = false;
        });
    },

    questionSavedListener() {
      this.$store.dispatch("question/deactivateCreateEditDialog");
      this.$store.dispatch("survey/deactivateRemoveQuestionDialog");
      this.$store.dispatch("survey/deactivateTransferQuestionDialog");
      this.$store.dispatch("survey/fetchQuestionsForSection");
    }
  },

  watch: {
    "question.instance.id"(newVal, oldVal) {
      if (newVal != null) {
        this.tab = 1;
      }
    },

    "question.duplicating"(newVal, oldVal) {
      if (newVal) {
        this.tab = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  background-color: rgba(255, 255, 255, 1) !important;
  height: 38px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgb(179, 178, 178);
  text-decoration: none;
}
.answerHeader {
  color: #6b6b70;
  padding-top: 0;
}
.option-row {
  height: 50px;
}

.questions-list::v-deep .v-data-table__wrapper {
  max-height: 300px;
}
</style>
