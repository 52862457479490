<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" class="pt-0">
        <cp-card dense flush-body collapse-actions>
          <template #title>Questions</template>

          <template #actions>
            <v-text-field
              class="inq-list-action"
              v-model="question.search"
              placeholder="Search"
              hide-details
              dense
              append-icon="mdi-magnify"
            />
            <v-select
              class="inq-list-action"
              v-model="question.filters.createdBy"
              placeholder="Creator"
              :items="question.collection.map(x => x.created_by)"
              hide-details
              clearable
              dense
            />
            <v-select
              class="inq-list-action"
              v-model="question.filters.questionType"
              placeholder="Type"
              :items="
                question.question_types.map(x => ({
                  text: x.text,
                  value: x.slug
                }))
              "
              hide-details
              clearable
              dense
            />
            <v-btn
              rounded
              color="primary"
              @click="$store.dispatch('question/activateCreateEditDialog')"
            >
              <v-icon>mdi-plus</v-icon>
              New Question
            </v-btn>
          </template>

          <v-data-table
            :loading="question.loading_all_questions"
            sort-by="updated_at"
            :sort-desc="true"
            :headers="[
              { text: 'Body', value: 'body', class: 'pl-8' },
              {
                text: 'Creator',
                value: 'created_by',
                filter: (value, search, item) => {
                  return question.filters.createdBy == null
                    ? true
                    : item.created_by == question.filters.createdBy;
                }
              },
              {
                text: 'Type',
                value: 'type.text',
                filter: (value, search, item) => {
                  return question.filters.questionType == null
                    ? true
                    : item.type.slug == question.filters.questionType;
                }
              },
              {
                text: 'Status',
                value: 'status_slug'
              },
              { text: 'Modified', value: 'updated_at' },
              { text: 'Actions', value: 'actions', sortable: false }
            ]"
            :items="question.collection"
            :search="question.search"
          >
            <template #item="{item}">
              <tr>
                <td
                  width="auto"
                  class="pl-8 text-truncate"
                  style="position: relative;"
                >
                  <v-icon
                    v-if="item.created_by == 'Swift Bunny'"
                    class="sb-owned-indicator"
                  >
                    $vuetify.icons.SwiftBunnyLogo
                  </v-icon>
                  <span class="table-link-text">{{ item.body }}</span>
                </td>
                <td width="10%">
                  {{ item.created_by }}
                </td>
                <td width="10%">{{ item.type.text }}</td>
                <td width="10%">
                  <cp-status-chip label dark :status="item.status_slug">
                    {{ item.status_slug }}
                  </cp-status-chip>
                </td>
                <td width="10%">{{ formatAmericanDate(item.updated_at) }}</td>
                <td width="5%">
                  <question-menu-actions :question-object="item" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </cp-card>
      </v-col>
    </v-row>

    <cp-dialog
      header-background
      :show-model.sync="question.editor_open"
      max-width="1160"
      scrollable
      @modalClosed="$store.dispatch('question/deactivateCreateEditDialog')"
    >
      <template #title>
        {{ question.editing ? "Edit" : "Create" }} Question
      </template>
      <template #body>
        <question-create-edit-form ref="questionCreateEditForm" />
      </template>
      <template #actions>
        <v-spacer />
        <v-btn
          text
          @click="$store.dispatch('question/deactivateCreateEditDialog')"
          color="error"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!passesValidation"
          @click="$store.dispatch('question/processQuestion')"
          color="primary"
        >
          {{ question.editing ? "Save" : "Create" }}
        </v-btn>
      </template>
    </cp-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import helpers from "../mixins/helpers.js";

export default {
  name: "Questions",

  mixins: [helpers],

  methods: {
    questionChangedCallback() {
      this.$store.dispatch("question/deactivateCreateEditDialog");
      this.$store.dispatch("question/fetchQuestions", "index");
      this.$CpEvent.$emit("snackAlert", {
        message: "Question saved!"
      });
    }
  },

  computed: {
    ...mapState({
      question: state => state.question
    }),

    ...mapGetters("question", ["passesValidation"])
  },

  mounted() {
    this.$store.dispatch("question/fetchQuestionTypes");
    this.$store.dispatch("question/fetchQuestions", "index");
    this.$CpEvent.$on("questionCreated", this.questionChangedCallback);
    this.$CpEvent.$on("questionSaved", this.questionChangedCallback);
  },

  beforeDestroy() {
    this.$store.commit("question/resetQuestionInstance");
    this.$store.commit("question/resetQuestionCollection");
    this.$store.commit("campaign/resetCampaignInstance");
    this.$store.commit("campaign/resetCampaignCollection");
    this.$CpEvent.$off("questionCreated", this.questionChangedCallback);
    this.$CpEvent.$off("questionSaved", this.questionChangedCallback);
  }
};
</script>

<style lang="scss" scoped>
.inq-list-action {
  width: 180px;
  margin-right: 12px;

  &.inq-list-action--wide {
    width: 290px;
  }
}

.table-link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.table-link-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
