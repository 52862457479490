<template>
  <cp-doughnut :value="value" v-bind="$attrs">
    <template #inside>
      <h4 class="label-value">{{ value || 0 }}%</h4>
      <h4 class="label-text">Response Rate</h4>
    </template>
  </cp-doughnut>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 }
  }
};
</script>

<style scoped>
.label-value {
  font-size: 23px;
}

.label-text {
  font-size: 11px;
}
</style>
