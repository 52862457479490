import { Api } from "@cp/lib";
import { v4 as uuidv4 } from "uuid";
const bApi = new Api(process.env.VUE_APP_TASSEL_API_PATH);

const state = () => ({
  instance: {
    id: null,
    body: null,
    status_slug: "active",
    type: {
      text: null,
      value: null,
      slug: null,
    },
    options: [],
    errors: {},
  },
  search: "",
  filters: {
    createdBy: null,
    questionType: null,
    status: [
      { text: "Draft", value: "draft" },
      { text: "Active", value: "active" },
    ],
  },
  collection: [],
  question_types: [],
  question_menu_actions: [],
  editor_open: false,
  editing: false,
  processing: false,
  loading_all_questions: false,
  question_loaded: false,
  duplicating: false,
  question_type_component_map: {
    "agreement-importance": "double-likert",
    agreement: "likert",
    likelihood: "likert",
    importance: "likert",
    "multiple-select": "multiple-select",
    "multiple-choice": "multiple-choice",
    "numeric-entry": "numeric-entry",
    "text-entry": "text-entry",
    "yes-no": "yes-no",
  },
  question_type_slug_name_map: {
    "agreement-importance": "Agreement/Importance Rating Scale",
    agreement: "Agreement Rating Scale",
    likelihood: "Likelihood Rating Scale",
    importance: "Importance Rating Scale",
    "multiple-select": "Multiple Select",
    "multiple-choice": "Multiple Choice",
    "numeric-entry": "Numeric",
    "text-entry": "Text",
    "yes-no": "Yes/No",
  },
  response_value_maps: {
    agreement: {
      0: "Totally Disagree",
      1: "Disagree",
      2: "Neutral",
      3: "Agree",
      4: "Totally Agree",
    },
    importance: {
      0: "Very Unimportant",
      1: "Unimportant",
      2: "Neutral",
      3: "Important",
      4: "Very Important",
    },
    likelihood: {
      0: "Very Unlikely",
      1: "Unlikely",
      2: "Neutral",
      3: "Likely",
      4: "Very Likely",
    },
  },
});

const getters = {
  questionFormattedForApi(state) {
    let q = state.instance;

    return {
      question: {
        body: q.body,
        question_type_id: q.type.value,
        _destroy: q._destroy,
        status_slug: q.status_slug,
        options_attributes: ["multiple-choice", "multiple-select"].includes(
          q.type.slug
        )
          ? q.options
          : [],
      },
    };
  },

  passesValidation(state) {
    let q = state.instance;
    let passed = true;

    if (
      q.body == null ||
      !q.body.trim() ||
      q.type.text == null ||
      !q.type.text.trim() ||
      q.type.value == null
    ) {
      passed = false;
    }

    if (["multiple-choice", "multiple-select"].includes(q.type.slug)) {
      if (
        q.options.filter(x => x._destroy == false && x.body == "").length > 0 ||
        q.options.filter(x => x._destroy == false).length <= 0
      ) {
        passed = false;
      }
    }

    return passed;
  },

  shouldShowPreview(state) {
    return !["multiple-choice", "multiple-select"].includes(
      state.instance.type.slug
    );
  },

  canEdit(state, getters, rootState) {
    return state.instance.created_by != "Swift Bunny";
  },
};

const actions = {
  activateCreateEditDialog(
    { state, commit, dispatch, rootGetters },
    question_id = state.instance.id
  ) {
    if (rootGetters["auth/isImpersonating"]) {
      this._vm.$CpEvent.$emit("snackAlert", {
        message: "Cannot create or edit surveys while impersonating.",
        color: "error",
      });
      return;
    }

    state.editor_open = true;
    state.editing = question_id != null;

    dispatch("fetchQuestionTypes").then(() => {
      if (state.editing) {
        dispatch("fetchQuestion", question_id);
      }
    });
  },

  deactivateCreateEditDialog({ state, commit }) {
    state.editor_open = false;
    state.editing = false;
    state.duplicating = false;
    commit("resetQuestionInstance");
  },

  fetchQuestions({ state, commit }) {
    state.loading_all_questions = true;

    bApi
      .authorize()
      .get("/en/v1/questions", { page: { size: "all" } })
      .then(({ data }) => {
        commit("setQuestions", data);
      })
      .finally(() => {
        state.loading_all_questions = false;
      });
  },

  fetchQuestion({ state, commit }, question_id) {
    return bApi
      .authorize()
      .get(`/en/v1/questions/${question_id}`)
      .then(({ data, included }) => {
        let options = included.filter(x => x.type == "option").length
          ? included.map(x => ({
              id: x.id,
              body: x.attributes.body,
              position: x.attributes.position,
              pointer: uuidv4(),
              _destroy: false,
            }))
          : [];

        let question = {
          ...data.attributes,
          type: {
            text: data.attributes.type,
            value: parseInt(data.attributes.question_type_id),
            slug: data.attributes.question_type_slug,
          },
          id: data.id,
          options: options,
        };

        commit("setQuestion", Object.assign(state.instance, question));
        this._vm.$CpEvent.$emit("questionFetched", question);
      });
  },

  fetchQuestionTypes({ commit }) {
    return bApi
      .authorize()
      .get(`/en/v1/question-types`)
      .then(({ data }) => {
        commit("setQuestionTypes", data);
      });
  },

  processQuestion({ state, dispatch }) {
    state.processing = true;
    state.instance.errors = {};
    let action = state.instance.id ? "updateQuestion" : "createQuestion";

    dispatch(action)
      .then(response => {
        return response;
      })
      .catch(({ response }) => {
        state.instance.errors = response.data.errors;
      })
      .finally(() => {
        state.processing = false;
      });
  },

  createQuestion({ state, getters }) {
    return bApi
      .authorize()
      .post(`/en/v1/questions`, getters.questionFormattedForApi)
      .then(response => {
        this._vm.$CpEvent.$emit("questionCreated", response);
        return response;
      });
  },

  updateQuestion({ state, getters, dispatch, rootState }) {
    return bApi
      .authorize()
      .put(
        `/en/v1/questions/${state.instance.id}`,
        getters.questionFormattedForApi
      )
      .then(response => {
        if (response.surveys) {
          let newSectionId = response.surveys[0].section_ids[0];

          dispatch("survey/fetchSurvey", response.surveys[0].survey_id, {
            root: true,
          }).then(() => {
            dispatch("survey/fetchSection", newSectionId, { root: true }).then(
              () => {
                dispatch("survey/fetchQuestionsForSection", newSectionId, {
                  root: true,
                });
              }
            );
          });
        }
        this._vm.$CpEvent.$emit("questionSaved", response);
        return response;
      });
  },

  duplicateQuestion({ state, dispatch }, question) {
    Object.assign(state.instance, {
      ...question,
      id: null,
      options: question.options
        ? question.options.map(x => ({
            ...x,
            _destroy: false,
            id: null,
          }))
        : [],
    });

    state.duplicating = true;
    dispatch("activateCreateEditDialog");
  },

  buildMenuActions({ state, rootState }, { question_id }) {
    return bApi
      .authorize()
      .get(`/en/v1/questions/${question_id}`)
      .then(({ data, included }) => {
        let options = included.filter(x => x.type == "option").length
          ? included.map(x => ({
              id: x.id,
              body: x.attributes.body,
              position: x.attributes.position,
              pointer: uuidv4(),
              _destroy: false,
            }))
          : [];

        let question = {
          ...data.attributes,
          type: {
            text: data.attributes.type,
            value: parseInt(data.attributes.question_type_id),
            slug: data.attributes.question_type_slug,
          },
          id: data.id,
          options: options,
        };

        state.question_menu_actions = [];

        if (
          question.created_by != null &&
          question.created_by != "Swift Bunny"
        ) {
          state.question_menu_actions.push({
            title: "Edit",
            type: "storeAction",
            action: "question/activateCreateEditDialog",
            data: question_id,
          });
        }

        state.question_menu_actions.push({
          title: "Duplicate Question",
          type: "storeAction",
          action: "question/duplicateQuestion",
          data: question,
        });

        if (rootState.survey.editor_open) {
          state.question_menu_actions.push(
            ...[
              {
                title: "Transfer Section",
                type: "storeAction",
                action: "survey/activateTransferQuestionDialog",
                data: question,
              },
              {
                title: "Remove Question",
                type: "storeAction",
                action: "survey/activateRemoveQuestionDialog",
                data: question,
              },
            ]
          );
        }
      });
  },
};

const mutations = {
  setQuestions(state, questions) {
    state.collection = questions;
  },

  setQuestion(state, question) {
    state.instance = question;
  },

  setQuestionTypes(state, types) {
    state.question_types = types.map(x => ({
      text: x.attributes.name,
      value: parseInt(x.id),
      slug: x.attributes.slug,
    }));
  },

  setEditQuestionDialog(state, isOpen = false) {
    state.duplicating = false;
    state.duplicate_or_edit_question_open = isOpen;
  },

  setDuplicateQuestionDialog(state, isOpen = false) {
    state.duplicating = true;
    state.duplicate_or_edit_question_open = isOpen;
  },

  setTransferQuestionDialog(state, isOpen = false) {
    state.transfer_question_open = isOpen;
  },

  setRemoveQuestionDialog(state, isOpen = false) {
    state.remove_question_open = isOpen;
  },

  resetQuestionInstance(state) {
    state.instance = {
      id: null,
      body: null,
      status_slug: "active",
      type: {
        text: null,
        value: null,
      },
      options: [],
      errors: {},
    };
  },

  resetQuestionCollection(state) {
    state.collection = [];
    state.search = "";
  },

  setQuestionMenuActions(state, actions) {
    state.question_menu_actions = actions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
