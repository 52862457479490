import Vue from "vue";
import App from "./App.vue";
import { CarrotPatchV2, router, store, vuetify } from "@cp/install";
import "./styles/survey-bunny-ui.scss";
import PortalVue from "portal-vue";
import VuePluralize from "vue-pluralize";
import VueStringFilter from "vue-string-filter";

Vue.config.productionTip = false;

Vue.use(CarrotPatchV2);
Vue.use(PortalVue);
Vue.use(VuePluralize);
Vue.use(VueStringFilter);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount("#app");
