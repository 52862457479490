import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mt-4"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('cp-card',{attrs:{"dense":"","flush-body":"","collapse-actions":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Questions")]},proxy:true},{key:"actions",fn:function(){return [_c(VTextField,{staticClass:"inq-list-action",attrs:{"placeholder":"Search","hide-details":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.question.search),callback:function ($$v) {_vm.$set(_vm.question, "search", $$v)},expression:"question.search"}}),_c(VSelect,{staticClass:"inq-list-action",attrs:{"placeholder":"Creator","items":_vm.question.collection.map(function (x) { return x.created_by; }),"hide-details":"","clearable":"","dense":""},model:{value:(_vm.question.filters.createdBy),callback:function ($$v) {_vm.$set(_vm.question.filters, "createdBy", $$v)},expression:"question.filters.createdBy"}}),_c(VSelect,{staticClass:"inq-list-action",attrs:{"placeholder":"Type","items":_vm.question.question_types.map(function (x) { return ({
                text: x.text,
                value: x.slug
              }); }),"hide-details":"","clearable":"","dense":""},model:{value:(_vm.question.filters.questionType),callback:function ($$v) {_vm.$set(_vm.question.filters, "questionType", $$v)},expression:"question.filters.questionType"}}),_c(VBtn,{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('question/activateCreateEditDialog')}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" New Question ")],1)]},proxy:true}])},[_c(VDataTable,{attrs:{"loading":_vm.question.loading_all_questions,"sort-by":"updated_at","sort-desc":true,"headers":[
            { text: 'Body', value: 'body', class: 'pl-8' },
            {
              text: 'Creator',
              value: 'created_by',
              filter: function (value, search, item) {
                return _vm.question.filters.createdBy == null
                  ? true
                  : item.created_by == _vm.question.filters.createdBy;
              }
            },
            {
              text: 'Type',
              value: 'type.text',
              filter: function (value, search, item) {
                return _vm.question.filters.questionType == null
                  ? true
                  : item.type.slug == _vm.question.filters.questionType;
              }
            },
            {
              text: 'Status',
              value: 'status_slug'
            },
            { text: 'Modified', value: 'updated_at' },
            { text: 'Actions', value: 'actions', sortable: false }
          ],"items":_vm.question.collection,"search":_vm.question.search},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"pl-8 text-truncate",staticStyle:{"position":"relative"},attrs:{"width":"auto"}},[(item.created_by == 'Swift Bunny')?_c(VIcon,{staticClass:"sb-owned-indicator"},[_vm._v(" $vuetify.icons.SwiftBunnyLogo ")]):_vm._e(),_c('span',{staticClass:"table-link-text"},[_vm._v(_vm._s(item.body))])],1),_c('td',{attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(item.created_by)+" ")]),_c('td',{attrs:{"width":"10%"}},[_vm._v(_vm._s(item.type.text))]),_c('td',{attrs:{"width":"10%"}},[_c('cp-status-chip',{attrs:{"label":"","dark":"","status":item.status_slug}},[_vm._v(" "+_vm._s(item.status_slug)+" ")])],1),_c('td',{attrs:{"width":"10%"}},[_vm._v(_vm._s(_vm.formatAmericanDate(item.updated_at)))]),_c('td',{attrs:{"width":"5%"}},[_c('question-menu-actions',{attrs:{"question-object":item}})],1)])]}}])})],1)],1)],1),_c('cp-dialog',{attrs:{"header-background":"","show-model":_vm.question.editor_open,"max-width":"1160","scrollable":""},on:{"update:showModel":function($event){return _vm.$set(_vm.question, "editor_open", $event)},"update:show-model":function($event){return _vm.$set(_vm.question, "editor_open", $event)},"modalClosed":function($event){return _vm.$store.dispatch('question/deactivateCreateEditDialog')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.question.editing ? "Edit" : "Create")+" Question ")]},proxy:true},{key:"body",fn:function(){return [_c('question-create-edit-form',{ref:"questionCreateEditForm"})]},proxy:true},{key:"actions",fn:function(){return [_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.$store.dispatch('question/deactivateCreateEditDialog')}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"disabled":!_vm.passesValidation,"color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('question/processQuestion')}}},[_vm._v(" "+_vm._s(_vm.question.editing ? "Save" : "Create")+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }