<template>
  <v-row no-gutters class="survey-container">
    <v-col class="survey-header">
      <h1 class="survey-title">
        {{ survey.instance.name }}
      </h1>
    </v-col>

    <v-col
      class="survey-body text-center"
      :class="{ 'is-preview': service.is_preview }"
    >
      <div class="survey-width-limit">
        <template v-if="shouldShowCompleted">
          <img src="@/assets/happy_icon.svg" class="py-5" />
          <h4 class="text-h4 font-weight-bold mb-5">
            Thanks for completing this survey!
          </h4>
          <p>
            Your responses have been recorded, and we appreciate your feedback.
          </p>
        </template>
        <template v-else>
          <img src="@/assets/sorry_icon.svg" class="py-5" />
          <h4 class="text-h4 font-weight-bold mb-5">
            Sorry, but this survey is closed.
          </h4>
          <p>
            Any responses you've already provided, though, were still saved.
          </p>
        </template>
      </div>
    </v-col>
    <survey-footer />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      service: state => state.surveyRenderService,
      survey: state => state.survey
    }),

    ...mapGetters("surveyRenderService", [
      "shouldShowCompleted",
      "shouldShowClosed"
    ])
  }
};
</script>

<style lang="scss" scoped>
.survey-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #f1f1f1;

  .survey-width-limit {
    max-width: 1200px;
    margin: 0 auto;
  }

  .survey-header {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #aaaaaa;
  }

  .survey-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    padding-top: 1rem;
    color: #007dba;
  }

  .survey-body {
    background-color: #f1f1f1;
    padding: 1rem;
    padding-top: 110px;
    min-height: calc(100vh - 124px);

    &.is-preview {
      padding-top: 156px;
    }

    * {
      color: #444444;
      fill: #444444;
    }
  }
}
</style>
