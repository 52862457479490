<template>
  <cp-card dense flush-body>
    <template #title>
      <span class="text-subtitle-1">
        For Survey: {{ survey.instance.name }}
      </span>
    </template>

    <template #actions>
      <span class="text-subtitle-1">
        {{ typeText[campaign.instance.campaign_type_id] }}
      </span>
    </template>

    <v-stepper v-model="campaign.current_step" class="elevation-0" vertical>
      <campaign-create-edit-title />
      <campaign-create-edit-recipients />
      <campaign-create-edit-schedule />
    </v-stepper>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";
import CampaignCreateEditTitle from "./CampaignCreateEditTitle";
import CampaignCreateEditRecipients from "./CampaignCreateEditRecipients";
import CampaignCreateEditSchedule from "./CampaignCreateEditSchedule";

export default {
  components: {
    CampaignCreateEditTitle,
    CampaignCreateEditRecipients,
    CampaignCreateEditSchedule
  },

  data() {
    return {
      typeText: {
        1: "Anonymous Weblink Campaign",
        2: "Confidential Email Campaign",
        3: "Non-Confidential Email Campaign"
      }
    };
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign,
      survey: state => state.survey
    })
  }
};
</script>
