<script>
import { mapState } from "vuex";

export default {
  props: {
    question: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      questionStore: state => state.question
    }),

    questionTypeToUse() {
      if (
        Object.keys(this.question.details).length == 1 &&
        ["agreement", "importance", "likelihood"].includes(this.question.type)
      ) {
        return "likert";
      }

      return this.question.type;
    },

    totalResponseCount() {
      return Object.values(
        this.question.details[this.questionTypeToUse]
      ).reduce((a, b) => a + b);
    },

    chartValues() {
      return Object.values(
        this.question.details[this.questionTypeToUse]
      ).map(x => this.getPercentage(x, this.totalResponseCount));
    },

    highestResponse() {
      let max = Math.max(...this.chartValues);

      return {
        value: max,
        text: this.questionStore.response_value_maps[this.question.type][
          this.chartValues.indexOf(max)
        ]
      };
    }
  },

  methods: {
    getPercentage(responseCount, totalCount) {
      return Math.round((responseCount / totalCount) * 100);
    }
  }
};
</script>
