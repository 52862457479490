<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" lg="6" class="pt-0">
        <cp-card dense wrap-title-width="65%" class="fill-height">
          <template #title>
            {{ survey.instance.name }}
            <span v-if="survey.instance.status_slug === 'draft'">
              (DRAFT)
            </span>
          </template>

          <template #actions>
            <cp-btn
              v-if="canPublish"
              height="30"
              class="text-body-1"
              color="success"
              @click="survey.publish_confirmation_open = true"
            >
              <v-icon class="mr-1">mdi-check-circle</v-icon>
              Publish
            </cp-btn>
            <survey-menu-actions :survey-object="survey.instance" />
          </template>
          <v-row>
            <v-col cols="8" class="pa-4">
              <h6 class="caption">
                {{ survey.instance.created_by }} created
                {{ formatAmericanDate(survey.instance.created_at) }}
                | {{ survey.instance.updated_by }} modified
                {{ formatAmericanDate(survey.instance.updated_at) }}
              </h6>
              <p>
                {{
                  survey.instance.description ||
                    "This survey has not been given a description."
                }}
              </p>
            </v-col>
            <v-divider vertical />
            <v-col cols="4" align="center" class="pa-4">
              <span class="text-h3 black--text text-center">
                {{ survey.instance.question_ids.length }}
              </span>
              <h4>
                {{
                  "Question" | pluralize(survey.instance.question_ids.length)
                }}
              </h4>
              <v-btn
                v-if="canEdit"
                class="mt-3"
                rounded
                dense
                color="primary"
                @click="
                  $store.dispatch(
                    'survey/activateCreateEditDialog',
                    survey.instance.id
                  )
                "
              >
                <v-icon class="mr-1">mdi-pencil</v-icon> Edit
              </v-btn>
            </v-col>
          </v-row>
        </cp-card>
      </v-col>
      <v-col cols="12" lg="6" class="pt-0">
        <cp-card dense class="fill-height">
          <template #title>Activity</template>

          <v-row align="center" class="pa-4">
            <v-col cols="4">
              <response-rate-doughnut
                :value="survey.instance.all_response_rate"
              />
            </v-col>
            <v-col cols="4">
              <div class="pb-2">Email Campaigns</div>
              <cp-chip-combo
                stacked
                :items="[
                  {
                    leftValue: 'Sent',
                    rightValue: survey.instance.all_assigned_count.email
                  },
                  {
                    leftValue: 'Responded',
                    rightValue: survey.instance.all_respondent_count.email
                  }
                ]"
              />
            </v-col>
            <v-divider vertical inset />
            <v-col cols="4">
              <div class="pb-2">Weblink Campaigns</div>
              <cp-chip-combo
                stacked
                :items="[
                  {
                    leftValue: 'Clicked',
                    rightValue: survey.instance.all_assigned_count.weblink
                  },
                  {
                    leftValue: 'Responded',
                    rightValue: survey.instance.all_respondent_count.weblink
                  }
                ]"
              />
            </v-col>
          </v-row>
        </cp-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="no-pad" flat color="transparent">
          <cp-toolbar height="44" rounded>
            <template #title>Campaigns</template>
            <v-spacer />
            <v-btn
              color="primary"
              rounded
              height="30"
              :disabled="!canCreateCampaigns"
              @click="$store.dispatch('campaign/activateLauncher')"
            >
              Create Campaign
            </v-btn>
          </cp-toolbar>
          <template>
            <div v-if="!campaign.loaded_campaigns" class="pa-6">
              <v-skeleton-loader
                v-for="n in 5"
                class="mb-4"
                type="image"
                width="100%"
                height="64px"
                :key="n"
              />
            </div>
            <template v-else-if="campaigns.length">
              <campaign-card
                v-for="item in campaigns"
                :key="item.id"
                :campaign-list-item="item"
                class="mt-4"
              />

              <cp-card dense>
                <v-row>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      small
                      icon
                      color="primary"
                      :disabled="campaign.pagination.number <= 1"
                      @click="
                        campaign.pagination.number = 1;
                        campaignChangedCallback();
                      "
                    >
                      <v-icon>mdi-chevron-double-left</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      color="primary"
                      :disabled="campaign.pagination.number <= 1"
                      @click="
                        campaign.pagination.number--;
                        campaignChangedCallback();
                      "
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <span class="mx-2 font-weight-bold">
                      Page {{ campaign.pagination.number }} /
                      {{ campaign.pagination.total }}
                    </span>

                    <v-btn
                      small
                      icon
                      color="primary"
                      :disabled="
                        campaign.pagination.number >= campaign.pagination.total
                      "
                      @click="
                        campaign.pagination.number++;
                        campaignChangedCallback();
                      "
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      color="primary"
                      :disabled="
                        campaign.pagination.number >= campaign.pagination.total
                      "
                      @click="
                        campaign.pagination.number = campaign.pagination.total;
                        campaignChangedCallback();
                      "
                    >
                      <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </cp-card>
            </template>
            <v-card-text
              v-else
              class="text-center text-h6 font-weight-bold mt-3"
            >
              Create your first campaign to get your survey out there!
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <cp-dialog
      :show-model.sync="campaign.viewer_open"
      header-background
      max-width="1160"
      scrollable
      @modalClosed="$store.commit('campaign/resetCampaignInstance')"
    >
      <template #title>
        Campaign Details
      </template>
      <template #body>
        <campaign-detail />
      </template>
    </cp-dialog>

    <survey-archive-dialog />
    <survey-publish-dialog />
    <survey-create-edit-dialog hide-activator />
    <campaign-create-launcher />
    <campaign-close-dialog />
    <campaign-cancel-dialog />
    <survey-send-preview />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ResponseRateDoughnut from "../components/campaign/ResponseRateDoughnut.vue";
import helpers from "../mixins/helpers";

export default {
  components: { ResponseRateDoughnut },
  mixins: [helpers],

  methods: {
    surveyChangedCallback() {
      this.$store.dispatch("survey/fetchSurvey");
      this.$CpEvent.$emit("snackAlert", { message: "Survey updated" });
    },

    campaignChangedCallback() {
      this.$store.dispatch("campaign/fetchCampaigns");
    },

    surveyDuplicatedCallback(id) {
      this.$router.push({
        name: "survey_detail",
        params: { id: id }
      });
    },

    campaignsFetchedCallback() {
      let campaign_poll_pending =
        this.campaigns.filter(x =>
          ["sending", "closing"].includes(x.status.slug)
        ).length > 0;

      if (campaign_poll_pending) {
        if (this.campaign.status_poll_interval_function == null) {
          this.campaign.status_poll_interval_function = setInterval(() => {
            this.$store.dispatch("campaign/pollForCampaignChanges");
          }, 5000);
        }
      } else {
        clearInterval(this.campaign.status_poll_interval_function);
      }
    }
  },

  computed: {
    ...mapState({
      survey: state => state.survey,
      campaign: state => state.campaign,
      campaigns: state => state.campaign.collection
    }),

    ...mapGetters("survey", ["canPublish", "canCreateCampaigns", "canEdit"])
  },

  mounted() {
    this.survey.editing = true;
    this.$store
      .dispatch("survey/fetchSurvey", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("campaign/fetchCampaigns");
      });

    this.$CpEvent.$on("surveyPublished", this.surveyChangedCallback);
    this.$CpEvent.$on("surveyArchived", this.surveyChangedCallback);
    this.$CpEvent.$on("campaignSaved", this.campaignChangedCallback);
    this.$CpEvent.$on("campaignsFetched", this.campaignsFetchedCallback);
    this.$CpEvent.$on("surveyDuplicated", this.surveyDuplicatedCallback);
  },

  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch("survey/fetchSurvey", to.params.id).then(() => {
      next();
    });
  },

  beforeDestroy() {
    clearInterval(this.campaign.status_poll_interval_function);
    this.$store.commit("survey/resetSurveyInstance");
    this.$store.commit("campaign/resetCampaignInstance");
    this.$store.commit("campaign/resetCampaignCollection");
    this.$CpEvent.$off("surveyPublished");
    this.$CpEvent.$off("surveyArchived");
    this.$CpEvent.$off("campaignSaved");
    this.$CpEvent.$off("campaignsFetched");
    this.$CpEvent.$off("surveyDuplicated");
  },

  watch: {
    "survey.editor_open"(opening, closing) {
      if (closing) {
        this.$store.commit("survey/resetSectionInstance");
        this.$store.dispatch("survey/fetchSurvey");
      }
    }
  }
};
</script>

<style scoped>
.toolbar {
  padding: 0;
  border-bottom: 1px solid #cccccc;
  height: 44px !important;
}
</style>
