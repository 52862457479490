<template>
  <div class="campaign-details">
    <div class="campaign-details__header">
      <v-row align="center" class="mb-3 mt-2">
        <v-col cols="auto">
          <h5 class="text-h5 mb-2">
            {{ campaign.instance.title }}
            <v-icon class="ml-3 mr-1 pb-0">mdi-account-circle</v-icon>
            <span class="text-body-2">{{
              campaign.instance.creator_name
            }}</span>
          </h5>
          <v-sheet
            color="#e6e6e6"
            rounded
            class="d-inline-block px-2 text-body-1"
          >
            Created: {{ getFormattedCreatedAt }}
          </v-sheet>
          <v-sheet
            v-if="campaign.instance.campaign_type_slug != 'weblink'"
            color="#e6e6e6"
            rounded
            class="d-inline-block px-2 ml-2 text-body-1"
          >
            Start: {{ getFormattedStartOn }}
          </v-sheet>
          <v-sheet
            v-if="campaign.instance.close_on"
            color="#e6e6e6"
            rounded
            class="d-inline-block px-2 ml-2 text-body-1"
          >
            Close: {{ getFormattedCloseOn }}
          </v-sheet>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="pa-0 mt-1"
                text
                color="primary"
                @click="$store.dispatch('campaign/downloadResults')"
              >
                Export
                <v-icon small class="ml-1">mdi-export</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  campaign.instance.campaign_type_slug == 'weblink' &&
                    campaign.instance.status.slug == 'live'
                "
                class="pa-0 mt-1 ml-4"
                text
                color="primary"
                @click="copyLink(campaign.instance.survey_id)"
              >
                Copy Link
                <v-icon small class="ml-1">mdi-content-copy</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <cp-select
                v-model="campaign.selected_filters.region"
                :items="campaign.available_filters.regions"
                return-object
              />
            </v-col>
            <v-col>
              <cp-select
                v-model="campaign.selected_filters.position_category"
                :items="campaign.available_filters.position_categories"
                return-object
              />
            </v-col>
          </v-row> -->
        </v-col>

        <v-spacer />

        <template v-if="campaign.instance.campaign_type_slug == 'weblink'">
          <v-col cols="auto" class="text-center">
            <cp-chip-combo
              disabled
              stacked
              :items="[
                {
                  leftValue: 'Respondents',
                  rightValue: campaign.instance.response_count
                }
              ]"
            />
          </v-col>
        </template>

        <template v-else>
          <v-col cols="auto" class="text-center">
            <response-rate-doughnut
              v-if="campaign.instance.response_rate != null"
              :value="campaign.instance.response_rate"
              size="140px"
            />

            <v-skeleton-loader
              v-else
              type="card"
              width="120px"
              height="120px"
              class="d-inline-block"
            />
          </v-col>

          <v-col cols="auto">
            <v-menu
              max-width="1000"
              v-model="campaign.recipient_details_open"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <cp-chip-combo
                  @chip-combo-clicked="showRecipients"
                  stacked
                  :items="[
                    {
                      leftValue: 'Recipients',
                      rightValue: campaign.instance.assigned_count
                    },
                    {
                      leftValue: 'Respondents',
                      rightValue: campaign.instance.response_count
                    }
                  ]"
                />
              </template>

              <v-card color="#ffffff" max-height="300">
                <v-card-text class="pa-0">
                  <v-simple-table fixed-header dense height="300">
                    <thead class="recipient-table-header">
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Position
                        </th>
                        <th class="text-left">
                          Location
                        </th>
                      </tr>
                    </thead>
                    <tbody class="recipient-table-data">
                      <tr
                        v-for="user in campaign.instance.assigned_users"
                        :key="user.id"
                      >
                        <td>{{ user.full_name }}</td>
                        <td>{{ user.position_name }}</td>
                        <td>{{ user.location_name }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </template>
      </v-row>
    </div>
    <div class="campaign-details__body">
      <v-skeleton-loader
        v-if="!campaign.report_loaded"
        type="text, article, text, article, text, article, text, article, text, article"
      />
      <q-r-summary
        v-else
        v-for="(question, i) in campaign.instance.questions"
        :key="i"
        :question="question"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import helpers from "../../mixins/helpers.js";

export default {
  mixins: [helpers],

  computed: {
    ...mapState({
      campaign: state => state.campaign,
      survey: state => state.survey
    }),

    ...mapGetters("campaign", [
      "getFormattedStartOn",
      "getFormattedCloseOn",
      "getFormattedCreatedAt"
    ])
  },

  methods: {
    showRecipients($evt) {
      if ($evt != "Recipients") return;

      this.campaign.recipient_details_open = !this.campaign
        .recipient_details_open;
    },

    copyLink(surveyId) {
      navigator.clipboard.writeText(
        `${process.env.VUE_APP_DARKHAVEN_UI_PATH}/feedback/weblink/${this.campaign.instance.id}/${surveyId}`
      );
      this.$CpEvent.$emit("snackAlert", {
        message: `Campaign Link Copied!`,
        color: "success"
      });
    }
  },

  watch: {
    "campaign.selected_filters": {
      handler(oldVal, newVal) {
        console.log(this.$store.state.campaign.selected_filters);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.recipient-table-header th {
  background-color: #f1f1f1 !important;
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody.recipient-table-data
  tr
  td {
  border-top: 1px solid #f1f1f1 !important;
  border-bottom: 1px solid #f1f1f1 !important;
}

// only applies when there are 2 boxes, and the first will be recipients
::v-deep .chip-combo-wrapper--stacked:nth-last-child(2) {
  cursor: pointer;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: rgb(215, 215, 215) !important;
  }
}
</style>
