import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSparkline } from 'vuetify/lib/components/VSparkline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary-wrapper",style:(("background-color: " + _vm.color + ";"))},[_c('router-link',{staticClass:"summary-title",attrs:{"to":{
      name: 'survey_detail',
      params: { id: _vm.summaryItem.parent_version },
    }}},[_c('div',{staticClass:"summary-title-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.summaryItem.name)+" ")]),_c(VIcon,{staticClass:"summary-title-icon",attrs:{"color":"#ffffff","large":""}},[_vm._v(" mdi-chevron-right ")])],1)]),_c(VCard,{staticClass:"summary-body"},[_c(VContainer,{staticClass:"pt-1 align-start fill-height"},[_c('div',{staticClass:"summary-campaign-list"},[_vm._l((_vm.summaryItem.email_campaigns.concat(
            _vm.summaryItem.weblink_campaigns
          )),function(campaignItem){return [_c(VRow,{staticClass:"mt-0",attrs:{"align":"start"}},[_c(VCol,[_c('a',{staticClass:"summary-body-title text-truncate",on:{"click":function($event){return _vm.$store.dispatch('campaign/activateViewer', {
                    campaign_id: campaignItem.id,
                    survey_id: _vm.summaryItem.parent_version,
                  })}}},[_c(VIcon,{staticClass:"mr-1",staticStyle:{"margin-top":"-1px"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.campaign.type_icon_map[campaignItem.type])+" ")]),_vm._v(" "+_vm._s(campaignItem.title)+" ")],1)])],1),_c(VRow,{staticClass:"text-center caption",attrs:{"dense":""}},[_c(VCol,{staticClass:"font-weight-medium mb-2 pl-2 py-0",attrs:{"cols":"12","align":"left"}},[_vm._v(" Started "+_vm._s(campaignItem.start_on)+" "),_c(VIcon,{staticStyle:{"margin-top":"-1px"},attrs:{"x-small":"","color":"grey lighten-1"}},[_vm._v(" mdi-arrow-right ")]),_vm._v(" "+_vm._s(_vm.daysLeft(campaignItem))+" ")],1),_c(VCol,{attrs:{"cols":"2"}},[_c('span',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(" "+_vm._s(campaignItem.type == 1 ? "NA" : campaignItem.sent)+" ")]),_c('br'),_vm._v(" "+_vm._s(campaignItem.type == "weblink" ? "Clicks" : "Sent")+" ")]),_c(VCol,{attrs:{"cols":"2"}},[_c('span',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(" "+_vm._s(campaignItem.started)+" ")]),_c('br'),_vm._v(" Started ")]),_c(VCol,{attrs:{"cols":"3"}},[_c('span',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(" "+_vm._s(campaignItem.completed)+" ")]),_c('br'),_vm._v(" Completed ")]),_c(VCol,{attrs:{"cols":"5"}},[_c(VSparkline,{attrs:{"fill":"","smooth":16,"gradient":['#f72047', '#ffd200', '#1feaea'],"line-width":10,"value":campaignItem.response_trend,"auto-draw":"","auto-line-width":"","stroke-linecap":"round","height":62}}),_vm._v(" Response Trend ")],1)],1)]})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }