<template>
  <cp-card dense flush-body class="inq-stat-card-wrapper fill-height">
    <div v-if="Object.keys(summary).length" class="inq-divider" />
    <v-container>
      <p class="text-subtitle-1 font-weight-bold mb-3">
        {{ title }}
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>Stats represent the past 90 days of activity.</span>
        </v-tooltip>
      </p>

      <template v-if="Object.keys(summary).length">
        <v-row>
          <v-col cols="6" class="inq-stat-card">
            <span class="inq-big-number mr-2">{{ summary.sent }}</span>
            <span class="text-subtitle-1 font-weight-bold grey--text">
              {{ leftLabel }}
            </span>
            <v-icon class="inq-stat-icon">{{ leftIcon }}</v-icon>
          </v-col>

          <v-col cols="6" align-self="end" align="left" class="inq-stat-card">
            <span class="inq-big-number mr-2">{{ summary.started }}</span>
            <span class="text-subtitle-1 font-weight-bold grey--text">
              Responded
            </span>
            <cp-doughnut
              :value="summary.response_rate || 0"
              size="100px"
              cutout="78%"
            >
              <template #inside>
                <h4>{{ summary.response_rate || 0 }}%</h4>
              </template>
            </cp-doughnut>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        There have been no {{ title.toLowerCase() }} created in the past 90
        days.
      </template>
    </v-container>
  </cp-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    leftIcon: { type: String, default: null },
    leftLabel: { type: String, default: null },
    summary: { type: Object, default: () => {} }
  }
};
</script>

<style lang="scss" scoped>
.inq-stat-card-wrapper,
.inq-stat-card {
  display: flex;
  align-items: flex-end;
}

.inq-big-number {
  font-size: 3rem;
  line-height: 2.9rem;
  color: $success;
}

.inq-stat-icon,
.inq-stat-icon.v-icon {
  font-size: 3.5rem;
  line-height: 3rem;
  margin-left: auto;
}

.CpDoughnut {
  display: inline-block;
  margin-left: auto;
  margin-top: -100%;

  h4 {
    font-size: 1.5rem;
  }
}

.inq-divider {
  width: 2px;
  height: calc(100% - 16px);
  background-color: $gray-1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
