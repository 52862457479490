import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section-transfer-dialog'),_c('question-remove-from-section-dialog'),_c(VDataTable,{attrs:{"hide-default-footer":"","dense":"","disable-pagination":"","fixed-header":"","loading-text":"","loading":_vm.survey.loading_all_questions,"items":_vm.survey.section_instance.questions,"headers":[
      { width: '10px', sortable: false },
      {
        text: 'Question',
        value: 'body',
        sortable: false,
      },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false } ]},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","handle":".draggable"},on:{"update":_vm.updateQuestionOnMove}},_vm._l((props.items),function(item){return _c('tr',[_c('td',{attrs:{"width":"1%"}},[_c(VIcon,{staticClass:"draggable"},[_vm._v("mdi-drag-vertical")])],1),_c('td',{staticClass:"text-body-1 py-2 pl-9",staticStyle:{"position":"relative"}},[(item.created_by == 'Swift Bunny')?_c(VIcon,{staticClass:"sb-owned-indicator"},[_vm._v(" $vuetify.icons.SwiftBunnyLogo ")]):_vm._e(),_c('span',{staticClass:"table-link-text"},[_vm._v(_vm._s(item.body))])],1),_c('td',{staticClass:"text-body-1",attrs:{"width":"20%"}},[_vm._v(_vm._s(item.type.text))]),_c('td',{attrs:{"width":"5%"}},[_c('question-menu-actions',{attrs:{"question-object":item}})],1)])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }