<template>
  <v-data-table
    v-bind="$attrs"
    :loading="survey.loading_all_surveys"
    sort-by="updated_at"
    :sort-desc="true"
    :headers="[
      { text: 'Name', value: 'name', sortable: !noManualSort, class: 'pl-8' },
      { text: 'Live', value: 'is_live', sortable: !noManualSort },
      { text: 'Creator', value: 'created_by', sortable: !noManualSort },
      { text: 'Status', value: 'status_slug', sortable: !noManualSort },
      { text: 'Modified', value: 'updated_at', sortable: !noManualSort },
      { text: 'Actions', value: 'actions', sortable: false },
    ]"
    :items="surveyCollection"
    :search="survey.search"
  >
    <!-- <v-data-table
    v-bind="$attrs"
    :loading="survey.loading_all_surveys"
    sort-by="updated_at"
    :sort-desc="true"
    :headers="[
      { text: 'Name', value: 'name', sortable: !noManualSort, class: 'pl-8' },
      { text: 'Live', value: 'is_live', sortable: !noManualSort },
      { text: 'Creator', value: 'created_by', sortable: !noManualSort },
      { text: 'Status', value: 'status_slug', sortable: !noManualSort },
      { text: 'Modified', value: 'updated_at', sortable: !noManualSort },
      { text: 'Actions', value: 'actions', sortable: false },
    ]"
    :items="surveyCollection"
    :items-per-page.sync="survey.pagination.perPage"
    :server-items-length="survey.pagination.total"
    :page.sync="survey.pagination.number"
    :search="survey.search"
    @update:page="$store.dispatch('survey/fetchSurveys')"
    @update:items-per-page="$store.dispatch('survey/fetchSurveys')"
    :footer-props="{
      'items-per-page-options': [5, 10, 15],
      'disable-pagination': survey.loading_all_surveys,
    }"
  > -->
    <template #item="{item}">
      <tr>
        <td width="auto" class="pl-8 text-truncate" style="position: relative;">
          <v-icon
            v-if="item.created_by == 'Swift Bunny'"
            class="sb-owned-indicator"
          >
            $vuetify.icons.SwiftBunnyLogo
          </v-icon>
          <router-link
            class="table-link"
            :to="{ name: 'survey_detail', params: { id: item.id } }"
          >
            <span class="table-link-text">{{ item.name }}</span>
          </router-link>
        </td>
        <td width="8%">
          <div v-if="item.is_live" class="ml-2 campaign-live-indicator" />
        </td>
        <td width="10%">
          {{ item.created_by }}
        </td>
        <td width="10%">
          <cp-status-chip label dark :status="item.status_slug">{{
            item.status_slug
          }}</cp-status-chip>
        </td>
        <td width="10%">{{ formatAmericanDate(item.updated_at) }}</td>
        <td width="5%">
          <survey-menu-actions :survey-object="item" />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import helpers from "../../mixins/helpers.js";

export default {
  props: {
    surveyCollection: { type: Array, default: () => [] },
    noManualSort: { type: Boolean, default: false },
  },

  mixins: [helpers],

  computed: {
    ...mapState({
      survey: (state) => state.survey,
    }),
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  from {
    box-shadow: 0 0 6px 4px rgba(#dc001b, 0.15);
  }

  to {
    box-shadow: 0 0 8px 5px rgba(#dc001b, 0.6);
  }
}

.campaign-live-indicator {
  display: inline-block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #dc001b;
  box-shadow: 0 0 6px 4px rgba(#dc001b, 0.15);
  animation-duration: 1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.table-link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.table-link-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
