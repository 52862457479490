import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.campaign.instance.campaign_type_id != 1)?[_c(VStepperStep,{attrs:{"step":"2","complete":_vm.campaign.current_step > 2,"editable":_vm.campaign.current_step > 2,"edit-icon":"$complete"}},[_vm._v(" Pick Your Recipients "),_c('small',[_vm._v(_vm._s(_vm.getRecipientCount)+" total employees")])]),_c(VStepperContent,{attrs:{"step":"2"}},[_c('div',{staticClass:"limited-max-width"},[_c('h4',[_vm._v(" You can select from any of the employees found within the Swift Bunny Data Manager, organized by region and position category. ")]),(_vm.campaign.instance.campaign_type_id == 2)?_c('h4',[_c(VBanner,{staticClass:"mt-2",attrs:{"icon":"mdi-alert-circle-outline","icon-color":"warning","outlined":"","rounded":""}},[_vm._v(" Note, since this is a confidential campaign, we recommend selecting at least 15 recipients to have the best chance of responses being visible. ")])],1):_vm._e(),_c('cp-card',{staticClass:"my-4",attrs:{"dense":"","flush-body":"","flat":"","color":"#f1f1f1"}},[_c('cp-banner',{attrs:{"color":"#f1f1f1"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Who's Getting this Survey? ")]},proxy:true},{key:"actions",fn:function(){return [_vm._v(" "+_vm._s(_vm.getRecipientCount)+" Unique Recipients ")]},proxy:true}],null,false,2640641837)}),_c('div',{staticClass:"pa-4"},_vm._l((_vm.categories),function(category){return _c('div',{staticClass:"pb-4"},[_c('h1',{staticClass:"text-bold mb-2"},[_vm._v(" Employees by "+_vm._s(category.label)+" ")]),_c(VExpansionPanels,_vm._l((_vm.campaign.available_recipients.filter(
                    function (x) { return x.model == category.model; }
                  )),function(area,i){return _c(VExpansionPanel,{key:i,attrs:{"disabled":area.total_users <= 0},on:{"change":function($event){return _vm.getChildren(area)}}},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(area.name)+" "),(area.total_users <= 0)?[_vm._v(" - No Active Employees ")]:_vm._e()],2),_c(VExpansionPanelContent,[_c(VTextField,{staticClass:"mb-2",attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.recipientSearch[area.name]),callback:function ($$v) {_vm.$set(_vm.recipientSearch, area.name, $$v)},expression:"recipientSearch[area.name]"}}),_c(VDataTable,{staticClass:"recipient-data-table",attrs:{"items":area.users,"loading":_vm.gettingChildren,"disable-pagination":"","hide-default-footer":"","fixed-header":"","search":_vm.recipientSearch[area.name],"show-select":"","dense":"","headers":[
                        {
                          text: 'Name',
                          align: 'start',
                          sortable: true,
                          value: 'name',
                        },
                        {
                          text: 'Location',
                          align: 'start',
                          sortable: true,
                          value: 'location_name',
                        },
                        {
                          text: 'Position',
                          align: 'start',
                          sortable: true,
                          value: 'position_name',
                        } ]},model:{value:(_vm.campaign.instance.selected_recipients),callback:function ($$v) {_vm.$set(_vm.campaign.instance, "selected_recipients", $$v)},expression:"campaign.instance.selected_recipients"}})],1)],1)}),1)],1)}),0)],1),_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"text":"","color":"primary","rounded":""},on:{"click":function($event){_vm.campaign.current_step--}}},[_vm._v(" Back ")]),_c(VBtn,{attrs:{"color":"primary","rounded":"","disabled":_vm.getRecipientCount <= 0 ||
                  (_vm.campaign.instance.campaign_type_id == 2 &&
                    _vm.getRecipientCount < 15)},on:{"click":function($event){_vm.campaign.current_step++}}},[_vm._v(" Continue ")]),_c('br'),(
                _vm.campaign.instance.campaign_type_id == 2 &&
                  _vm.getRecipientCount < 15
              )?_c('div',{staticClass:"font-weight-light font-italic mt-2"},[_vm._v(" Please select "+_vm._s(15 - _vm.getRecipientCount)+" more "+_vm._s(_vm._f("pluralize")("recipient",15 - _vm.getRecipientCount))+". ")]):_vm._e()],1)],1)],1)])]:_vm._e(),_c(VStepperStep,{attrs:{"step":_vm.campaign.instance.campaign_type_id == 1 ? 2 : 3,"complete":_vm.campaign.current_step >
        (_vm.campaign.instance.campaign_type_id == 1 ? 2 : 3),"editable":_vm.campaign.current_step >
        (_vm.campaign.instance.campaign_type_id == 1 ? 2 : 3),"edit-icon":"$complete"}},[_vm._v(" Schedule Your Campaign ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }