import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VStepperStep,{attrs:{"step":"1","complete":_vm.campaign.current_step > 1,"editable":"","edit-icon":"$complete"}},[_vm._v(" Name Your Campaign "),_c('small',[_vm._v(_vm._s(_vm.campaign.instance.title || "Our Awesome New Campaign"))])]),_c(VStepperContent,{attrs:{"step":"1"}},[_c('div',{staticClass:"limited-max-width"},[_c(VTextField,{ref:"campaignTitle",staticClass:"mb-3",attrs:{"label":"Campaign Title","flat":"","required":"","hint":"Required","persistent-hint":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.campaign.instance.title) {
            _vm.campaign.current_step++;
          }}},model:{value:(_vm.campaign.instance.title),callback:function ($$v) {_vm.$set(_vm.campaign.instance, "title", $$v)},expression:"campaign.instance.title"}}),_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"color":"primary","rounded":"","disabled":!_vm.campaign.instance.title},on:{"click":function($event){_vm.campaign.current_step++}}},[_vm._v(" Continue ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }