import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-footer",attrs:{"align":"center"}},[_c('div',{staticClass:"survey-width-limit"},[(
        ['sent', 'started'].includes(_vm.service.instance.status) ||
          _vm.service.is_preview
      )?_c('div',{staticClass:"pa-5 pb-2"},[_c(VBtn,{staticClass:"mx-4",attrs:{"disabled":!_vm.canGoBack,"color":"primary"},on:{"click":function($event){return _vm.$store.dispatch(
            'surveyRenderService/initializeSection',
            _vm.survey.instance.sections[_vm.section.position - 1].id
          )}}},[_vm._v(" Back ")]),(_vm.canGoForward)?_c(VBtn,{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$store.dispatch(
            'surveyRenderService/initializeSection',
            _vm.survey.instance.sections[_vm.section.position + 1].id
          )}}},[_vm._v(" Next ")]):(_vm.canSubmit)?_c(VBtn,{staticClass:"mx-4",attrs:{"color":"success"},on:{"click":function($event){_vm.service.confirming_submit = true}}},[_vm._v(" Submit ")]):_vm._e(),_c('cp-dialog',{attrs:{"show-model":_vm.service.confirming_submit,"fixed-header-footer":"","header-background":"","max-width":"500"},on:{"update:showModel":function($event){return _vm.$set(_vm.service, "confirming_submit", $event)},"update:show-model":function($event){return _vm.$set(_vm.service, "confirming_submit", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h1',[_vm._v("All Finished?")])]},proxy:true},{key:"body",fn:function(){return [_c('h1',[_vm._v(" Once your survey has been submitted, you won't be able to change any of your responses. ")])]},proxy:true},{key:"actions",fn:function(ref){
          var close = ref.close;
return [_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"success","loading":_vm.service.submitting_survey,"disabled":_vm.service.submitting_survey},on:{"click":function($event){return _vm.$store.dispatch('surveyRenderService/submitSurvey')}}},[_vm._v(" Submit ")]),(_vm.service.is_preview)?_c('div',{staticClass:"text-caption text-right mt-3"},[_vm._v(" Surveys cannot be submitted in preview mode. ")]):_vm._e()],1)]}}],null,false,4263327442)})],1):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('a',{attrs:{"href":"mailto:support@swiftbunny.com"}},[_vm._v("support@swiftbunny.com")]),_vm._v(" | "),_c('a',{attrs:{"href":"https://swiftbunny.com/privacy-policy"}},[_vm._v("Privacy Policy")]),_c('p',{staticClass:"caption"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Swift Bunny LLC. All rights reserved. ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }