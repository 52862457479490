<template>
  <cp-menu
    @menuOpened="
      $store.dispatch('campaign/buildCampaignMenuActions', campaignObject)
    "
    bottom
    left
    transition="slide-y-transition"
    :options="campaign.campaign_menu_actions"
    :min-width="130"
  >
  </cp-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    campaignObject: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign
    })
  }
};
</script>
