import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mt-4"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('cp-card',{staticClass:"fill-height",attrs:{"dense":"","wrap-title-width":"65%"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.survey.instance.name)+" "),(_vm.survey.instance.status_slug === 'draft')?_c('span',[_vm._v(" (DRAFT) ")]):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [(_vm.canPublish)?_c('cp-btn',{staticClass:"text-body-1",attrs:{"height":"30","color":"success"},on:{"click":function($event){_vm.survey.publish_confirmation_open = true}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-check-circle")]),_vm._v(" Publish ")],1):_vm._e(),_c('survey-menu-actions',{attrs:{"survey-object":_vm.survey.instance}})]},proxy:true}])},[_c(VRow,[_c(VCol,{staticClass:"pa-4",attrs:{"cols":"8"}},[_c('h6',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.survey.instance.created_by)+" created "+_vm._s(_vm.formatAmericanDate(_vm.survey.instance.created_at))+" | "+_vm._s(_vm.survey.instance.updated_by)+" modified "+_vm._s(_vm.formatAmericanDate(_vm.survey.instance.updated_at))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.survey.instance.description || "This survey has not been given a description.")+" ")])]),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{staticClass:"pa-4",attrs:{"cols":"4","align":"center"}},[_c('span',{staticClass:"text-h3 black--text text-center"},[_vm._v(" "+_vm._s(_vm.survey.instance.question_ids.length)+" ")]),_c('h4',[_vm._v(" "+_vm._s(_vm._f("pluralize")("Question",_vm.survey.instance.question_ids.length))+" ")]),(_vm.canEdit)?_c(VBtn,{staticClass:"mt-3",attrs:{"rounded":"","dense":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch(
                  'survey/activateCreateEditDialog',
                  _vm.survey.instance.id
                )}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e()],1)],1)],1)],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('cp-card',{staticClass:"fill-height",attrs:{"dense":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Activity")]},proxy:true}])},[_c(VRow,{staticClass:"pa-4",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[_c('response-rate-doughnut',{attrs:{"value":_vm.survey.instance.all_response_rate}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c('div',{staticClass:"pb-2"},[_vm._v("Email Campaigns")]),_c('cp-chip-combo',{attrs:{"stacked":"","items":[
                {
                  leftValue: 'Sent',
                  rightValue: _vm.survey.instance.all_assigned_count.email
                },
                {
                  leftValue: 'Responded',
                  rightValue: _vm.survey.instance.all_respondent_count.email
                }
              ]}})],1),_c(VDivider,{attrs:{"vertical":"","inset":""}}),_c(VCol,{attrs:{"cols":"4"}},[_c('div',{staticClass:"pb-2"},[_vm._v("Weblink Campaigns")]),_c('cp-chip-combo',{attrs:{"stacked":"","items":[
                {
                  leftValue: 'Clicked',
                  rightValue: _vm.survey.instance.all_assigned_count.weblink
                },
                {
                  leftValue: 'Responded',
                  rightValue: _vm.survey.instance.all_respondent_count.weblink
                }
              ]}})],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"no-pad",attrs:{"flat":"","color":"transparent"}},[_c('cp-toolbar',{attrs:{"height":"44","rounded":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Campaigns")]},proxy:true}])},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","rounded":"","height":"30","disabled":!_vm.canCreateCampaigns},on:{"click":function($event){return _vm.$store.dispatch('campaign/activateLauncher')}}},[_vm._v(" Create Campaign ")])],1),[(!_vm.campaign.loaded_campaigns)?_c('div',{staticClass:"pa-6"},_vm._l((5),function(n){return _c(VSkeletonLoader,{key:n,staticClass:"mb-4",attrs:{"type":"image","width":"100%","height":"64px"}})}),1):(_vm.campaigns.length)?[_vm._l((_vm.campaigns),function(item){return _c('campaign-card',{key:item.id,staticClass:"mt-4",attrs:{"campaign-list-item":item}})}),_c('cp-card',{attrs:{"dense":""}},[_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"small":"","icon":"","color":"primary","disabled":_vm.campaign.pagination.number <= 1},on:{"click":function($event){_vm.campaign.pagination.number = 1;
                      _vm.campaignChangedCallback();}}},[_c(VIcon,[_vm._v("mdi-chevron-double-left")])],1),_c(VBtn,{attrs:{"small":"","icon":"","color":"primary","disabled":_vm.campaign.pagination.number <= 1},on:{"click":function($event){_vm.campaign.pagination.number--;
                      _vm.campaignChangedCallback();}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" Page "+_vm._s(_vm.campaign.pagination.number)+" / "+_vm._s(_vm.campaign.pagination.total)+" ")]),_c(VBtn,{attrs:{"small":"","icon":"","color":"primary","disabled":_vm.campaign.pagination.number >= _vm.campaign.pagination.total},on:{"click":function($event){_vm.campaign.pagination.number++;
                      _vm.campaignChangedCallback();}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1),_c(VBtn,{attrs:{"small":"","icon":"","color":"primary","disabled":_vm.campaign.pagination.number >= _vm.campaign.pagination.total},on:{"click":function($event){_vm.campaign.pagination.number = _vm.campaign.pagination.total;
                      _vm.campaignChangedCallback();}}},[_c(VIcon,[_vm._v("mdi-chevron-double-right")])],1)],1)],1)],1)]:_c(VCardText,{staticClass:"text-center text-h6 font-weight-bold mt-3"},[_vm._v(" Create your first campaign to get your survey out there! ")])]],2)],1)],1),_c('cp-dialog',{attrs:{"show-model":_vm.campaign.viewer_open,"header-background":"","max-width":"1160","scrollable":""},on:{"update:showModel":function($event){return _vm.$set(_vm.campaign, "viewer_open", $event)},"update:show-model":function($event){return _vm.$set(_vm.campaign, "viewer_open", $event)},"modalClosed":function($event){return _vm.$store.commit('campaign/resetCampaignInstance')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Campaign Details ")]},proxy:true},{key:"body",fn:function(){return [_c('campaign-detail')]},proxy:true}])}),_c('survey-archive-dialog'),_c('survey-publish-dialog'),_c('survey-create-edit-dialog',{attrs:{"hide-activator":""}}),_c('campaign-create-launcher'),_c('campaign-close-dialog'),_c('campaign-cancel-dialog'),_c('survey-send-preview')],1)}
var staticRenderFns = []

export { render, staticRenderFns }