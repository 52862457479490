<template>
  <div>
    <v-stepper-step
      step="1"
      :complete="campaign.current_step > 1"
      editable
      edit-icon="$complete"
    >
      Name Your Campaign
      <small>{{ campaign.instance.title || "Our Awesome New Campaign" }}</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <div class="limited-max-width">
        <v-text-field
          v-model="campaign.instance.title"
          label="Campaign Title"
          flat
          required
          hint="Required"
          persistent-hint
          class="mb-3"
          ref="campaignTitle"
          @keyup.enter="
            if (campaign.instance.title) {
              campaign.current_step++;
            }
          "
        />
        <v-row class="mb-1">
          <v-col align="right">
            <v-btn
              color="primary"
              rounded
              @click="campaign.current_step++"
              :disabled="!campaign.instance.title"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      campaign: state => state.campaign
    })
  },

  watch: {
    "campaign.current_step"(newStep) {
      if (newStep != 1) {
        this.$refs.campaignTitle.blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.limited-max-width {
  max-width: 760px;
}
</style>
