<template>
  <div>
    <cp-btn
      text
      tile
      color="primary"
      @click="survey.remove_section_open = true"
    >
      <v-icon small color="primary" class="ma-1">mdi-trash-can</v-icon>
      Remove Section
    </cp-btn>

    <cp-dialog
      :show-model.sync="survey.remove_section_open"
      class="mx-auto"
      headerBackground
      width="600"
    >
      <template #title>
        Remove Section
      </template>

      <template
        v-if="
          getMovableSections.length > 0 &&
            survey.section_instance.questions.length > 0
        "
        #body="{close}"
      >
        <v-row class="pb-8">
          <v-col>
            What would you like to do with the questions in this section?
          </v-col>
        </v-row>
        <v-row style="min-height: 189px ">
          <v-tabs
            height="48"
            centered
            hide-slider
            color="#6B6B70"
            background-color="grey lighten-2"
            fixed-tabs
          >
            <v-tab
              height="32"
              active-class="active"
              class="pa-4 mx-1 my-auto text-no-wrap"
            >
              Move to another section
            </v-tab>
            <v-tab-item>
              <cp-select
                label="Select Section"
                v-model="sectionToMoveTo"
                :items="getMovableSections"
                item-text="name"
                item-value="id"
                ref="newSection"
              />
              <v-row class="ma-4" justify="end">
                <cp-btn @click="close" text color="error">
                  Cancel
                </cp-btn>
                <cp-btn
                  @click="
                    $store.dispatch(
                      'survey/transferAllQuestions',
                      sectionToMoveTo
                    )
                  "
                  color="primary"
                  :disabled="!sectionToMoveTo"
                  :loading="survey.transferring_questions"
                >
                  Confirm
                </cp-btn>
              </v-row>
            </v-tab-item>

            <v-tab height="32" active-class="active" class="pa-4 mx-1 my-auto">
              Discard
            </v-tab>
            <v-tab-item class="ma-4">
              <v-row justify="center">
                <cp-btn @click="close" color="error" class="ma-4" text>
                  Cancel
                </cp-btn>
                <cp-btn
                  @click="$store.dispatch('survey/deleteSection')"
                  color="primary"
                  class="ma-5 "
                >
                  Yes, Delete This Section
                </cp-btn>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </template>

      <template v-else #body="{close}">
        <v-row class="pb-4">
          <v-col>
            Are you sure? This will permanently delete this section.
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="pb-4">
          <cp-btn color="error" text @click="close">
            Cancel
          </cp-btn>
          <cp-btn
            @click="$store.dispatch('survey/deleteSection')"
            color="primary"
          >
            Yes, Delete This Section
          </cp-btn>
        </v-row>
      </template>
    </cp-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      sectionToMoveTo: null
    };
  },

  computed: {
    ...mapGetters("survey", ["getMovableSections"]),

    ...mapState({
      question: state => state.question,
      survey: state => state.survey
    })
  },

  methods: {
    sectionDeletedListener() {
      this.survey.remove_section_open = false;
    }
  },

  mounted() {
    this.$CpEvent.$on("sectionDeleted", this.sectionDeletedListener);
  }
};
</script>

<style scoped>
.active {
  background-color: rgba(255, 255, 255, 1) !important;
  height: 38px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgb(179, 178, 178);
  text-decoration: none;
}
</style>
