<template>
  <cp-fullscreen-modal
    :title="title"
    back-text="Close"
    back-icon="close"
    :show="survey.editor_open"
    @fullscreenModalClosed="closeModal"
  >
    <template #activator v-if="!hideActivator">
      <v-btn
        rounded
        dense
        color="primary"
        @click="$store.dispatch('survey/activateCreateEditDialog')"
      >
        <v-icon>mdi-{{ icon }}</v-icon>
        {{ title }}
      </v-btn>
    </template>

    <template #default="{close}">
      <cp-card class="mx-auto ma-0 pa-0">
        <cp-banner single-line>
          <template v-slot:body>
            <div class="text-h6 font-weight-regular">
              {{ title }}
            </div>
          </template>

          <template #actions>
            <cp-btn
              text
              color="primary"
              class="text-body-1"
              :href="`/surveys/${survey.instance.id}/preview`"
              target="_blank"
              :disabled="survey.instance.sections.length <= 0"
            >
              <v-icon class="ma-2">mdi-eye</v-icon>
              Preview
            </cp-btn>
          </template>
        </cp-banner>
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <section-list-card />
            </v-col>
            <v-col cols="8">
              <survey-info-create-edit-card
                v-if="survey.active_editor == 'info'"
              />
              <section-create-edit-card
                v-if="survey.active_editor == 'section'"
              />
              <survey-questions-card
                v-if="survey.active_editor == 'questions'"
              />
            </v-col>
          </v-row>
        </v-container>
      </cp-card>
    </template>
    <v-row class="footer-row">
      <v-col cols="12">
        <cp-footer />
      </v-col>
    </v-row>
  </cp-fullscreen-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    hideActivator: { type: Boolean, default: false }
  },

  computed: {
    ...mapState({
      survey: state => state.survey
    }),

    title() {
      return this.survey.editing ? "Edit Survey" : "New Survey";
    },

    icon() {
      return this.survey.editing ? "pencil" : "plus";
    }
  },

  methods: {
    closeModal() {
      if (this.survey.new_survey_created) {
        this.survey.new_survey_created = false;
        window.location.href = `${process.env.VUE_APP_TASSEL_UI_PATH}/surveys/${this.survey.instance.id}`;
      } else {
        this.$store.dispatch("survey/deactivateCreateEditDialog");
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch("survey/deactivateCreateEditDialog");
  }
};
</script>

<style>
.cp-modal .modal-content {
  max-width: 87% !important;
}
</style>
