export default {
  getSUSlug(state, getters, rootState) {
    let slug = rootState.surveyRenderService.instance.slug;
    return slug ? `surveys-users/${slug}/` : "";
  },

  getMovableSections(state) {
    return state.instance.sections.filter(
      x => x.id != state.section_instance.id
    );
  },

  getLastSection(state) {
    return state.instance.sections[state.instance.sections.length - 1];
  },

  canPublish(state) {
    return state.survey_loaded && state.instance.status_slug != "active";
  },

  canEdit(state) {
    return state.survey_loaded && state.instance.created_by != "Swift Bunny";
  },

  canArchive(state) {
    return state.survey_loaded && state.instance.created_by != "Swift Bunny";
  },

  canCreateCampaigns(state) {
    return (
      state.survey_loaded &&
      state.instance.status_slug == "active" &&
      state.instance.question_count > 0
    );
  }
};
