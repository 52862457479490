<template>
  <v-radio-group v-model="value.multiple_choice" class="cp-multi-choice">
    <v-radio
      v-for="option in question.options.filter(x => !x._destroy)"
      :key="option.id"
      :label="option.body"
      :value="option.id"
      class="text-bold ma-0"
    />
  </v-radio-group>
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  extends: QuestionsShared
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cp-multi-choice {
  text-align: left;

  &::v-deep {
    .v-input--radio-group__input {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
    }

    .v-input--selection-controls__input {
      align-self: flex-start;
    }

    .v-label {
      margin-top: 2px;
    }
  }

  .v-radio {
    text-align: left;
    width: 100%;
    padding: 4px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 50%;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 30%;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      width: 50%;
    }
  }
}
</style>
