<template>
  <div>
    <v-row v-if="survey.summary_collection.length > 0" dense class="mb-8 mt-4">
      <v-col cols="12" md="6" class="pt-0">
        <stat-card
          :summary="emailCampaigns"
          title="Email Campaigns"
          left-icon="mdi-send"
          left-label="Sent"
        />
      </v-col>

      <v-col cols="12" md="6" class="pt-0">
        <stat-card
          :summary="weblinkCampaigns"
          title="Weblink Campaigns"
          left-icon="mdi-cursor-default-click"
          left-label="Clicked"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" class="pt-0">
        <cp-card dense body-color="#f0f0f0">
          <template #title>
            Live Surveys
            <v-chip class="ml-2">
              {{ survey.summary_collection.length }}
              {{ "Survey" | pluralize(survey.summary_collection.length) }} •
              {{ activeCampaignCount }}
              {{ "Campaign" | pluralize(activeCampaignCount) }}
            </v-chip>
          </template>

          <template #actions>
            <v-btn
              class="px-4"
              color="primary"
              rounded
              @click="$store.dispatch('survey/activateCreateEditDialog')"
            >
              <v-icon>mdi-plus</v-icon> Create Survey
            </v-btn>
          </template>

          <v-row>
            <template v-if="survey.summary_collection.length > 0">
              <v-col
                v-for="(summaryItem, i) in survey.summary_collection"
                cols="12"
                md="6"
                lg="4"
                :key="i"
              >
                <survey-live-summary-card :summary-item="summaryItem" />
              </v-col>
            </template>
            <v-col v-else class="my-8" align="center">
              <v-alert
                class="no-content-banner"
                type="info"
                color="primary"
                border="left"
                prominent
                text
              >
                <span class="text-h5">
                  No surveys are currently live and taking feedback.
                </span>
                <br />
                <span class="text-subtitle-1">
                  Create a survey and schedule a campaign to get started!
                </span>
              </v-alert>
            </v-col>
          </v-row>
        </cp-card>
      </v-col>
    </v-row>

    <cp-card dense body-color="#f0f0f0" class="mt-8">
      <template #title>
        Recently Ended Campaigns
        <v-chip class="ml-2 my-2">
          {{ survey.recently_ended_summary_collection.length }}
          {{
            "Survey"
              | pluralize(survey.recently_ended_summary_collection.length)
          }}
          •
          {{ recentlyEndedCampaignCount }}
          {{ "Campaign" | pluralize(recentlyEndedCampaignCount) }}
        </v-chip>
      </template>

      <template #actions>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>
            Showing data for campaigns that have closed in the past 2 weeks.
          </span>
        </v-tooltip>
      </template>

      <v-row>
        <template v-if="survey.recently_ended_summary_collection.length > 0">
          <v-col
            v-for="(summaryItem, i) in survey.recently_ended_summary_collection"
            cols="12"
            md="6"
            lg="4"
            :key="i"
          >
            <survey-live-summary-card
              :summary-item="summaryItem"
              color="#008A09"
            />
          </v-col>
        </template>
        <v-col v-else class="my-8" align="center">
          <v-alert
            class="no-content-banner"
            type="info"
            color="primary"
            border="left"
            prominent
            text
          >
            <span class="text-h5">
              No surveys have ended in the past 2 weeks.
            </span>
          </v-alert>
        </v-col>
      </v-row>
    </cp-card>

    <cp-dialog
      :show-model.sync="campaign.viewer_open"
      max-width="1160px"
      scrollable
      header-background
      @modalClosed="$store.commit('campaign/resetCampaignInstance')"
    >
      <template #title>
        Campaign Details
      </template>
      <template #body>
        <campaign-detail />
      </template>
    </cp-dialog>

    <survey-create-edit-dialog hide-activator />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      survey: state => state.survey,
      campaign: state => state.campaign,
    }),

    emailCampaigns() {
      let campaigns = this.survey.campaign_summary_collection
        .map(x => x.email_campaigns)
        .flat();

      return this.buildCampaignTotals(campaigns);
    },

    weblinkCampaigns() {
      let campaigns = this.survey.campaign_summary_collection
        .map(x => x.weblink_campaigns)
        .flat();

      return this.buildCampaignTotals(campaigns);
    },

    activeCampaignCount() {
      let emails = this.survey.summary_collection
        .map(x => x.email_campaigns)
        .flat().length;
      let weblinks = this.survey.summary_collection
        .map(x => x.weblink_campaigns)
        .flat().length;

      return emails + weblinks;
    },

    recentlyEndedCampaignCount() {
      let emails = this.survey.recently_ended_summary_collection
        .map(x => x.email_campaigns)
        .flat().length;
      let weblinks = this.survey.recently_ended_summary_collection
        .map(x => x.weblink_campaigns)
        .flat().length;

      return emails + weblinks;
    },
  },

  methods: {
    buildCampaignTotals(campaigns = []) {
      if (campaigns.length <= 0) return {};

      let sent = campaigns.map(x => x.sent).reduce((a, b) => a + b);
      let started = campaigns.map(x => x.started).reduce((a, b) => a + b);
      let completed = campaigns.map(x => x.completed).reduce((a, b) => a + b);

      return {
        sent: sent,
        started: started,
        completed: completed,
        response_rate: sent <= 0 ? 0 : Math.floor((started / sent) * 100),
        type: campaigns[0].type == "weblink" ? "weblink" : "email",
      };
    },
  },

  mounted() {
    this.$store.dispatch("survey/fetchCampaignSummary");
    this.$store.dispatch("survey/fetchSurveySummaryItems");
    this.$store.dispatch("survey/fetchRecentlyEndedSurveySummaryItems");
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.dash-header {
  color: #ffffff;
}

.no-content-banner {
  font-weight: bold;
  display: inline-block;
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: 0 auto;

  &::v-deep .v-alert__icon {
    margin-left: 24px !important;
    margin-right: 32px !important;
  }
}

.campaign-live-indicator {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: #dc001b;
  box-shadow: 0 0 6px 4px rgba(#dc001b, 0.15);
  animation-duration: 1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  margin-top: 1.5rem;
}

.inq-dash-stat {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
</style>
