<script>
import { mapState } from "vuex";

export default {
  props: {
    question: Object,
    response: Object
  },

  data() {
    return {
      userTextEntryDelayTimer: null,
      responsesSet: false,
      value: {}
    };
  },

  computed: {
    ...mapState({
      service: state => state.surveyRenderService
    })
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.response != null) {
        this.value = this.response.value;
      }
      this.responsesSet = true;

      this.$watch(
        "value",
        function(newValue, oldValue) {
          this.responseWatcher();
        },
        { deep: true }
      );
    },

    responseWatcher() {
      if (!this.responsesSet) return;

      if (["text-entry", "numeric-entry"].includes(this.question.type.slug)) {
        clearTimeout(this.userTextEntryDelayTimer);
        this.userTextEntryDelayTimer = setTimeout(() => {
          this.$emit("response-updated", {
            question_id: this.question.id,
            value: this.value
          });
        }, 400);
      } else {
        this.$emit("response-updated", {
          question_id: this.question.id,
          value: this.value
        });
      }
    }
  }
};
</script>
