<template>
  <v-radio-group row class="yes-no-wrapper" v-model="value.yes_no">
    <v-radio class="yes-no-input" label="Yes" :value="1" />
    <v-radio class="yes-no-input" label="No" :value="0" />
  </v-radio-group>
</template>

<script>
import QuestionsShared from "./QuestionsShared.vue";

export default {
  extends: QuestionsShared
};
</script>

<style lang="scss" scoped>
.yes-no-wrapper {
  display: inline-block;

  .yes-no-input {
    padding: 16px;
    border-radius: 4px;
    background-color: #dddddd;
    margin-left: 16px;
    width: 120px;
  }
}
</style>
