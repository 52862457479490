<template>
  <div class="survey-footer" align="center">
    <div class="survey-width-limit">
      <div
        v-if="
          ['sent', 'started'].includes(service.instance.status) ||
            service.is_preview
        "
        class="pa-5 pb-2"
      >
        <v-btn
          class="mx-4"
          :disabled="!canGoBack"
          color="primary"
          @click="
            $store.dispatch(
              'surveyRenderService/initializeSection',
              survey.instance.sections[section.position - 1].id
            )
          "
        >
          Back
        </v-btn>
        <v-btn
          v-if="canGoForward"
          class="mx-4"
          color="primary"
          @click="
            $store.dispatch(
              'surveyRenderService/initializeSection',
              survey.instance.sections[section.position + 1].id
            )
          "
        >
          Next
        </v-btn>
        <v-btn
          v-else-if="canSubmit"
          class="mx-4"
          color="success"
          @click="service.confirming_submit = true"
        >
          Submit
        </v-btn>

        <cp-dialog
          :show-model.sync="service.confirming_submit"
          fixed-header-footer
          header-background
          max-width="500"
        >
          <template #title>
            <h1>All Finished?</h1>
          </template>

          <template #body>
            <h1>
              Once your survey has been submitted, you won't be able to change
              any of your responses.
            </h1>
          </template>

          <template #actions="{close}">
            <v-col align="right">
              <v-btn color="error" text @click="close">Cancel</v-btn>
              <v-btn
                color="success"
                class="ml-4"
                :loading="service.submitting_survey"
                :disabled="service.submitting_survey"
                @click="$store.dispatch('surveyRenderService/submitSurvey')"
              >
                Submit
              </v-btn>
              <div
                v-if="service.is_preview"
                class="text-caption text-right mt-3"
              >
                Surveys cannot be submitted in preview mode.
              </div>
            </v-col>
          </template>
        </cp-dialog>
      </div>

      <div class="mt-4">
        <a href="mailto:support@swiftbunny.com">support@swiftbunny.com</a> |
        <a href="https://swiftbunny.com/privacy-policy">Privacy Policy</a>
        <p class="caption">
          © {{ new Date().getFullYear() }} Swift Bunny LLC. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SurveyFooter",

  computed: {
    ...mapState({
      service: state => state.surveyRenderService,
      survey: state => state.survey,
      section: state => state.survey.section_instance
    }),

    ...mapGetters("surveyRenderService", [
      "canGoBack",
      "canGoForward",
      "canSubmit"
    ])
  }
};
</script>

<style lang="scss" scoped>
.survey-footer {
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px 0 rgba(#000000, 0.05);
}
</style>
