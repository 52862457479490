/*
import auth from './middleware/auth';
import isAdmin from './middleware/isAdmin';
import canViewReporting from './middleware/canViewReporting';
*/
// import store from "@cp/store/appStore";

import {
  Dashboard,
  Questions,
  Demo,
  Surveys,
  SurveyDetail,
  SurveyWrapper,
} from "../views";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    //middleware: [auth],
  },
  {
    path: "/surveys",
    name: "surveys",
    component: Surveys,
  },
  {
    path: "/surveys/:id",
    name: "survey_detail",
    component: SurveyDetail,
  },
  {
    path: "/surveys/:id/preview",
    name: "survey_preview",
    component: SurveyWrapper,
    meta: {
      layout: "none",
      surveyPreviewMode: true,
    },
  },
  {
    path: "/feedback/:slug/:id",
    name: "feedback",
    component: SurveyWrapper,
    meta: {
      hideAppHeader: true,
      hideAppFooter: true,
      hideBgImg: true,
      skipAuth: true,
      fullWidth: true,
    },
  },
  {
    path: "/feedback/weblink/:campaign_id/:survey_id",
    name: "feedback_weblink",
    component: SurveyWrapper,
    meta: {
      hideAppHeader: true,
      hideAppFooter: true,
      hideBgImg: true,
      skipAuth: true,
      fullWidth: true,
    },
  },
  {
    path: "/questions",
    name: "questions",
    component: Questions,
    //middleware: [auth],
  },
  {
    path: "/demo",
    name: "demo",
    component: Demo,
    //middleware: [auth],
  },
];

export default routes.map(route => {
  if (!route.props) {
    route.props = route => ({
      ...route.query,
      ...route.params,
    });
  }

  /*
  if (route.middleware) {
      route.meta = {middleware: route.middleware};
      delete route.middleware;
  }
  */

  return route;
});
