<template>
  <cp-dialog
    :show-model.sync="campaign.confirming_cancel"
    class="mx-auto"
    headerBackground
    width="600"
  >
    <template #title>
      Cancel Scheduled Campaign
    </template>

    <template #body>
      Are you sure you want to cancel and remove this campaign?
    </template>

    <template #actions="{ close }">
      <v-col cols="auto" class="ml-auto">
        <cp-btn text @click="close()">
          Cancel
        </cp-btn>
        <cp-btn
          color="primary"
          rounded
          @click="cancelCampaign"
          :loading="campaign.closing"
        >
          Yes, Cancel Campaign
        </cp-btn>
      </v-col>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      campaign: state => state.campaign
    })
  },

  methods: {
    cancelCampaign() {
      let campaign_to_cancel = this.campaign.instance.id;

      this.$store
        .dispatch("campaign/cancelCampaign", {
          id: this.campaign.instance.id
        })
        .then(() => {
          this.campaign.collection.find(
            x => x.id == campaign_to_cancel
          ).status.slug = "cancelling";
          this.campaign.confirming_cancel = false;
          this.$store.dispatch("campaign/fetchCampaigns");
        });
    }
  }
};
</script>
