import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,_vm._b({attrs:{"loading":_vm.survey.loading_all_surveys,"sort-by":"updated_at","sort-desc":true,"headers":[
    { text: 'Name', value: 'name', sortable: !_vm.noManualSort, class: 'pl-8' },
    { text: 'Live', value: 'is_live', sortable: !_vm.noManualSort },
    { text: 'Creator', value: 'created_by', sortable: !_vm.noManualSort },
    { text: 'Status', value: 'status_slug', sortable: !_vm.noManualSort },
    { text: 'Modified', value: 'updated_at', sortable: !_vm.noManualSort },
    { text: 'Actions', value: 'actions', sortable: false } ],"items":_vm.surveyCollection,"search":_vm.survey.search},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"pl-8 text-truncate",staticStyle:{"position":"relative"},attrs:{"width":"auto"}},[(item.created_by == 'Swift Bunny')?_c(VIcon,{staticClass:"sb-owned-indicator"},[_vm._v(" $vuetify.icons.SwiftBunnyLogo ")]):_vm._e(),_c('router-link',{staticClass:"table-link",attrs:{"to":{ name: 'survey_detail', params: { id: item.id } }}},[_c('span',{staticClass:"table-link-text"},[_vm._v(_vm._s(item.name))])])],1),_c('td',{attrs:{"width":"8%"}},[(item.is_live)?_c('div',{staticClass:"ml-2 campaign-live-indicator"}):_vm._e()]),_c('td',{attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(item.created_by)+" ")]),_c('td',{attrs:{"width":"10%"}},[_c('cp-status-chip',{attrs:{"label":"","dark":"","status":item.status_slug}},[_vm._v(_vm._s(item.status_slug))])],1),_c('td',{attrs:{"width":"10%"}},[_vm._v(_vm._s(_vm.formatAmericanDate(item.updated_at)))]),_c('td',{attrs:{"width":"5%"}},[_c('survey-menu-actions',{attrs:{"survey-object":item}})],1)])]}}])},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }