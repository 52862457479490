import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSubheader,{staticClass:"primary--text justify-center font-weight-bold"},[_c('cp-btn',{staticClass:"text-body-1 pt-5",attrs:{"plain":"","text":"","tile":""},on:{"click":function($event){_vm.$store.dispatch('question/activateCreateEditDialog');
        _vm.$store.dispatch('question/fetchQuestions');
        _vm.$store.dispatch('survey/fetchSurveyQuestionIds');}}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" Add Question ")],1)],1),_c('cp-dialog',{attrs:{"max-width":"1160","scrollable":"","show-model":_vm.question.editor_open,"header-background":""},on:{"update:showModel":function($event){return _vm.$set(_vm.question, "editor_open", $event)},"update:show-model":function($event){return _vm.$set(_vm.question, "editor_open", $event)},"modalClosed":function($event){_vm.$store.dispatch('question/deactivateCreateEditDialog');
      _vm.tab = 0;
      _vm.question.search = '';}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.question.editing ? "Edit" : "Add")+" Question ")]},proxy:true},{key:"body",fn:function(){return [(!_vm.question.editing && !_vm.question.duplicating)?_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"4","xs":"12","sm":"12","md":"8"}},[_c(VTabs,{attrs:{"height":"48","centered":"","hide-slider":"","color":"#6B6B70","background-color":"grey lighten-2","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{staticClass:"pa-4 mx-1 my-auto text-no-wrap",attrs:{"height":"32","active-class":"active"}},[_vm._v(" Select from Existing ")]),_c(VTab,{staticClass:"pa-4 mx-1 my-auto",attrs:{"height":"32","active-class":"active"}},[_vm._v(" Create new ")])],1)],1),_c(VSpacer)],1):_vm._e(),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c(VRow,{staticClass:"mt-2"},[_c(VSpacer),_c(VCol,{attrs:{"cols":"6","align":"center"}},[_c('cp-search',{ref:"searchFilter",attrs:{"label":"Search for Questions"},model:{value:(_vm.question.search),callback:function ($$v) {_vm.$set(_vm.question, "search", $$v)},expression:"question.search"}})],1),_c(VCol,{attrs:{"cols":"4","align":"center"}},[_c('cp-select',{attrs:{"items":_vm.question.question_types,"label":"Filter By Type","placeholder":"","return-object":"","clearable":""},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c(VSpacer)],1),(_vm.question.collection.length > 0)?_c('cp-table',{staticClass:"questions-list",attrs:{"dense":"","loadingText":"No Questions Found","search":_vm.question.search,"items":_vm.question.collection,"fixed-header":"","items-per-page":20,"headers":[
              {
                text: 'Question',
                value: 'body',
                sortable: false
              },
              {
                text: 'Type',
                value: 'type',
                sortable: false,
                filter: function (value, search, item) {
                  return _vm.filters.type == null
                    ? true
                    : item.type.text == _vm.filters.type.text;
                }
              },
              {}
            ]},scopedSlots:_vm._u([{key:"item-columns",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"pl-9",staticStyle:{"position":"relative"}},[(item.created_by == 'Swift Bunny')?_c(VIcon,{staticClass:"sb-owned-indicator"},[_vm._v(" $vuetify.icons.SwiftBunnyLogo ")]):_vm._e(),_c('span',{staticClass:"table-link-text"},[_vm._v(_vm._s(item.body))])],1),_c('td',[_vm._v(_vm._s(item.type.text))]),_c('td',{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.survey.instance.question_ids.includes(item.id),"icon":""},on:{"click":function($event){return _vm.addExistingQuestion(item.id)}}},[_c(VIcon,[_vm._v(" mdi-"+_vm._s(_vm.survey.instance.question_ids.includes(item.id) ? "check" : "plus")+" ")])],1)],1)]}}],null,false,389355364)}):_c('div',{staticClass:"text-center py-6"},[_c(VProgressCircular,{attrs:{"size":100,"width":8,"color":"primary","indeterminate":""}})],1)],1),_c(VTabItem,{staticClass:"ma-4"},[_c('question-create-edit-form',{ref:"questionCreateEditForm"})],1)],1)]},proxy:true},(_vm.question.editing || _vm.question.duplicating)?{key:"actions",fn:function(ref){
            var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","text":""},on:{"click":close}},[_vm._v(" Cancel ")]),_c('cp-btn',{attrs:{"color":"primary","rounded":"","disabled":!_vm.passesValidation},on:{"click":function($event){return _vm.$store.dispatch('question/processQuestion')}}},[_vm._v(" Save ")])],1)]}}:(_vm.tab == 1)?{key:"actions",fn:function(){return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.passesValidation},on:{"click":function($event){_vm.addAnother = true;
            _vm.$store.dispatch('question/processQuestion');}}},[_vm._v(" Add Another ")]),_c('cp-btn',{attrs:{"color":"primary","rounded":"","disabled":!_vm.passesValidation},on:{"click":function($event){return _vm.$store.dispatch('question/processQuestion')}}},[_vm._v(" Add and Close ")])],1)]},proxy:true}:{key:"actions",fn:function(ref){
            var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }