<template>
  <v-stepper-content :step="campaign.instance.campaign_type_id == 1 ? 2 : 3">
    <div class="limited-max-width">
      <cp-card class="mt-2 mb-4" flat color="#f1f1f1">
        <template v-if="campaign.instance.campaign_type_id != 1">
          <span class="inline-input-group">Starts at</span>

          <v-menu
            v-model="campaign.start_on_open"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :disabled="campaign.instance.status.slug == 'live'"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :disabled="campaign.instance.status.slug == 'live'"
                :value="getFormattedStartOn"
                label="Date and Time"
                persistent-hint
                append-icon="mdi-calendar"
                hide-details
                solo
                v-bind="attrs"
                v-on="on"
                class="inline-input-group date-field"
              />
            </template>
            <v-date-picker
              no-title
              :value="startDatetime.date"
              @input="
                dateTimes.start.date = $event;
                formatAndSetDate('start');
              "
            >
              <v-container>
                <v-row no-gutters>
                  <v-col>
                    <v-select
                      dense
                      flat
                      label="Hour to Send"
                      :items="hourPickerItems"
                      :value="startDatetime.time"
                      @input="
                        dateTimes.start.time = $event;
                        formatAndSetDate('start');
                      "
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-date-picker>
          </v-menu>
        </template>

        <span class="inline-input-group">Closes at</span>

        <v-menu
          v-model="campaign.close_on_open"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="getFormattedCloseOn"
              label="Date and Time"
              persistent-hint
              append-icon="mdi-calendar"
              hide-details
              solo
              v-bind="attrs"
              v-on="on"
              class="inline-input-group date-field"
            />
          </template>
          <v-date-picker
            no-title
            :value="closeDatetime.date"
            @input="
              dateTimes.close.date = $event;
              formatAndSetDate('close');
            "
          >
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <v-select
                    dense
                    flat
                    label="Hour to Close"
                    :items="hourPickerItems"
                    :value="closeDatetime.time"
                    @input="
                      dateTimes.close.time = $event;
                      formatAndSetDate('close');
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-date-picker>
        </v-menu>

        <v-btn
          text
          small
          color="error"
          v-if="
            campaign.instance.campaign_type_id == 1 &&
              campaign.instance.close_on
          "
          @click="
            campaign.instance.close_on = null;
            closeDate = null;
            closeTime = null;
          "
        >
          <v-icon small>mdi-close</v-icon>Clear
        </v-btn>

        <br />

        <template v-if="campaign.instance.campaign_type_id != 1">
          <br />
          <span class="inline-input-group">Send a reminder?</span>

          <v-checkbox
            v-model="campaign.instance.reminder"
            class="inline-input-group pt-0"
            hide-details
            solo
          />

          <template v-if="campaign.instance.reminder">
            <span class="d-inline-block" style="width:290px">
              <v-select
                v-model="campaign.reminder_on_option"
                @change="
                  dateTimes.reminder.date = null;
                  campaign.instance.reminder_on = null;
                "
                :items="[
                  { text: 'Send one day before close', value: 1 },
                  { text: 'Select custom date:', value: 2 },
                ]"
                class="inline-input-group"
                full-width
                hide-details
                solo
                dense
              />
            </span>

            <span v-if="campaign.reminder_on_option === 1">
              {{ getFormattedReminderOn }}
            </span>

            <v-menu
              v-if="campaign.reminder_on_option === 2"
              v-model="campaign.reminder_on_open"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="getFormattedReminderOn"
                  label="Date and Time"
                  persistent-hint
                  append-icon="mdi-calendar"
                  hide-details
                  solo
                  v-bind="attrs"
                  v-on="on"
                  class="inline-input-group date-field"
                />
              </template>
              <v-date-picker
                no-title
                :value="reminderDatetime.date"
                @input="
                  dateTimes.reminder.date = $event;
                  formatAndSetDate('reminder');
                "
              >
                <v-container>
                  <v-row no-gutters>
                    <v-col>
                      <v-select
                        dense
                        flat
                        label="Hour to send reminder"
                        :items="hourPickerItems"
                        :value="reminderDatetime.time"
                        @input="
                          dateTimes.reminder.time = $event;
                          formatAndSetDate('reminder');
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-date-picker>
            </v-menu>
          </template>
        </template>
      </cp-card>

      <v-row class="mb-1">
        <v-col align="right">
          <v-btn
            text
            color="primary"
            rounded
            @click="campaign.current_step--"
            class="mr-4"
          >
            Back
          </v-btn>
          <v-btn
            color="primary"
            rounded
            @click="campaign.confirming_save = true"
            :disabled="!passesValidation"
          >
            Review Campaign
          </v-btn>

          <cp-dialog
            header-background
            :show-model.sync="campaign.confirming_save"
            max-width="640"
          >
            <template #title>
              Confirm New Campaign
            </template>
            <template #body>
              <h2 class="font-weight-bold">
                Campaign Type
              </h2>
              <p>
                {{ campaign.type_name_map[campaign.instance.campaign_type_id] }}
              </p>

              <h2 class="font-weight-bold">Survey</h2>
              <p>{{ survey.instance.name }}</p>

              <template v-if="campaign.instance.campaign_type_id != 1">
                <h2 class="font-weight-bold">Recipients</h2>
                <p>
                  {{ getRecipientCount }}
                  {{ "Employee" | pluralize(parseInt(getRecipientCount)) }}
                </p>
              </template>

              <h2 class="font-weight-bold">Timing</h2>
              <template v-if="campaign.instance.campaign_type_id != 1">
                <p>
                  Starts {{ getFormattedStartOn }}, and closes
                  {{ getFormattedCloseOn }}
                </p>
              </template>

              <template v-else>
                <p v-if="campaign.instance.close_on">
                  Campaign will stop accepting responses on
                  {{ getFormattedCloseOn }}
                </p>
                <p v-else>Campaign will remain open indefinitely.</p>
              </template>

              <p v-if="campaign.instance.reminder">
                Reminders will be sent on {{ getFormattedReminderOn }}
              </p>
            </template>

            <template #actions>
              <v-col cols="auto" class="ml-auto">
                <cp-btn
                  color="primary"
                  text
                  @click="campaign.confirming_save = false"
                >
                  Back
                </cp-btn>
              </v-col>
              <v-col cols="auto">
                <cp-btn
                  color="primary"
                  @click="submit()"
                  :loading="campaign.saving"
                  :disabled="campaign.saving || !passesValidation"
                >
                  {{ campaign.editing_campaign ? "Save" : "Create" }}
                  Campaign
                </cp-btn>
              </v-col>
            </template>
          </cp-dialog>
        </v-col>
      </v-row>
    </div>
  </v-stepper-content>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      sendReminders: false,
      dateTimes: {
        start: {
          date: null,
          time: null,
        },
        close: {
          date: null,
          time: null,
        },
        reminder: {
          date: null,
          time: null,
        },
      },
    };
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign,
      survey: state => state.survey,
    }),

    ...mapGetters("campaign", [
      "getRecipientCount",
      "getCalculatedEndDate",
      "getFormattedStartOn",
      "getFormattedCloseOn",
      "getFormattedReminderOn",
      "passesValidation",
    ]),

    scheduleDescription() {
      if (this.campaign.instance.campaign_type_id == 1) {
        return "Links will remain open indefinitely to receive responses, unless you provide a close date.";
      }

      return "Surveys will be emailed at the selected date and time, and will accept responses until your close date and time.";
    },

    hourPickerItems() {
      let hours = [];
      for (let i = 0; i < 24; i++) {
        const hr = i % 12 || 12;
        const amPm = i < 12 ? "AM" : "PM";
        hours.push({
          text: `${hr}:00 ${amPm}`,
          value: i,
        });
      }
      return hours;
    },

    startDatetime() {
      let date = this.dateTimes.start.date
        ? this.dateTimes.start.date
        : this.campaign.instance.start_on;

      let time = this.dateTimes.start.time
        ? this.dateTimes.start.time
        : this.campaign.instance.start_on_time;

      if (date) {
        date = moment(date)
          .format()
          .substr(0, 10);
      } else {
        date = moment()
          .format()
          .substr(0, 10);
      }

      return { date, time };
    },

    closeDatetime() {
      let date = this.dateTimes.close.date
        ? this.dateTimes.close.date
        : this.campaign.instance.close_on;

      let time = this.dateTimes.close.time
        ? this.dateTimes.close.time
        : this.campaign.instance.close_on_time;

      if (date) {
        date = new Date(date).toISOString().substr(0, 10);
      } else {
        date = null;
      }

      return { date, time };
    },

    reminderDatetime() {
      let date = this.dateTimes.reminder.date
        ? this.dateTimes.reminder.date
        : this.campaign.instance.reminder_on;

      let time = this.dateTimes.reminder.time
        ? this.dateTimes.reminder.time
        : this.campaign.instance.reminder_on_time;

      if (date) {
        date = new Date(date).toISOString().substr(0, 10);
      } else {
        date = null;
      }

      return { date, time };
    },
  },

  methods: {
    submit() {
      let action = this.campaign.editing_campaign
        ? "campaign/updateCampaign"
        : "campaign/createCampaign";

      this.$store
        .dispatch(action, this.campaign.instance)
        .then(result => {
          this.$store.commit("campaign/resetCampaignInstance");
          this.$CpEvent.$emit("campaignSaved");
          this.$CpEvent.$emit("snackAlert", {
            message: `Campaign saved successfully!`,
            color: "success",
          });
        })
        .catch(error => {
          this.$CpEvent.$emit("snackAlert", {
            message: "There was an issue with this campaign.",
            color: "error",
          });
        });
    },

    formatAndSetDate(context) {
      let builtDate = moment(this[`${context}Datetime`].date).toDate();

      builtDate.setHours(this[`${context}Datetime`].time);
      this.campaign.instance[`${context}_on_time`] = this[
        `${context}Datetime`
      ].time;
      this.campaign.instance[`${context}_on`] = String(builtDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.limited-max-width {
  max-width: 760px;
}

.inline-input-group {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem !important;
  font-weight: 600;

  &:last-child {
    margin-right: 0;
  }
  &.v-input--selection-controls.v-input--checkbox {
    margin-top: 0;
  }
}

.date-picker-actions {
  display: block;
}

.date-field {
  width: 240px;
  ::v-deep .v-text-field__slot input {
    // make sure the text inside doesn't go under the icon
    padding-right: 28px;
  }
  ::v-deep .v-input__append-inner {
    // make the icon not take up any space in the parent flex
    margin-left: -28px;
    // let the user click through the icon onto the input
    pointer-events: none;
  }
}
</style>
