<template>
  <div class="summary-wrapper" :style="`background-color: ${color};`">
    <router-link
      class="summary-title"
      :to="{
        name: 'survey_detail',
        params: { id: summaryItem.parent_version },
      }"
    >
      <div class="summary-title-text">
        <span>
          {{ summaryItem.name }}
        </span>
        <v-icon color="#ffffff" class="summary-title-icon" large>
          mdi-chevron-right
        </v-icon>
      </div>
    </router-link>
    <v-card class="summary-body">
      <v-container class="pt-1 align-start fill-height">
        <div class="summary-campaign-list">
          <template
            v-for="campaignItem in summaryItem.email_campaigns.concat(
              summaryItem.weblink_campaigns
            )"
          >
            <v-row class="mt-0" align="start">
              <v-col>
                <a
                  class="summary-body-title text-truncate"
                  @click="
                    $store.dispatch('campaign/activateViewer', {
                      campaign_id: campaignItem.id,
                      survey_id: summaryItem.parent_version,
                    })
                  "
                >
                  <v-icon small style="margin-top: -1px;" class="mr-1">
                    {{ campaign.type_icon_map[campaignItem.type] }}
                  </v-icon>
                  {{ campaignItem.title }}
                </a>
              </v-col>
            </v-row>
            <v-row dense class="text-center caption">
              <v-col
                cols="12"
                align="left"
                class="font-weight-medium mb-2 pl-2 py-0"
              >
                Started {{ campaignItem.start_on }}
                <v-icon
                  x-small
                  style="margin-top: -1px;"
                  color="grey lighten-1"
                >
                  mdi-arrow-right
                </v-icon>
                {{ daysLeft(campaignItem) }}
              </v-col>
              <v-col cols="2">
                <span class="font-weight-bold text-subtitle-1">
                  {{ campaignItem.type == 1 ? "NA" : campaignItem.sent }}
                </span>
                <br />
                {{ campaignItem.type == "weblink" ? "Clicks" : "Sent" }}
              </v-col>
              <v-col cols="2">
                <span class="font-weight-bold text-subtitle-1">
                  {{ campaignItem.started }}
                </span>
                <br />
                Started
              </v-col>
              <v-col cols="3">
                <span class="font-weight-bold text-subtitle-1">
                  {{ campaignItem.completed }}
                </span>
                <br />
                Completed
              </v-col>
              <v-col cols="5">
                <v-sparkline
                  fill
                  :smooth="16"
                  :gradient="['#f72047', '#ffd200', '#1feaea']"
                  :line-width="10"
                  :value="campaignItem.response_trend"
                  auto-draw
                  auto-line-width
                  stroke-linecap="round"
                  :height="62"
                />
                Response Trend
              </v-col>
            </v-row>
          </template>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    summaryItem: { type: Object, default: () => {} },
    color: { type: String, default: "#0070A8" },
  },

  computed: {
    ...mapState({
      campaign: (state) => state.campaign,
    }),
  },

  methods: {
    daysLeft(campaignItem = {}) {
      if (campaignItem.ended) {
        return `Ended ${campaignItem.close_on}`;
      }

      return `Days Left: ${
        campaignItem.days_left <= 0 ? "TODAY" : campaignItem.days_left
      }`;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-wrapper {
  border-radius: 6px;
  padding: 2px;
  position: relative;
  height: 100%;
}

.summary-title {
  color: $white;
  text-decoration: none;

  .summary-title-text {
    transition: background-color 0.25s ease-in-out;
    line-height: 1.25;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    border-radius: 4px;

    &:hover {
      background-color: rgba($white, 0.2);

      .summary-title-icon {
        opacity: 1;
        padding-left: 6px;
        padding-right: 0px;
      }
    }

    span {
      line-height: 1.25em;
      max-height: 1.25em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .summary-title-icon {
    transition: opacity 0.25s ease-in-out, right 0.25s ease-in-out;
    right: 0;
    opacity: 0.5;
    padding-left: 0px;
    padding-right: 6px;
    transition-property: opacity, padding-right, padding-left;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
  }
}

.summary-body {
  height: calc(100% - 50px);
  min-height: 145px;
  max-height: 277px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;
}

.summary-body-title {
  transition: background-color 0.25s ease-in-out;
  display: block;
  position: relative;
  background-color: rgba($primary, 0.1);
  padding: 0 4px;
  border-radius: 2px;

  &:hover {
    background-color: rgba($primary, 0.3);
    text-decoration: none;
  }
}

.summary-campaign-list {
  max-height: 100%;
  color: $gray-5;
}
</style>
